import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Nash from './nash'

import SharkNote from './sharknote'
import Login from './login'
import Signup from './login/Registre'
import Premium from './premium'
import Profile from './Profile'
import Range from './range'

export default function Routes() {
    return (
        <Switch>
            <Route path='/' component={Nash} exact />
            <Route path='/nash' component={Nash} />
            <Route path='/sharknote' component={SharkNote} />
            <Route path='/login' component={Login} />
            <Route path='/signup' component={Signup} />
            <Route path='/premium' component={Premium} />
            <Route path='/profile' component={Profile} />
            <Route path='/range' component={Range} />
        </Switch>
    )
}
