import React, { useState, useEffect } from 'react'
import TabelaEquit from '../TabelaEquit'
import { InputLabel, Card, TextField, Switch } from '@material-ui/core';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import _ from 'lodash'
import FimUsesFree from '../../FimUsesFree';

const posicoesMesa = ["BB", "SB", "BTN", "CO", "MP3", "MP2", "MP1", "UTG1", "UTG"]

const bbPossiveis = [
    { value: 'FIVE', label: '5', },
    { value: 'SIX', label: '6', },
    { value: 'SEVEN', label: '7', },
    { value: 'EIGHT', label: '8', },
    { value: 'NINE', label: '9', },
    { value: 'TEN', label: '10', },
    { value: 'ELEVEN', label: '11', },
    { value: 'TWELVE', label: '12', },
    { value: 'THIRTEEN', label: '13', },
    { value: 'FOURTEEN', label: '14', },
    { value: 'FIFTEEN', label: '15', },
    { value: 'SIXTEEN', label: '16', },
    { value: 'SEVENTEEN', label: '17', },
    { value: 'EIGHTEEN', label: '18', },
    { value: 'NINETEEN', label: '19', },
    { value: 'TWENTY', label: '20', },
];

export default function MaoSeletor({ dbShove, dbCall, newUse, bloqueia }) {

    const [bb, setBB] = useState("TEN")
    const [degrade, setDegrade] = useState(true)
    const [limit, setLimit] = useState(0)
    const [posicoesMesaVilao, setPosicoesMesaVilao] = useState(posicoesMesa.slice(3, posicoesMesa.length))
    const [position, setPosition] = useState("BTN")
    const [vPosition, setVposition] = useState("CO")
    const [tabela, setTabela] = useState(null)
    const [tabelaCall, setTabelaCall] = useState(null)


    const [oldUse, setOldUse] = useState(null)
    useEffect(() => {
        let tabelaSel = null;
        dbShove.forEach(element => {
            if (element.heroPosition === position && element.bb === bb) {
                tabelaSel = element;
            }
        });
        setTabela(tabelaSel)

    }, [bb, dbShove, position])


    useEffect(() => {

        let use = { bb, position }
        if (!oldUse) {
            setOldUse(use);
        } else if (!_.isEqual(oldUse.bb, use.bb) && !_.isEqual(oldUse.position, use.position)) {
            setOldUse(use);

            newUse(use)
        }
    }, [bb, newUse, oldUse, position])

    useEffect(() => {
        let tabelaSel = null;
        dbCall.forEach(element => {
            if (element.villianPosition === vPosition && element.heroPosition === position && element.bb === bb) {
                tabelaSel = element;
            }
        });
        setTabelaCall(tabelaSel)
    }, [bb, dbCall, position, vPosition])

    return (
        <Card style={{ marginTop: 10, marginLeft: 5, marginRight: 5, padding: 10, width: 960 }}>
            {bloqueia ? <FimUsesFree /> :
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>


                    <div className="inputComp">
                        <InputLabel>Hero Position:</InputLabel>
                        <ToggleButtonGroup
                            style={{ height: 32 }}
                            value={position}
                            exclusive
                            onChange={(evt, v) => {
                                if (v) {
                                    setPosition(v)
                                    let index = posicoesMesa.indexOf(v);
                                    index++;
                                    if (index < posicoesMesa.length) {
                                        setVposition(posicoesMesa[index])
                                    } else {
                                        setVposition(null)
                                    }

                                    setPosicoesMesaVilao(posicoesMesa.slice(index, posicoesMesa.length))
                                }
                            }}
                        >
                            {posicoesMesa.map((b) => <ToggleButton style={{ padding: 5 }} key={b} value={b} >
                                {b}
                            </ToggleButton>)}
                        </ToggleButtonGroup>
                    </div>
                    <div className="inputComp">
                        <InputLabel>Limit:</InputLabel>
                        <TextField
                            style={{ width: 55 }}
                            id="standard-number"
                            value={limit}
                            onChange={(evt, v) => setLimit(parseFloat(evt.target.value))}
                            type="number"

                        />
                    </div>
                    <div className="inputComp">
                        <InputLabel>Gradient</InputLabel>

                        <Switch checked={degrade} onChange={(evt) => setDegrade(evt.target.checked)} name="checkedA" />
                    </div>
                </div>
            }

            <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                    {!bloqueia && <div className="inputComp">

                        <InputLabel>BB:</InputLabel>
                        <ToggleButtonGroup
                            style={{ height: 32 }}
                            value={bb}
                            exclusive
                            onChange={(evt, v) => { if (v) setBB(v) }}
                        >
                            {bbPossiveis.map(b => <ToggleButton style={{ padding: 5 }} key={b.value} value={b.value} >
                                {b.label}
                            </ToggleButton>)}

                        </ToggleButtonGroup>
                    </div>}

                    {tabela && <div className="inputComp">
                        <InputLabel >Range Shove</InputLabel>
                        <TabelaEquit tabela={tabela} limit={limit} degrade={degrade} />
                    </div>}
                </div>
                <div style={{ flex: 1 }}>
                    {!bloqueia && <div className="inputComp">
                        <InputLabel>Villian Position:</InputLabel>
                        <ToggleButtonGroup
                            style={{ height: 32 }}
                            value={vPosition}
                            exclusive
                            onChange={(evt, v) => { if (v) setVposition(v) }}
                        >
                            {posicoesMesaVilao.map(b => <ToggleButton style={{ padding: 5 }} key={b} value={b} >
                                {b}
                            </ToggleButton>)}
                        </ToggleButtonGroup>
                    </div>}
                    {tabelaCall && <div className="inputComp">
                        <InputLabel>Range Call</InputLabel>
                        <TabelaEquit tabela={tabelaCall} limit={limit} degrade={degrade} />
                    </div>}
                </div>
            </div>
        </Card>
    )
}
