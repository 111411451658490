import React from 'react'
import './style.css'
import { possiveisCartas } from '../../utils';



export default function TabelaNash({ tabela, limit, degrade }) {
    let limitColor = limit || 0.0;
    const getColor = (v) => {
        let vCor = parseFloat(v) - limitColor;
        if (parseFloat(v) < limitColor) {
            // vemelho
            if (degrade) {

                return 'rgb(255, 0, 0,' + Math.abs(vCor) + ')'
            } else {
                return 'rgb(255, 0, 0)'
            }
        } else {
            //verde

            if (degrade) {

                return 'rgb(0, 255, 0,' + Math.abs(vCor) + ')'
            } else {
                return 'rgb(0, 255, 0)'
            }
        }
    }


    const RenderCarta = ({ cartas }) => {
        let nome = cartas.substring(1, 3) + (cartas.substring(0, 1) === 'p' ? "" : cartas.substring(0, 1));
        let equit = tabela[cartas];
        return <div className="cartas" style={{ backgroundColor: getColor(equit) }}>
            <div className="cartasNome">
                {nome}
            </div>
            <div className="cartasValor">
                {parseFloat(equit).toFixed(2)}
            </div>
        </div>
    }
    return (
        <div className="tabelaNash">
            {possiveisCartas.map((c, i) => <RenderCarta key={i} cartas={c} />)}
        </div>
    )
}
