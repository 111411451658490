import React from 'react'
import HOT1 from './HOT1'
import HOT2 from './HOT2'

export default function RandomHot(p) {


    // return <HOT1 {...p} />
    const randomize = (myArray) => {
        return myArray[Math.floor(Math.random() * myArray.length)];
    }
    var arr = [<HOT1 />, <HOT2 />]

    return <>{randomize(arr)}</>;

}
