import { Card } from '@material-ui/core'
import React from 'react'
import { dbRegisterClickHot } from '../../../dbUtil'
import Image from './banner.jpg'
import './style.css'
export default function Afiliado() {
    let link = "https://go.hotmart.com/G41790491S"
    let texto = "Curso Online"
    let click = () => {
        console.log('click', link)
        dbRegisterClickHot({ link, texto })
    }
    return (
        <div className='contanerHot' >
            <Card className='contanerHot' style={{ height: 325 }}>
                <a href={link} target="_blank" onClick={click}>
                    <img alt="Link" src={Image} width={290
                    } />
                </a>
                <br />
                <a className='hotText' target="_blank" href={link} onClick={click}>
                    {texto}
                </a>
            </Card>
        </div>
    )
}
