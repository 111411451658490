import React from 'react'
import { useIsAuthenticated, useSubscriptions, useLastSubscription, usePremium } from '../../useAuth'
import { useHistory, Link } from 'react-router-dom'
import "./style.css"

import StripeSub from './StripeSubscriptionButton';
import SubscripitionView from './SubscripitionView';
export default function Premium() {
  let premium  = usePremium();
  const isAuthenticaded = useIsAuthenticated();
  const history = useHistory();
  let sub = useLastSubscription();
console.log('subs',sub)
  let exibeComprarPremiun = !sub || sub.status !== "active"
  if (!isAuthenticaded) {
    history.push("/")
  }


  return (
    <div className="buyPremiumDiv">
      {exibeComprarPremiun ?
        <StripeSub urlReturn={'http://localhost:3000/premium'} />
        :<Link to="/profile">To Profile</Link>
      }

     

    </div>
  )
}
