import firebaseUtil from './firebaseUtil'
import { useHistory } from "react-router-dom";
import { useState, useEffect } from 'react';
import { dbRegisterAutoLogin, dbRegisterLogin, dbRegisterSingUp, snapUser, snapSubscriptions } from './dbUtil';



export const isAuthenticated1 = () => {
    if (!firebaseUtil.getCurrentUser()) {
        return false;
    } else {
        return true;
    }


}
export function usePremium() {//undefined sem profile... null sem premium
    let profile = useProfile();


    let premium = null;
    return premium;
}

export function useProfile() {

    const [profile, setProfile] = useState(null)
    let c = firebaseUtil.getCurrentUser();


    useEffect(() => {
        let observer;
        if (c) {
            observer = snapUser(c.uid, (p) => setProfile(p))
        } else {
            setProfile(null)
        }

        return () => {
            if (observer) observer()
            setProfile(null)
        }
    }, [c])

    if (!c) {
        return null;
    }

    return profile;

}
export function useLastSubscription() {
    const subs = useSubscriptions();
    if (!subs) {
        if (subs === null) {
            return null;
        }
        return undefined;
    }


    let lastSub = null;

    subs.forEach(s => {
        console.log('s', s)
        if (!lastSub || lastSub.created < s) {
            lastSub = s

        }
    });
    return lastSub;
}

export function useSubscriptions() {
    const profile = useProfile();
    const [subscriptions, setSubscriptions] = useState(undefined)

    useEffect(() => {
        let observer = null;
        if (profile) {
            let consumerId = profile.stripeCustomer;
            if (consumerId) {
                observer = snapSubscriptions(consumerId, (aaa) => {
                    setSubscriptions(aaa)
                })
            } else {
                setSubscriptions(null)
            }
        }
        return () => {
            if (observer) observer();
            setSubscriptions(undefined)
        }
    }, [profile])
    return subscriptions;
}

export function useIsAuthenticated() {


    return firebaseUtil.getCurrentUser() ? true : false;

}


export default function useAuth() {
    const history = useHistory();
    const [current, setCurrent] = useState(null)

    let c = firebaseUtil.getCurrentUser();



    useEffect(() => {
        setCurrent(c)
    }, [c])


    const isAuthenticated = () => {
        if (!current) {
            return false;
        } else {
            return true;
        }

    }
    const redirectPosAuth = (pagePosLogin) => {
        console.log('redirectPosAuth', pagePosLogin)
        history.push(pagePosLogin || '/')
    }
    const redirectLogin = () => {
        history.push('/Login')
    }
    const redirectRegister = () => {
        history.push('/Register')
    }

    const authenticatedOnly = () => {
        if (!firebaseUtil.getCurrentUser()) {
            redirectLogin()
        }

    }
    const notAuthenticatedOnly = () => {
        if (firebaseUtil.getCurrentUser()) {
            redirectPosAuth()
        }
    }



    const userName = () => {
        return firebaseUtil.getCurrentUsername()
    }

    const user = () => {
        return firebaseUtil.getCurrentUser()
    }

    const registerAutoLogin = () => {
        console.log('registerAutoLogin')
        if (firebaseUtil.getCurrentUser()) {
            firebaseUtil.logEvent("LogIn");
            dbRegisterAutoLogin(firebaseUtil.getCurrentUser())
        }
    }
    const registerLogin = (params) => {

        if (firebaseUtil.getCurrentUser()) {
            firebaseUtil.logEvent("LogIn");
            dbRegisterLogin({ ...firebaseUtil.getCurrentUser(), params })
        }
    }
    const registerSingIn = (data) => {

        if (firebaseUtil.getCurrentUser()) {


            dbRegisterSingUp({ fuser: firebaseUtil.getCurrentUser(), data })
        }
    }

    const logOut = () => firebaseUtil.logout()


    return { authenticatedOnly, notAuthenticatedOnly, redirectLogin, redirectRegister, redirectPosAuth, isAuthenticated, userName, user, registerLogin, registerAutoLogin, registerSingIn, logOut }
}
