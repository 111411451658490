import React from 'react'
import './style.css'
import { possiveisCartas } from '../../utils';



export default function TabelaSel({ range, changeRange }) {

    const getColor = (mardado) => {

        if (!mardado) {

            return 'rgb(255, 0, 0)'
        } else {
            //verde

            return 'rgb(0, 255, 0)'

        }
    }


    const RenderCarta = ({ cartas }) => {
        let nome = cartas.substring(1, 3) + (cartas.substring(0, 1) === 'p' ? "" : cartas.substring(0, 1));
        let marcado = range.includes(nome)
        return <div className="cartas" style={{ backgroundColor: getColor(marcado) }} onClick={() => changeRange(nome)}>
            <div className="cartasNome">
                {nome}
            </div>
        </div>
    }
    return (
        <div className="tabelaNash">
            {possiveisCartas.map((c, i) => <RenderCarta key={i} cartas={c} />)}
        </div>
    )
}
