import firebaseUtil from "./firebaseUtil"

export const dbRegisterAutoLogin = (params) => {
    // console.log('dbRegisterAutoLogin', params)
    const { uid, email, emailVerified, displayName } = params
    salvaObj({ uidUser: uid, email, emailVerified, displayName, auto: true }, "Login")
    salvaObj({ uid, email, emailVerified, displayName }, "User")
}

export const dbRegisterLogin = (params) => {
    // console.log('dbRegisterLogin', params)
    const { uid, email, emailVerified, displayName } = params
    salvaObj({ uidUser: uid, email, emailVerified, displayName }, "Login")
    salvaObj({ uid, email, emailVerified, displayName }, "User")
}

export const dbRegisterSingUp = (params) => {
    // console.log('dbRegisterSingUp', params)
    const { uid, email, emailVerified, displayName } = params
    salvaObj({ uid, email, emailVerified, displayName }, "User")
    salvaObj({ uidUser: uid, email, emailVerified, displayName, SingUp: true }, "Login")


}
export const registerIp = (params) => {
    // console.log('registerIp', params)

    salvaObj(params, "RegisterIp")
    if (params.userUid) {
        salvaObj(params, "User/" + params.userUid + "/RegisterIp")
    }
}


export const registerOneNashUse = (params) => {
    console.log('registerOneNashUse', params)
}


const salvaObj = (obj, colecitonStr) => {
    const { uid } = obj;
    const colection = firebaseUtil.db.collection(colecitonStr)
    let docSalvar = null;
    if (uid) {
        docSalvar = colection.doc(uid);
    } else {
        docSalvar = colection.doc();
        obj.uid = docSalvar.id;
    }
    obj.lastChange = firebaseUtil.firestore().FieldValue.serverTimestamp()
    docSalvar.set(obj, { merge: true });

}


export const snapUser = (uid, callback) => {
    // console.log('snapUser', uid)
    firebaseUtil.db.collection('User').doc(uid).onSnapshot((doc) => {
        let profile = doc.data();
        callback(profile)
    })
}


export const snapSubscriptions = (idConsumerStripe, callback) => {
    // console.log('snapUser', uid)
    firebaseUtil.db.collection('Subscription').where('customer', '==', idConsumerStripe).onSnapshot((snapshot) => {
        let subs = [];
        snapshot.forEach(doc => {
            console.log(doc.id, '=>', doc.data());
            subs.push(doc.data());
        });

        callback(subs)
    })
}

export const dbRegisterClickHot = (params) => {
    
    salvaObj({ ...params }, "ClickHot")
}