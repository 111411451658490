export const txtCsv = `RANGE,STACKSIZE,HERO ACTION,HERO POSITION,VILLAIN POSITION (can be empty),SELECTED HANDS (all remaining column seperated by commas)
Sample,15,OPEN,UTG,,AKs,AJo,QQ,JJ,TT,ATo,ATs,AA,QTs,KK,AKo,A9s,KTs,77,88,AQo,QJs,AJs,K9s,JTs,KQs,KJo,99,KQo,AQs,KJs
Sample,15,OPEN,UTG1,,AKs,AJo,QQ,JJ,TT,ATs,ATo,QTs,AA,KK,AKo,66,A8s,A9s,KTs,77,88,AQo,QJs,AJs,K9s,JTs,KQs,KJo,99,KQo,AQs,KJs
Sample,15,OPEN,MP1,,AKs,AJo,QQ,JJ,TT,55,ATs,ATo,QTs,AA,KK,AKo,J9s,A7s,66,A8s,A9s,KTs,77,88,AQo,A6s,QJs,AJs,K9s,JTs,KQs,Q9s,KJo,99,KQo,AQs,KJs,A5s,T9s
Sample,15,OPEN,MP2,,AKs,AJo,A4s,QQ,JJ,TT,ATo,ATs,55,AA,QTs,KK,AKo,J9s,A7s,66,A8s,A9s,KTs,77,88,AQo,A6s,QJs,AJs,JTs,K9s,KQs,Q9s,98s,KJo,99,KQo,AQs,KJs,A5s,T9s
Sample,15,OPEN,MP3,,AKs,AJo,A4s,QQ,JJ,TT,ATo,ATs,55,A2s,AA,QTs,KK,44,AKo,J9s,33,A7s,66,A8s,A9s,KTs,77,88,AQo,A3s,QJs,A6s,AJs,JTs,K9s,KQs,Q9s,98s,KJo,99,22,KQo,AQs,KJs,A5s,T9s
Sample,15,OPEN,CO,,AKs,AJo,A4s,QQ,JJ,TT,55,ATs,ATo,A2s,AA,QTs,KK,44,KTo,Q8s,A9o,AKo,J9s,33,A7s,66,A8s,A9s,KTs,77,AQo,88,A3s,AJs,A6s,QJs,K9s,JTs,KQs,Q9s,98s,KJo,99,QJo,22,K7s,KQo,K8s,AQs,KJs,A5s,T9s
Sample,15,OPEN,BTN,,AKs,AJo,A4s,QQ,86s,TT,ATo,A2s,QTs,KTo,J9s,K6s,66,A9s,97s,AQo,88,A3s,A6s,QJs,K9s,Q9s,98s,99,K8s,87s,J8s,T9s,JJ,ATs,55,T8s,JTo,AA,QTo,KK,44,Q8s,A9o,AKo,A7s,33,76s,A8s,KTs,77,AJs,K9o,JTs,KQs,KJo,QJo,22,K7s,KQo,AQs,KJs,A5s,A5o
Sample,15,OPEN,SB,,Q9o,AKs,Q4s,AJo,A4s,QQ,A6o,86s,TT,ATo,A4o,A2s,QTs,KTo,A7o,J9s,K6s,Q5s,66,A9s,K5s,97s,K3s,T9o,AQo,88,A3s,T8o,A6s,QJs,K9s,K5o,Q9s,98s,K2s,53s,Q8o,99,K6o,K8s,87s,K4s,J8s,K7o,43s,T9s,T7s,A3o,A8o,JJ,K8o,ATs,55,87o,65s,T8s,JTo,AA,Q7s,QTo,KK,54s,44,Q8s,A9o,AKo,98o,J8o,A7s,33,76s,A8s,75s,Q6s,KTs,77,A2o,AJs,K9o,J9o,JTs,KQs,KJo,QJo,Q2s,J7s,22,K7s,KQo,AQs,KJs,A5s,64s,Q3s,A5o
Sample,15,CALL,UTG1,UTG,AJo,KTs,QJs,KQo,99,TT,ATs,88,KJs
Sample,15,THREE_BET,UTG1,UTG,AKo,QQ,AQs,KK,AQo,AJs,AA,KQs,AKs,JJ
Sample,15,THREE_BET,MP1,UTG,AKs,AJs,QQ,JJ,AQs,KQs,TT,AA,KK
Sample,15,THREE_BET,MP2,UTG,AKs,AJs,QQ,JJ,AQs,KQs,TT,AA,KK
Sample,15,THREE_BET,MP3,UTG,AKs,AJs,QQ,JJ,AQs,KQs,TT,AA,KK
Sample,15,THREE_BET,CO,UTG,AKs,AJs,QQ,KQs,JJ,TT,AA,KK,99,AKo,AQs,KJs,AQo,88
Sample,15,THREE_BET,BTN,UTG,AKs,QJs,AJs,AJo,QQ,JJ,KQs,TT,ATs,AA,KK,99,AKo,KQo,AQs,KJs,77,88,AQo
Sample,15,THREE_BET,SB,UTG,AKs,AJo,QQ,JJ,TT,ATs,ATo,AA,QTs,KK,AKo,66,A9s,KTs,77,AQo,88,AJs,QJs,JTs,KQs,99,KQo,AQs,KJs
Sample,15,THREE_BET,BB,UTG,QQ,KK,KQs,AKs,AQs,AKo,AA,JJ
Sample,15,CALL,MP1,UTG1,A9s,QJs,66,99,88,ATs,JTs,77,TT,QTs,A8s,KJs,KTs,AQo
Sample,15,CALL,MP2,UTG1,QJs,A9s,KQo,66,AJo,88,99,ATs,JTs,77,TT,QTs,A8s,KJs,KTs,AQo,K9s
Sample,25,OPEN,SB,,Q9o,Q4s,86s,QQ,A4o,J9s,K6s,J4s,85s,66,88,AQo,T8o,A6s,Q9s,98s,53s,99,Q8o,74s,87s,J8s,K7o,T9s,84s,J6s,ATs,JTo,T8s,87o,KK,Q7s,T4s,44,33,A8s,76s,75s,KTs,Q6s,95s,77,A2o,K9o,J9o,KQs,KJo,QJo,Q2s,K7s,KQo,AQs,KJs,64s,42s,AKs,AJo,A6o,A4s,TT,ATo,A2s,QTs,KTo,96s,A7o,Q5s,A9s,K5s,97s,K3s,T9o,A3s,QJs,K9s,T5s,K2s,K8s,K4s,43s,T7s,A3o,A8o,J5s,JJ,K8o,55,AA,65s,QTo,54s,Q8s,32s,A9o,AKo,98o,J8o,T6s,A7s,63s,AJs,JTs,22,J7s,94s,A5s,A5o,Q3s
Sample,25,OPEN,UTG,,AKs,AJo,QQ,JJ,TT,ATo,ATs,AA,QTs,KK,AKo,A9s,KTs,77,88,AQo,QJs,AJs,K9s,JTs,KQs,KJo,99,KQo,AQs,KJs
Sample,40,OPEN,UTG,,AKs,AJo,A4s,QQ,JJ,TT,ATo,ATs,A2s,AA,QTs,KK,AKo,J9s,A7s,A8s,A9s,KTs,77,88,AQo,A3s,A6s,QJs,AJs,K9s,JTs,KQs,Q9s,KJo,99,KQo,AQs,KJs,A5s,T9s
Sample,60,OPEN,UTG,,AKs,AJo,A4s,QQ,JJ,TT,ATs,ATo,A2s,QTs,AA,KK,AKo,J9s,A7s,A8s,A9s,KTs,77,88,AQo,A3s,AJs,A6s,QJs,JTs,K9s,KQs,Q9s,KJo,99,KQo,AQs,KJs,A5s,T9s
Sample,100,OPEN,UTG,,AKs,AJo,A4s,QQ,JJ,TT,ATs,ATo,A2s,QTs,AA,KK,AKo,J9s,A7s,A8s,A9s,KTs,77,88,AQo,A3s,AJs,A6s,QJs,JTs,K9s,KQs,Q9s,KJo,99,KQo,AQs,KJs,A5s,T9s
Sample,100,OPEN,UTG1,,AKs,AJo,A4s,QQ,JJ,TT,ATs,ATo,A2s,QTs,AA,KK,AKo,J9s,66,A7s,A8s,A9s,KTs,77,88,AQo,A3s,AJs,A6s,QJs,JTs,K9s,KQs,Q9s,KJo,99,K8s,KQo,AQs,KJs,A5s,T9s
Sample,60,OPEN,UTG1,,AKs,AJo,A4s,QQ,JJ,TT,ATs,ATo,A2s,QTs,AA,KK,AKo,J9s,66,A7s,A8s,A9s,KTs,77,88,AQo,A3s,AJs,A6s,QJs,JTs,K9s,KQs,Q9s,KJo,99,K8s,KQo,AQs,KJs,A5s,T9s
Sample,40,OPEN,UTG1,,AKs,AJo,QQ,JJ,TT,ATs,ATo,QTs,AA,KK,AKo,J9s,66,A7s,A8s,A9s,KTs,77,88,AQo,A6s,AJs,QJs,JTs,K9s,KQs,Q9s,KJo,99,K8s,KQo,AQs,KJs,A5s,T9s
Sample,25,OPEN,UTG1,,AKs,AJo,QQ,JJ,TT,ATo,ATs,AA,QTs,KK,AKo,J9s,66,A7s,A8s,A9s,KTs,77,88,AQo,A6s,AJs,QJs,K9s,JTs,KQs,Q9s,KJo,99,KQo,K8s,AQs,KJs,A5s,T9s
Sample,100,OPEN,MP1,,AKs,AJo,A4s,QQ,JJ,TT,55,ATs,ATo,A2s,T8s,QTs,AA,KK,Q8s,AKo,J9s,66,A7s,A8s,A9s,KTs,77,88,AQo,A3s,AJs,A6s,QJs,JTs,K9s,KQs,Q9s,98s,KJo,99,K8s,KQo,AQs,J8s,KJs,A5s,T9s
Sample,60,OPEN,MP1,,AKs,AJo,A4s,QQ,JJ,TT,ATo,ATs,55,A2s,QTs,T8s,AA,KK,Q8s,AKo,J9s,66,A7s,A8s,A9s,KTs,77,AQo,88,A3s,QJs,A6s,AJs,K9s,JTs,KQs,Q9s,98s,KJo,99,KQo,K8s,AQs,J8s,KJs,A5s,T9s
Sample,40,OPEN,MP1,,AKs,AJo,A4s,QQ,JJ,TT,ATo,ATs,55,A2s,QTs,AA,KK,AKo,J9s,66,A7s,A8s,A9s,KTs,77,AQo,88,A3s,QJs,A6s,AJs,K9s,JTs,KQs,Q9s,98s,KJo,99,KQo,AQs,KJs,A5s,T9s
Sample,25,OPEN,MP1,,AKs,AJo,A4s,QQ,JJ,TT,55,ATs,ATo,A2s,AA,QTs,KK,AKo,J9s,66,A7s,A8s,A9s,KTs,77,AQo,88,A3s,AJs,A6s,QJs,JTs,K9s,KQs,Q9s,98s,KJo,99,KQo,AQs,KJs,A5s,T9s
Sample,100,OPEN,MP2,,AKs,AJo,86s,A4s,QQ,JJ,TT,55,ATs,ATo,A2s,T8s,QTs,AA,KK,44,Q8s,AKo,J9s,66,A7s,76s,A8s,A9s,97s,KTs,77,88,AQo,A3s,AJs,A6s,QJs,JTs,K9s,KQs,Q9s,98s,KJo,99,K8s,KQo,87s,AQs,J8s,KJs,A5s,T9s
Sample,100,OPEN,MP3,,AKs,AJo,86s,QQ,A4s,TT,ATo,A2s,QTs,J9s,66,A9s,97s,AQo,88,A3s,QJs,A6s,K9s,Q9s,98s,99,K8s,87s,J8s,T9s,JJ,ATs,55,AA,T8s,65s,KK,54s,44,Q8s,AKo,A7s,33,A8s,76s,75s,KTs,77,AJs,JTs,KQs,KJo,22,KQo,AQs,KJs,A5s
Sample,60,OPEN,MP2,,AKs,AJo,86s,A4s,QQ,JJ,TT,ATo,ATs,55,A2s,T8s,QTs,AA,KK,44,Q8s,AKo,J9s,66,A7s,76s,A8s,A9s,97s,KTs,77,AQo,88,A3s,QJs,A6s,AJs,K9s,JTs,KQs,Q9s,98s,KJo,99,KQo,K8s,AQs,87s,J8s,KJs,A5s,T9s
Sample,40,OPEN,MP2,,AKs,AJo,86s,A4s,QQ,JJ,TT,ATo,ATs,55,A2s,T8s,QTs,AA,KK,44,Q8s,AKo,J9s,66,A7s,76s,A8s,A9s,97s,KTs,77,AQo,88,A3s,QJs,A6s,AJs,K9s,JTs,KQs,Q9s,98s,KJo,99,KQo,K8s,AQs,87s,J8s,KJs,A5s,T9s
Sample,25,OPEN,MP2,,AKs,AJo,A4s,QQ,JJ,TT,55,ATs,ATo,A2s,QTs,AA,KK,44,AKo,J9s,66,A7s,76s,A8s,A9s,KTs,77,88,AQo,A3s,AJs,A6s,QJs,JTs,K9s,KQs,Q9s,98s,KJo,99,KQo,87s,AQs,KJs,A5s,T9s
Sample,60,OPEN,MP3,,AKs,AJo,86s,QQ,A4s,TT,ATo,A2s,QTs,J9s,66,A9s,97s,AQo,88,A3s,QJs,A6s,K9s,Q9s,98s,99,K8s,87s,J8s,T9s,JJ,ATs,55,AA,T8s,65s,KK,54s,44,Q8s,AKo,A7s,33,A8s,76s,75s,KTs,77,AJs,JTs,KQs,KJo,22,KQo,AQs,KJs,A5s
Sample,40,OPEN,MP3,,AKs,AJo,86s,QQ,A4s,TT,ATo,A2s,QTs,J9s,66,A9s,97s,AQo,88,A3s,QJs,A6s,K9s,Q9s,98s,99,K8s,87s,J8s,T9s,JJ,ATs,55,AA,T8s,65s,KK,54s,44,Q8s,AKo,A7s,33,A8s,76s,75s,KTs,77,AJs,JTs,KQs,KJo,22,KQo,AQs,KJs,A5s
Sample,25,OPEN,MP3,,AKs,AJo,QQ,A4s,TT,ATo,A2s,QTs,J9s,66,A9s,AQo,88,A3s,QJs,A6s,K9s,Q9s,98s,99,K8s,87s,J8s,T9s,JJ,ATs,55,AA,T8s,KK,44,Q8s,AKo,A7s,33,A8s,76s,KTs,77,AJs,JTs,KQs,KJo,22,KQo,AQs,KJs,A5s
Sample,100,OPEN,CO,,AKs,AJo,A4s,QQ,86s,TT,ATo,A2s,QTs,KTo,J9s,66,A9s,97s,AQo,88,A3s,A6s,QJs,K9s,Q9s,98s,99,K8s,87s,J8s,T9s,JJ,ATs,55,JTo,65s,T8s,AA,QTo,KK,54s,44,Q8s,A9o,AKo,A7s,33,76s,A8s,75s,KTs,77,AJs,JTs,KQs,KJo,QJo,22,K7s,KQo,AQs,KJs,A5s
Sample,60,OPEN,CO,,AKs,AJo,A4s,QQ,86s,TT,ATo,A2s,QTs,KTo,J9s,66,A9s,97s,AQo,88,A3s,A6s,QJs,K9s,Q9s,98s,99,K8s,87s,J8s,T9s,JJ,ATs,55,JTo,65s,T8s,AA,QTo,KK,54s,44,Q8s,A9o,AKo,A7s,33,76s,A8s,75s,KTs,77,AJs,JTs,KQs,KJo,QJo,22,K7s,KQo,AQs,KJs,A5s
Sample,40,OPEN,CO,,AKs,AJo,A4s,QQ,TT,ATo,A2s,QTs,KTo,J9s,66,A9s,AQo,88,A3s,A6s,QJs,K9s,Q9s,98s,99,K8s,87s,J8s,T9s,JJ,ATs,55,JTo,65s,T8s,AA,QTo,KK,54s,44,Q8s,A9o,AKo,A7s,33,76s,A8s,KTs,77,AJs,JTs,KQs,KJo,QJo,22,KQo,AQs,KJs,A5s
Sample,25,OPEN,CO,,AKs,AJo,QQ,A4s,TT,ATo,A2s,QTs,KTo,J9s,66,A9s,88,AQo,A3s,QJs,A6s,K9s,Q9s,98s,99,K8s,87s,J8s,T9s,JJ,ATs,55,JTo,65s,T8s,AA,QTo,KK,54s,44,Q8s,A9o,AKo,A7s,33,76s,A8s,KTs,77,AJs,JTs,KQs,KJo,QJo,22,K7s,KQo,AQs,KJs,A5s
Sample,100,OPEN,BTN,,Q9o,AKs,AJo,A6o,A4s,QQ,86s,TT,ATo,76o,A2s,QTs,KTo,A7o,J9s,K6s,66,A9s,K5s,97s,K3s,T9o,AQo,88,A3s,A6s,QJs,K9s,Q9s,98s,K2s,53s,99,K8s,87s,K4s,J8s,43s,T9s,T7s,A8o,JJ,K8o,ATs,55,87o,JTo,65s,T8s,AA,Q7s,QTo,KK,54s,44,Q8s,32s,A9o,AKo,98o,A7s,33,76s,A8s,75s,KTs,77,AJs,K9o,J9o,JTs,KQs,KJo,QJo,J7s,22,K7s,KQo,AQs,KJs,A5s,42s,64s,A5o
Sample,60,OPEN,BTN,,Q9o,AKs,AJo,A6o,A4s,QQ,86s,TT,ATo,76o,A2s,QTs,KTo,A7o,J9s,K6s,66,A9s,K5s,97s,K3s,T9o,AQo,88,A3s,A6s,QJs,K9s,Q9s,98s,K2s,53s,99,K8s,87s,K4s,J8s,43s,T9s,T7s,A8o,JJ,K8o,ATs,55,65s,JTo,AA,T8s,87o,Q7s,QTo,KK,54s,44,Q8s,32s,A9o,AKo,98o,A7s,33,76s,A8s,75s,KTs,77,AJs,K9o,J9o,JTs,KQs,KJo,QJo,J7s,22,K7s,KQo,AQs,KJs,A5s,42s,64s,A5o
Sample,40,OPEN,BTN,,Q9o,AKs,AJo,A6o,A4s,QQ,86s,TT,ATo,76o,A2s,QTs,KTo,A7o,J9s,K6s,66,A9s,K5s,97s,K3s,T9o,AQo,88,A3s,A6s,QJs,K9s,Q9s,98s,K2s,53s,99,K8s,87s,K4s,J8s,43s,T9s,T7s,A8o,JJ,K8o,ATs,55,65s,JTo,AA,T8s,87o,Q7s,QTo,KK,54s,44,Q8s,32s,A9o,AKo,98o,A7s,33,76s,A8s,75s,KTs,77,AJs,K9o,J9o,JTs,KQs,KJo,QJo,J7s,22,K7s,KQo,AQs,KJs,A5s,42s,64s,A5o
Sample,25,OPEN,BTN,,Q9o,AKs,AJo,QQ,A4s,TT,ATo,76o,A2s,QTs,KTo,J9s,K6s,66,A9s,97s,K5s,K3s,T9o,88,AQo,A3s,QJs,A6s,K9s,Q9s,98s,K2s,99,K8s,87s,K4s,J8s,T9s,T7s,A8o,JJ,ATs,55,65s,JTo,AA,T8s,87o,Q7s,QTo,KK,44,Q8s,A9o,AKo,98o,33,A7s,A8s,76s,KTs,77,AJs,K9o,JTs,J9o,KQs,KJo,QJo,22,J7s,K7s,KQo,AQs,KJs,A5s,A5o
Sample,60,OPEN,SB,,Q9o,Q4s,86s,QQ,83s,A4o,J9s,K6s,J4s,85s,66,88,AQo,A6s,T8o,Q9s,98s,53s,99,Q8o,74s,87s,J8s,K7o,T9s,84s,J6s,ATs,JTo,T8s,87o,KK,Q7s,T4s,44,J2s,33,A8s,76s,75s,KTs,Q6s,95s,77,A2o,K9o,J9o,KQs,73s,KJo,QJo,Q2s,K7s,KQo,AQs,KJs,64s,42s,52s,AKs,AJo,A6o,A4s,TT,ATo,A2s,QTs,KTo,93s,96s,T3s,A7o,Q5s,A9s,K5s,97s,K3s,T9o,A3s,QJs,K9s,K5o,T5s,K2s,K6o,82s,72s,92s,J3s,K8s,K4s,43s,T7s,A3o,A8o,J5s,JJ,K8o,55,AA,65s,QTo,54s,Q8s,32s,A9o,AKo,98o,J8o,T6s,A7s,62s,T2s,63s,AJs,JTs,22,J7s,94s,A5s,A5o,Q3s
Sample,100,OPEN,SB,,Q9o,Q4s,86s,QQ,83s,A4o,J9s,85s,J4s,K6s,66,88,AQo,A6s,T8o,Q9s,98s,53s,99,Q8o,74s,87s,J8s,K7o,84s,T9s,J6s,ATs,87o,T8s,JTo,T4s,Q7s,KK,44,J2s,33,76s,A8s,75s,95s,Q6s,KTs,77,A2o,K9o,J9o,KQs,73s,KJo,QJo,Q2s,K7s,KQo,AQs,KJs,64s,42s,52s,AKs,AJo,A6o,A4s,TT,ATo,A2s,QTs,KTo,93s,96s,T3s,A7o,Q5s,A9s,K5s,97s,K3s,T9o,A3s,QJs,K9s,K5o,T5s,K2s,K6o,82s,72s,92s,J3s,K8s,K4s,43s,T7s,A3o,A8o,J5s,JJ,K8o,55,65s,AA,QTo,54s,Q8s,A9o,32s,AKo,98o,J8o,T6s,62s,A7s,T2s,63s,AJs,JTs,J7s,22,94s,A5s,Q3s,A5o
Sample,40,OPEN,SB,,Q9o,Q4s,86s,QQ,83s,A4o,J9s,K6s,J4s,85s,66,88,AQo,A6s,T8o,Q9s,98s,53s,99,Q8o,74s,87s,J8s,K7o,T9s,84s,J6s,ATs,JTo,T8s,87o,KK,Q7s,T4s,44,J2s,33,A8s,76s,75s,KTs,Q6s,95s,77,A2o,K9o,J9o,KQs,73s,KJo,QJo,Q2s,K7s,KQo,AQs,KJs,64s,42s,52s,AKs,AJo,A6o,A4s,TT,ATo,A2s,QTs,KTo,93s,96s,T3s,A7o,Q5s,A9s,K5s,97s,K3s,T9o,A3s,QJs,K9s,K5o,T5s,K2s,K6o,82s,72s,92s,J3s,K8s,K4s,43s,T7s,A3o,A8o,J5s,JJ,K8o,55,AA,65s,QTo,54s,Q8s,32s,A9o,AKo,98o,J8o,T6s,A7s,62s,T2s,63s,AJs,JTs,22,J7s,94s,A5s,A5o,Q3s
Sample,15,FOUR_BET,SB,BB,66,KJs,KTs,KTo,TT,AQo,AJo,77,KJo,99,A9s,AA,JJ,ATo,QTs,JTs,QQ,KK,ATs,QJs,AJs,KQs,AKs,AQs,QJo,88,AKo,KQo
Sample,15,FOUR_BET,UTG,BB,KQs,AJs,AKs,AQs,TT,AKo,QQ,KK,AQo,AA,JJ
Sample,25,CALL,UTG1,UTG,AJo,KTs,JTs,QJs,KQo,99,TT,ATs,88,KJs,AQo,QTs,JJ
Sample,40,CALL,UTG1,UTG,AJo,KTs,JTs,QJs,KQo,99,TT,ATs,88,KJs,AQo,QTs,JJ
Sample,60,CALL,UTG1,UTG,TT,AQo,A9s,KTs,QTs,88,AJo,KQo,KJs,QJs,99,JTs,ATs,JJ
Sample,100,CALL,UTG1,UTG,TT,AQo,A9s,KTs,QTs,88,KQo,AJo,KJs,QJs,99,JTs,ATs,JJ
Sample,100,THREE_BET,UTG1,UTG,KQs,AQs,KK,AA,K9s,A8s,QQ,AJs,AKs,AKo
Sample,25,THREE_BET,UTG1,UTG,A9s,AKo,QQ,AQs,KK,AQo,AJs,AA,KQs,K9s,AKs,JJ
Sample,25,CALL,MP1,UTG1,QJs,A9s,KQo,66,88,99,ATs,JTs,77,TT,QTs,A8s,KJs,AQo,KTs
Sample,60,THREE_BET,UTG1,UTG,KQs,AQs,KK,AA,K9s,A8s,QQ,AJs,AKs,AKo
Sample,40,THREE_BET,UTG1,UTG,KQs,AQs,KK,AA,K9s,A8s,QQ,AJs,AKs,AKo
Sample,100,CALL,MP1,UTG,A9s,QJs,KQo,T9s,AJo,88,99,ATs,JTs,77,TT,J9s,QTs,KJs,KTs,K9s,Q9s
Sample,100,THREE_BET,MP1,UTG,KQs,AJs,AKs,AQs,QQ,KK,AA,JJ
Sample,60,THREE_BET,MP1,UTG,AJs,KQs,AKs,AQs,TT,QQ,KK,AA,JJ
Sample,15,CALL,MP1,UTG,KTs,KJs,88,KQo,TT,ATs,QJs,AJo,QTs,99,JTs
Sample,15,CALL,MP2,UTG,KTs,KJs,88,KQo,TT,ATs,QJs,AJo,QTs,99,JTs
Sample,15,CALL,MP3,UTG,KTs,ATo,KJo,JTs,KJs,KQo,88,TT,66,ATs,QJs,AJo,77,99,QTs
Sample,15,CALL,CO,UTG,KTs,ATo,KJo,T9s,JTs,KJs,KQo,88,TT,66,ATs,98s,QJs,AJo,77,QTs,99
Sample,15,CALL,BTN,UTG,AJs,44,J9s,KJs,88,KQo,TT,ATs,AJo,76s,AQo,A4s,QTs,A5s,KTs,A9o,ATo,KJo,55,A9s,T9s,JTs,K9s,66,87s,Q9s,98s,QJs,77,99
Sample,15,CALL,SB,UTG,AJs,44,J9s,KJs,88,TT,ATs,76s,AQo,QTs,KTs,55,JTs,T9s,A9s,K9s,Q9s,87s,66,98s,QJs,77,99
Sample,15,CALL,BB,UTG,A3s,44,A6s,A2o,JTo,A7o,88,T9o,TT,Q5s,K5s,K3s,Q8s,AQo,A4s,T7s,K9o,KTs,T8o,A6o,KJo,A4o,75s,QJo,JTs,T9s,A8o,K9s,J9o,54s,J7s,Q9s,87s,98s,77,99,K8s,T8s,AJs,86s,T6s,A8s,98o,J9s,K8o,Q8o,J5s,43s,Q9o,22,KJs,K6s,KQo,K2s,ATs,KTo,64s,A2s,QTo,74s,53s,76s,AJo,33,QTs,A5s,A9o,97s,ATo,Q7s,K7s,55,A9s,J8o,J8s,65s,Q6s,66,K4s,96s,A5o,QJs,J6s,A7s,A3o
Sample,25,CALL,BB,UTG,A3s,44,A6s,A2o,JTo,A7o,88,T9o,TT,Q5s,K5s,K3s,Q8s,AQo,A4s,T7s,K9o,KTs,T8o,A6o,KJo,A4o,75s,QJo,JTs,T9s,A8o,K9s,J9o,54s,J7s,Q9s,87s,98s,77,99,K8s,T8s,AJs,86s,T6s,A8s,98o,J9s,K8o,Q8o,J5s,43s,Q9o,22,KJs,K6s,KQo,K2s,ATs,KTo,64s,A2s,QTo,74s,53s,76s,AJo,33,QTs,A5s,A9o,97s,ATo,Q7s,K7s,55,A9s,J8o,J8s,65s,Q6s,66,K4s,96s,A5o,QJs,J6s,A7s,A3o
Sample,25,CALL,MP1,UTG,KTs,J9s,T9s,A9s,JTs,KJs,KQo,88,TT,K9s,Q9s,ATs,QJs,AJo,77,99,QTs
Sample,25,CALL,MP2,UTG,KTs,J9s,JTs,A9s,T9s,KJs,KQo,88,TT,K9s,Q9s,ATs,QJs,AJo,77,QTs,99
Sample,25,CALL,MP3,UTG,K8s,KTs,A8s,J9s,JTs,A9s,T9s,KJs,KQo,88,TT,K9s,66,Q9s,ATs,QJs,AJo,77,QTs,99
Sample,25,CALL,CO,UTG,K8s,T8s,44,A8s,J9s,KJs,88,KQo,TT,ATs,Q8s,AJo,AQo,QTs,KTs,ATo,KJo,55,T9s,A9s,JTs,J8s,K9s,Q9s,66,87s,98s,QJs,77,99
Sample,25,CALL,BTN,UTG,A3s,K8s,T8s,44,A6s,A8s,J9s,22,KJs,KQo,88,TT,ATs,A2s,Q8s,76s,AJo,AQo,33,A4s,QTs,A5s,KTs,ATo,KJo,55,T9s,A9s,JTs,J8s,K9s,65s,87s,66,Q9s,A5o,98s,QJs,77,A7s,99
Sample,25,CALL,SB,UTG,AJs,44,J9s,KJs,88,TT,ATs,76s,AQo,QTs,KTs,55,A9s,T9s,JTs,K9s,66,87s,Q9s,98s,QJs,77,99
Sample,40,CALL,BB,UTG,A3s,44,A2o,JTo,T9o,88,TT,K5s,Q5s,86o,AQo,T7s,KTs,A4o,QJo,75s,J9o,K9s,J7s,54s,98s,AJs,T8s,T6s,86s,A8s,Q9o,K7o,KJs,22,KQo,K6s,64o,74s,53s,33,76o,A5s,ATo,Q7s,K7s,55,K6o,A9s,J8o,J8s,66,Q6s,65s,K4s,QJs,A7s,97o,A6s,K5o,A7o,K3s,Q8s,A4s,K9o,T8o,65o,A6o,KJo,T9s,JTs,A8o,87s,Q9s,75o,77,Q7o,99,K8s,98o,Q8o,K8o,J9s,43s,J5s,87o,ATs,K2s,KTo,64s,QTo,A2s,AJo,76s,QTs,A9o,97s,J7o,54o,96s,A5o,Q6o,J6s,A3o
Sample,60,CALL,BB,UTG,A3s,44,A2o,JTo,T9o,88,TT,Q5s,K5s,86o,AQo,T7s,KTs,A4o,QJo,75s,J9o,K9s,J7s,54s,98s,AJs,T8s,86s,T6s,A8s,K7o,Q9o,22,KJs,K6s,KQo,64o,74s,53s,33,76o,A5s,ATo,Q7s,K7s,K6o,55,A9s,J8o,J8s,Q6s,66,65s,K4s,QJs,A7s,97o,A6s,K5o,A7o,K3s,Q8s,A4s,K9o,T8o,65o,A6o,KJo,T9s,JTs,A8o,87s,Q9s,75o,77,Q7o,99,K8s,98o,Q8o,K8o,J9s,43s,J5s,87o,ATs,K2s,KTo,64s,QTo,A2s,AJo,76s,QTs,A9o,97s,J7o,54o,96s,A5o,Q6o,J6s,A3o
Sample,100,CALL,BB,UTG,A3s,44,A2o,JTo,T9o,88,TT,Q5s,K5s,86o,AQo,T7s,KTs,A4o,QJo,75s,J9o,K9s,J7s,54s,98s,AJs,T8s,86s,T6s,A8s,K7o,Q9o,22,KJs,K6s,KQo,64o,74s,53s,33,76o,A5s,ATo,Q7s,K7s,K6o,55,A9s,J8o,J8s,Q6s,66,65s,K4s,QJs,A7s,97o,A6s,K5o,A7o,K3s,Q8s,A4s,K9o,T8o,65o,A6o,KJo,T9s,JTs,A8o,87s,Q9s,75o,77,Q7o,99,K8s,98o,Q8o,K8o,J9s,43s,J5s,87o,ATs,K2s,KTo,64s,QTo,A2s,AJo,76s,QTs,A9o,97s,J7o,54o,96s,A5o,Q6o,J6s,A3o
Sample,100,CALL,MP1,UTG1,QJs,A9s,KQo,T9s,66,AJo,88,99,ATs,JTs,77,TT,QTs,A8s,KJs,KTs,AQo,98s
Sample,100,CALL,MP2,UTG,QJs,A9s,KQo,T9s,66,AJo,A5s,88,99,ATs,JTs,77,87s,TT,J9s,QTs,A8s,KJs,KTs,K9s,Q9s,98s
Sample,100,CALL,MP3,UTG,QJs,A9s,KQo,T9s,66,AJo,A5s,88,99,K8s,ATs,JTs,77,87s,TT,J9s,QTs,A8s,KJs,KTs,K9s,Q9s,98s
Sample,100,CALL,CO,UTG,99,88,A5s,K8s,A6s,JTs,A7s,T8s,87s,77,J9s,97s,KJs,K9s,55,A9s,QJs,ATo,T9s,KQo,66,86s,AJo,KJo,ATs,44,TT,76s,QTs,A8s,KTs,Q9s,98s
Sample,100,CALL,BTN,UTG,A5s,88,99,K8s,A6s,JTs,A7s,77,87s,T8s,J9s,97s,KJs,K9s,55,A9s,QJs,ATo,T9s,KQo,66,86s,AJo,KJo,ATs,44,TT,76s,QTs,A8s,KTs,Q9s,98s
Sample,100,CALL,SB,UTG,A5s,88,99,K8s,A6s,JTs,A7s,77,87s,T8s,KQs,J9s,97s,KJs,K9s,55,A9s,QJs,T9s,66,86s,ATs,AJs,44,JJ,TT,76s,QTs,A8s,AQo,KTs,Q9s,98s
Sample,15,CALL,BB,SB,T7o,96s,Q7s,66,65o,A2s,J9o,76o,J7s,87s,86o,32s,T9s,KJo,99,Q8o,A9s,65s,42s,QTs,JTs,J8s,T5s,A7s,K6o,QJs,T7s,A9o,KQo,76s,K2s,K8o,75s,85s,A4s,K4o,KTo,Q9o,QTo,Q5o,A5s,T9o,Q5s,43s,K7s,Q7o,J4s,Q6o,98o,A6o,J9s,A2o,86s,95s,Q8s,74s,88,J7o,T8s,KTs,K3s,TT,Q2s,K5s,Q9s,JTo,77,33,T4s,A4o,22,98s,T8o,ATo,A3o,J2s,T6o,97o,55,T2s,K8s,Q3s,54s,53s,K9o,A8o,K6s,J6o,K2o,84s,KJs,K9s,AQo,75o,T3s,K3o,J6s,AJo,K4s,Q6s,A5o,K5o,94s,Q4s,J8o,87o,44,64o,ATs,54o,AJs,J3s,T6s,A3s,A8s,K7o,QJo,A6s,A7o,J5s,64s,96o,97s
Sample,40,CALL,MP1,UTG,QJs,A9s,KQo,T9s,AJo,99,88,ATs,JTs,77,TT,J9s,QTs,KJs,KTs,K9s,Q9s
Sample,40,CALL,MP2,UTG,QJs,A9s,KQo,T9s,66,AJo,A5s,88,99,ATs,JTs,77,87s,TT,J9s,QTs,A8s,KJs,KTs,K9s,Q9s,98s
Sample,40,CALL,MP3,UTG,QJs,A9s,KQo,T9s,66,AJo,A5s,88,99,K8s,ATs,JTs,77,87s,TT,J9s,QTs,A8s,KJs,KTs,K9s,Q9s,98s
Sample,60,CALL,MP1,UTG,QJs,A9s,KQo,T9s,AJo,99,88,ATs,JTs,77,TT,J9s,QTs,KJs,KTs,K9s,Q9s
Sample,60,CALL,SB,UTG,A5s,88,99,K8s,A6s,JTs,A7s,77,87s,T8s,KQs,J9s,97s,KJs,K9s,55,A9s,QJs,T9s,66,86s,ATs,AJs,44,JJ,TT,76s,QTs,A8s,AQo,KTs,Q9s,98s
Sample,40,CALL,SB,UTG,A5s,88,99,K8s,A6s,JTs,A7s,77,87s,T8s,KQs,J9s,97s,KJs,K9s,55,A9s,QJs,T9s,66,86s,ATs,AJs,44,JJ,TT,76s,QTs,A8s,AQo,KTs,Q9s,98s
Sample,40,CALL,BTN,UTG,A5s,88,99,K8s,A6s,JTs,A7s,77,87s,T8s,J9s,97s,KJs,K9s,55,A9s,QJs,ATo,T9s,KQo,66,86s,AJo,KJo,ATs,44,TT,76s,QTs,A8s,KTs,Q9s,98s
Sample,60,CALL,BTN,UTG,A5s,88,99,K8s,A6s,JTs,A7s,77,87s,T8s,J9s,97s,KJs,K9s,55,A9s,QJs,ATo,T9s,KQo,66,86s,AJo,KJo,ATs,44,TT,76s,QTs,A8s,KTs,Q9s,98s
Sample,40,CALL,CO,UTG,99,88,A5s,K8s,A6s,JTs,A7s,T8s,87s,77,J9s,97s,KJs,K9s,55,A9s,QJs,ATo,T9s,KQo,66,86s,AJo,KJo,ATs,44,TT,76s,QTs,A8s,KTs,Q9s,98s
Sample,60,CALL,CO,UTG,99,88,A5s,K8s,A6s,JTs,A7s,T8s,87s,77,J9s,97s,KJs,K9s,55,A9s,QJs,ATo,T9s,KQo,66,86s,AJo,KJo,ATs,44,TT,76s,QTs,A8s,KTs,Q9s,98s
Sample,60,CALL,MP3,UTG,QJs,A9s,KQo,T9s,66,AJo,A5s,88,99,K8s,ATs,JTs,77,87s,TT,J9s,QTs,A8s,KJs,KTs,K9s,Q9s,98s
Sample,60,CALL,MP2,UTG,QJs,A9s,KQo,T9s,66,AJo,A5s,88,99,ATs,JTs,77,87s,TT,J9s,QTs,A8s,KJs,KTs,K9s,Q9s,98s
Sample,25,CALL,MP2,UTG1,QJs,A9s,ATo,KQo,66,AJo,88,99,KJo,ATs,JTs,77,TT,QTs,A8s,KJs,AQo,KTs,K9s
Sample,40,CALL,MP2,UTG1,QJs,A9s,ATo,T9s,KQo,66,AJo,88,99,KJo,ATs,JTs,77,TT,J9s,QTs,A8s,KJs,AQo,KTs,K9s,Q9s,98s
Sample,60,CALL,MP2,UTG1,QJs,A9s,ATo,KQo,T9s,66,AJo,88,99,KJo,ATs,JTs,77,TT,J9s,QTs,A8s,KJs,KTs,AQo,K9s,Q9s,98s
Sample,100,CALL,MP2,UTG1,QJs,A9s,ATo,KQo,T9s,66,AJo,88,99,KJo,ATs,JTs,77,TT,J9s,QTs,A8s,KJs,KTs,AQo,K9s,Q9s,98s
Sample,60,CALL,MP1,UTG1,QJs,A9s,KQo,T9s,66,AJo,88,99,ATs,JTs,77,TT,QTs,A8s,KJs,KTs,AQo,98s
Sample,40,CALL,MP1,UTG1,QJs,A9s,KQo,T9s,66,AJo,88,99,ATs,JTs,77,TT,QTs,A8s,KJs,KTs,AQo,98s
Sample,15,CALL,MP3,UTG1,QJs,A9s,ATo,KQo,66,AJo,88,99,KJo,ATs,JTs,77,TT,QTs,A8s,KJs,AQo,KTs,K9s
Sample,100,CALL,MP3,UTG1,AJo,KQo,J9s,66,AQo,55,QTs,KJo,Q9s,KTs,88,77,T9s,98s,JTs,ATo,99,QJs,ATs,K9s,TT,A8s,KJs,A9s
Sample,100,CALL,CO,UTG1,AJo,66,J9s,A5s,A6s,AQo,55,QTs,76s,KTs,88,77,87s,ATo,K9s,K8s,A9s,KQo,A7s,KJo,Q9s,T9s,98s,JTs,99,QJs,ATs,TT,A8s,KJs
Sample,100,CALL,BTN,UTG1,AJo,J9s,66,A5s,A6s,AQo,55,QTs,76s,77,88,KTs,87s,ATo,33,K9s,K8s,A9s,KQo,A7s,KJo,Q9s,T9s,44,98s,JTs,QJs,99,ATs,TT,A8s,KJs
Sample,100,CALL,SB,UTG1,AJo,KQo,AQo,QTs,JJ,KTs,88,JTs,QJs,99,ATs,TT,AJs,A9s,KJs
Sample,100,CALL,BB,UTG1,AJo,75o,K9o,JTo,87o,43s,J6s,64s,54o,77,KTs,86s,33,K9s,K4s,K6s,Q6s,A7s,Q9s,22,J7o,53s,KJs,KTo,66,T8o,A6s,A5s,97o,QTs,98o,76s,J9o,T8s,K8o,65s,Q5s,A9o,A4o,ATs,K3s,J9s,J7s,97s,AQo,88,K5o,T6s,87s,A7o,76o,75s,A9s,K8s,Q6o,J8o,KQo,KJo,65o,Q7o,T9s,98s,A2o,99,K5s,A8s,T7s,AJs,J8s,K6o,A5o,QJo,55,Q7s,K2s,K7o,A2s,Q9o,ATo,T9o,A6o,86o,54s,K7s,Q8s,Q8o,QTo,A3s,J5s,44,JTs,96s,QJs,TT,A8o,A3o,A4s,64o,74s
Sample,60,CALL,BB,UTG1,AJo,75o,K9o,JTo,87o,43s,J6s,64s,54o,77,KTs,86s,33,K9s,K4s,K6s,Q6s,A7s,Q9s,22,J7o,53s,KJs,KTo,66,T8o,A6s,A5s,97o,QTs,98o,76s,J9o,T8s,K8o,65s,Q5s,A9o,A4o,ATs,K3s,J9s,J7s,97s,AQo,88,K5o,T6s,87s,A7o,76o,75s,A9s,K8s,Q6o,J8o,KQo,KJo,65o,Q7o,T9s,98s,A2o,99,K5s,A8s,T7s,AJs,J8s,K6o,A5o,QJo,55,Q7s,K2s,K7o,A2s,Q9o,ATo,T9o,A6o,86o,54s,K7s,Q8s,Q8o,QTo,A3s,J5s,44,JTs,96s,QJs,TT,A8o,A3o,A4s,64o,74s
Sample,40,CALL,BB,UTG1,AJo,75o,K9o,JTo,87o,43s,J6s,64s,54o,77,KTs,86s,33,K9s,K4s,K6s,Q6s,A7s,Q9s,22,J7o,53s,KJs,KTo,66,T8o,A6s,A5s,97o,QTs,98o,76s,J9o,T8s,K8o,65s,Q5s,A9o,A4o,ATs,K3s,J9s,J7s,97s,AQo,88,K5o,T6s,87s,A7o,76o,75s,A9s,K8s,Q6o,J8o,KQo,KJo,65o,Q7o,T9s,98s,A2o,99,K5s,A8s,T7s,AJs,J8s,K6o,A5o,QJo,55,Q7s,K2s,K7o,A2s,Q9o,ATo,T9o,A6o,86o,54s,K7s,Q8s,Q8o,QTo,A3s,J5s,44,JTs,96s,QJs,TT,A8o,A3o,A4s,64o,74s
Sample,25,CALL,BB,UTG1,AJo,75o,K9o,JTo,87o,43s,54o,77,KTs,86s,33,K9s,K4s,K6s,Q6s,A7s,Q9s,22,KJs,KTo,66,T8o,A6s,A5s,97o,QTs,98o,76s,J9o,T8s,K8o,65s,A9o,A4o,ATs,K3s,J9s,97s,AQo,88,87s,A7o,76o,75s,A9s,K8s,J8o,KQo,KJo,65o,T9s,98s,A2o,99,K5s,A8s,AJs,J8s,A5o,QJo,55,Q7s,K2s,A2s,Q9o,ATo,T9o,A6o,86o,54s,K7s,Q8s,Q8o,QTo,A3s,44,JTs,QJs,TT,A8o,A3o,A4s,64o
Sample,15,CALL,BB,UTG1,AJo,75o,K9o,K3s,JTo,J9s,87o,43s,97s,AQo,54o,77,KTs,88,86s,87s,A7o,33,K9s,K4s,76o,75s,A9s,K8s,K6s,Q6s,J8o,KQo,A7s,KJo,Q9s,65o,22,T9s,98s,A2o,99,K5s,A8s,AJs,KJs,J8s,A5o,KTo,QJo,66,T8o,A5s,A6s,55,97o,K2s,Q7s,QTs,98o,76s,A2s,J9o,Q9o,ATo,A6o,T9o,86o,54s,T8s,K8o,K7s,65s,Q8o,Q8s,QTo,A3s,JTs,44,A9o,QJs,A4o,ATs,TT,A4s,A3o,A8o,64o
Sample,15,CALL,SB,UTG1,AJo,KQo,AQo,QTs,JJ,KTs,88,JTs,QJs,99,ATs,TT,AJs,A9s,KJs
Sample,60,CALL,SB,UTG1,AJo,KQo,AQo,QTs,JJ,KTs,88,JTs,QJs,99,ATs,TT,AJs,A9s,KJs
Sample,40,CALL,SB,UTG1,AJo,KQo,AQo,QTs,JJ,KTs,88,JTs,QJs,99,ATs,TT,AJs,A9s,KJs
Sample,25,CALL,SB,UTG1,AJo,KQo,AQo,QTs,JJ,KTs,88,JTs,QJs,99,ATs,TT,AJs,A9s,KJs
Sample,15,CALL,BTN,UTG1,AJo,66,J9s,A5s,A6s,AQo,55,QTs,KTs,88,77,ATo,K9s,K8s,A9s,KQo,A7s,KJo,Q9s,T9s,JTs,QJs,99,ATs,TT,A8s,KJs
Sample,60,CALL,BTN,UTG1,AJo,J9s,66,A5s,A6s,AQo,55,QTs,76s,77,88,KTs,87s,ATo,33,K9s,K8s,A9s,KQo,A7s,KJo,Q9s,T9s,44,98s,JTs,QJs,99,ATs,TT,A8s,KJs
Sample,40,CALL,BTN,UTG1,AJo,J9s,66,A5s,A6s,AQo,55,QTs,76s,77,88,KTs,87s,ATo,33,K9s,K8s,A9s,KQo,A7s,KJo,Q9s,T9s,44,98s,JTs,QJs,99,ATs,TT,A8s,KJs
Sample,25,CALL,BTN,UTG1,AJo,J9s,66,A5s,A6s,AQo,55,QTs,77,88,KTs,ATo,K9s,K8s,A9s,KQo,A7s,KJo,Q9s,T9s,JTs,QJs,99,ATs,TT,A8s,KJs
Sample,15,CALL,CO,UTG1,AJo,KQo,66,J9s,AQo,A5s,QTs,Q9s,KTs,88,77,T9s,JTs,QJs,99,ATs,K9s,TT,KJs,A9s
Sample,60,CALL,CO,UTG1,AJo,66,J9s,A5s,A6s,AQo,55,QTs,76s,KTs,88,77,87s,ATo,K9s,K8s,A9s,KQo,A7s,KJo,Q9s,T9s,98s,JTs,99,QJs,ATs,TT,A8s,KJs
Sample,40,CALL,CO,UTG1,AJo,66,J9s,A5s,A6s,AQo,55,QTs,76s,KTs,88,77,87s,ATo,K9s,K8s,A9s,KQo,A7s,KJo,Q9s,T9s,98s,JTs,99,QJs,ATs,TT,A8s,KJs
Sample,25,CALL,CO,UTG1,AJo,66,J9s,A5s,AQo,QTs,KTs,88,77,K9s,A9s,KQo,Q9s,T9s,JTs,99,QJs,ATs,TT,KJs
Sample,25,CALL,MP3,UTG1,AJo,KQo,66,AQo,QTs,KJo,KTs,88,77,JTs,ATo,99,QJs,ATs,K9s,TT,A8s,A9s,KJs
Sample,40,CALL,MP3,UTG1,AJo,KQo,66,AQo,QTs,KJo,KTs,88,77,JTs,ATo,99,QJs,ATs,K9s,TT,A8s,A9s,KJs
Sample,60,CALL,MP3,UTG1,AJo,66,J9s,AQo,55,QTs,77,88,KTs,ATo,K9s,A9s,KQo,KJo,Q9s,T9s,JTs,98s,QJs,99,ATs,TT,A8s,KJs
Sample,100,CALL,MP2,MP1,AJo,J9s,66,A5s,A6s,AQo,55,QTs,88,77,KTs,ATo,K9s,A9s,KQo,A7s,KJo,Q9s,T9s,98s,JTs,QJs,99,ATs,TT,A8s,KJs
Sample,100,CALL,MP3,MP1,AJo,66,J9s,97s,A5s,A6s,AQo,55,QTs,76s,KTs,77,88,87s,A2s,ATo,K9s,A9s,T8s,KQo,A7s,KJo,Q9s,A3s,T9s,44,98s,JTs,QJs,99,ATs,TT,A4s,A8s,KJs
Sample,100,CALL,CO,MP1,AJo,J9s,66,AQo,97s,A5s,A6s,55,QTs,76s,77,KTs,88,87s,A2s,ATo,K9s,A9s,T8s,KQo,A7s,KJo,Q9s,A3s,T9s,JTs,98s,44,99,QJs,ATs,TT,A8s,A4s,KJs
Sample,100,CALL,BTN,MP1,J8s,AJo,KTo,QJo,JTo,J9s,66,AQo,97s,A5s,A6s,55,QTs,76s,77,KTs,88,86s,87s,A2s,ATo,33,K9s,K8s,A9s,T8s,KQo,A7s,KJo,Q8s,Q9s,QTo,22,A3s,T9s,A9o,JTs,98s,44,99,QJs,ATs,TT,A8s,A4s,KJs
Sample,100,CALL,SB,MP1,AJo,KQo,J9s,66,AQo,QTs,JJ,Q9s,77,KTs,88,T9s,JTs,QJs,99,K9s,ATs,TT,A8s,AJs,A9s,KJs
Sample,100,CALL,BB,MP1,AJo,75o,K9o,JTo,87o,43s,J6s,64s,54o,77,KTs,86s,33,K9s,K4s,K6s,Q6s,A7s,Q9s,22,J7o,53s,KJs,KTo,66,T8o,A6s,A5s,97o,QTs,98o,76s,J9o,T8s,K8o,65s,Q5s,A9o,A4o,ATs,K3s,J9s,J7s,97s,AQo,88,K5o,T6s,87s,A7o,76o,75s,A9s,K8s,Q6o,J8o,KQo,KJo,65o,Q7o,T9s,98s,A2o,99,K5s,A8s,T7s,AJs,J8s,K6o,A5o,QJo,55,Q7s,K2s,K7o,A2s,Q9o,ATo,T9o,A6o,86o,54s,K7s,Q8s,Q8o,QTo,A3s,J5s,44,JTs,96s,QJs,TT,A3o,A4s,A8o,64o,74s
Sample,15,CALL,MP2,MP1,TT,77,AQo,99,A9s,JTs,KTs,QTs,ATs,88,T9s,KQo,AJo,QJs,KJs
Sample,15,CALL,MP3,MP1,TT,AQo,A9s,66,KTs,QTs,98s,T9s,88,AJo,KQo,KJs,QJs,77,99,JTs,ATs
Sample,60,CALL,MP2,MP1,TT,Q9s,AQo,A7s,J9s,A6s,A9s,KJo,A8s,KTs,66,QTs,98s,T9s,88,AJo,KQo,KJs,QJs,77,99,ATo,K9s,JTs,55,A5s,ATs
Sample,40,CALL,MP2,MP1,TT,Q9s,AQo,A7s,J9s,A6s,A9s,KJo,A8s,KTs,66,QTs,98s,T9s,88,AJo,KQo,KJs,QJs,77,99,ATo,K9s,JTs,55,A5s,ATs
Sample,25,CALL,MP2,MP1,TT,AQo,A9s,KTs,QTs,T9s,88,AJo,KQo,KJs,QJs,77,99,JTs,ATs
Sample,25,CALL,MP3,MP1,Q9s,66,KTs,88,T9s,98s,KQo,AJo,KJs,QJs,77,99,TT,AQo,J9s,A9s,QTs,K9s,JTs,55,ATs
Sample,60,CALL,MP3,MP1,Q9s,76s,44,A2s,KTs,66,88,T9s,98s,A3s,KQo,AJo,KJs,QJs,A4s,77,99,97s,A5s,T8s,TT,AQo,J9s,A7s,A6s,A9s,KJo,A8s,QTs,ATo,K9s,JTs,55,ATs,87s
Sample,40,CALL,MP3,MP1,Q9s,76s,44,A2s,KTs,66,88,T9s,98s,A3s,KQo,AJo,KJs,QJs,A4s,77,99,97s,A5s,T8s,TT,AQo,J9s,A7s,A6s,A9s,KJo,A8s,QTs,ATo,K9s,JTs,55,ATs,87s
Sample,25,CALL,CO,MP1,Q9s,44,A2s,KTs,66,88,T9s,98s,A3s,KQo,AJo,KJs,QJs,A4s,77,99,A5s,TT,AQo,J9s,A7s,A6s,A9s,A8s,QTs,K9s,JTs,55,ATs
Sample,60,CALL,CO,MP1,Q9s,76s,44,A2s,KTs,66,88,T9s,98s,A3s,KQo,AJo,KJs,QJs,A4s,77,99,97s,A5s,T8s,TT,AQo,J9s,A7s,A6s,A9s,KJo,A8s,QTs,ATo,K9s,JTs,55,ATs,87s
Sample,40,CALL,CO,MP1,Q9s,76s,44,A2s,KTs,66,88,T9s,98s,A3s,KQo,AJo,KJs,QJs,A4s,77,99,97s,A5s,T8s,TT,AQo,J9s,A7s,A6s,A9s,KJo,A8s,QTs,ATo,K9s,JTs,55,ATs,87s
Sample,15,CALL,CO,MP1,TT,Q9s,AQo,A7s,J9s,A6s,44,A9s,A8s,A2s,66,KTs,QTs,88,T9s,98s,A3s,KQo,AJo,QJs,KJs,77,A4s,99,K9s,JTs,55,A5s,ATs
Sample,15,CALL,BTN,MP1,Q9s,44,A2s,66,KTs,T9s,88,AJo,A3s,KQo,QJs,KJs,A4s,77,99,A5s,TT,AQo,J9s,A7s,A6s,A9s,KJo,A8s,QTs,33,22,ATo,K9s,JTs,55,ATs,QJo
Sample,60,CALL,BTN,MP1,Q9s,76s,44,A2s,66,KTs,JTo,88,T9s,98s,KQo,A3s,AJo,KJs,QJs,A4s,77,99,QTo,86s,97s,T8s,A5s,Q8s,TT,AQo,J9s,A7s,A6s,A9s,KJo,J8s,A8s,QTs,33,22,A9o,K8s,ATo,K9s,KTo,JTs,55,QJo,ATs,87s
Sample,40,CALL,BTN,MP1,Q9s,76s,44,A2s,66,KTs,JTo,88,T9s,98s,KQo,A3s,AJo,KJs,QJs,A4s,77,99,QTo,86s,97s,T8s,A5s,Q8s,TT,AQo,J9s,A7s,A6s,A9s,KJo,J8s,A8s,QTs,33,22,A9o,K8s,ATo,K9s,KTo,JTs,55,QJo,ATs,87s
Sample,25,CALL,BTN,MP1,Q9s,44,A2s,66,KTs,88,T9s,KQo,A3s,AJo,KJs,QJs,A4s,77,99,A5s,TT,AQo,J9s,A7s,A6s,A9s,KJo,A8s,QTs,33,22,ATo,K9s,JTs,55,QJo,ATs
Sample,15,CALL,SB,MP1,TT,AQo,99,JTs,KTs,QTs,ATs,AJs,88,JJ,KJs,QJs
Sample,60,CALL,SB,MP1,TT,Q9s,AQo,J9s,A9s,A8s,66,KTs,QTs,AJs,88,T9s,KQo,AJo,QJs,KJs,77,99,K9s,JTs,ATs,JJ
Sample,40,CALL,SB,MP1,TT,Q9s,AQo,J9s,A9s,A8s,66,KTs,QTs,AJs,88,T9s,KQo,AJo,QJs,KJs,77,99,K9s,JTs,ATs,JJ
Sample,25,CALL,SB,MP1,TT,AQo,KTs,QTs,AJs,88,QJs,KJs,99,JTs,ATs,JJ
Sample,15,CALL,BB,MP1,Q9s,76s,A2s,66,76o,T9s,AJo,A3s,QJs,K5s,77,99,K9o,K6s,QTo,T8s,A5s,A3o,Q8s,Q8o,T8o,J9s,A7s,A6s,QTs,98o,J9o,97o,A5o,ATo,K8s,K9s,T9o,55,A4o,K7s,75o,K3s,87s,A2o,44,Q7s,87o,KTs,JTo,AJs,98s,88,65o,KQo,KJs,64o,A4s,A6o,J8o,K4s,T7s,A7o,97s,TT,65s,AQo,A9s,KJo,J8s,A8s,86o,K8o,33,J7s,22,A9o,Q9o,KTo,JTs,54o,A8o,K2s,QJo,ATs
Sample,60,CALL,BB,MP1,Q9s,96s,76s,Q6s,A2s,66,76o,T9s,64s,AJo,A3s,QJs,J5s,K5s,77,99,QTo,K6s,K9o,A5s,T8s,A3o,43s,Q6o,Q8s,Q8o,T8o,A7s,J9s,A6s,J6s,QTs,98o,J9o,A5o,97o,ATo,K8s,K9s,T9o,55,A4o,K7s,75s,75o,K3s,87s,A2o,44,Q7s,87o,KTs,JTo,AJs,98s,88,65o,KQo,KJs,64o,Q5s,A4s,A6o,Q7o,J8o,K4s,86s,T7s,A7o,97s,74s,53s,TT,65s,AQo,K6o,A9s,KJo,J8s,A8s,J7o,86o,T6s,K8o,33,J7s,22,A9o,Q9o,K5o,KTo,JTs,A8o,54o,K2s,54s,ATs,QJo,K7o
Sample,40,CALL,BB,MP1,Q9s,96s,76s,Q6s,A2s,66,76o,T9s,64s,AJo,A3s,QJs,J5s,K5s,77,99,QTo,K6s,K9o,A5s,T8s,A3o,43s,Q6o,Q8s,Q8o,T8o,A7s,J9s,A6s,J6s,QTs,98o,J9o,A5o,97o,ATo,K8s,K9s,T9o,55,A4o,K7s,75s,75o,K3s,87s,A2o,44,Q7s,87o,KTs,JTo,AJs,98s,88,65o,KQo,KJs,64o,Q5s,A4s,A6o,Q7o,J8o,K4s,86s,T7s,A7o,97s,74s,53s,TT,65s,AQo,K6o,A9s,KJo,J8s,A8s,J7o,86o,T6s,K8o,33,J7s,22,A9o,Q9o,K5o,KTo,JTs,A8o,54o,K2s,54s,ATs,QJo,K7o
Sample,25,CALL,BB,MP1,Q9s,66,76o,T9s,AJo,QJs,T8s,Q8o,T8o,J9s,97o,A5o,A4o,K7s,87s,A2o,44,87o,Q7s,AJs,KJs,J8o,97s,AQo,KJo,J7s,A9o,KTo,54o,A8o,K2s,QJo,ATs,76s,A2s,A3s,K5s,77,99,K9o,K6s,QTo,A5s,A3o,Q8s,A7s,A6s,98o,QTs,J9o,K8s,ATo,K9s,55,T9o,K3s,75o,KTs,JTo,88,98s,65o,KQo,64o,A4s,A6o,K4s,A7o,T7s,65s,TT,A9s,A8s,J8s,86o,K8o,33,22,Q9o,JTs
Sample,15,CALL,MP3,MP2,TT,AQo,A9s,66,KTs,QTs,98s,T9s,88,AJo,KQo,KJs,QJs,77,99,JTs,ATs
Sample,100,CALL,MP3,MP2,TT,Q9s,AQo,A7s,J9s,A6s,A9s,KJo,A8s,KTs,66,QTs,98s,T9s,88,AJo,KQo,KJs,QJs,77,99,ATo,K9s,JTs,55,A5s,ATs
Sample,100,CALL,CO,MP2,Q9s,76s,44,A2s,KTs,66,88,T9s,98s,A3s,KQo,AJo,KJs,QJs,A4s,77,99,97s,A5s,T8s,TT,AQo,J9s,A7s,A6s,A9s,KJo,A8s,QTs,ATo,K9s,JTs,55,ATs,87s
Sample,100,CALL,BTN,MP2,Q9s,76s,44,A2s,66,KTs,JTo,88,T9s,98s,KQo,A3s,AJo,KJs,QJs,A4s,77,99,QTo,86s,97s,T8s,A5s,Q8s,TT,AQo,J9s,A7s,A6s,A9s,KJo,J8s,A8s,QTs,33,22,A9o,K8s,ATo,K9s,KTo,JTs,55,QJo,ATs,87s
Sample,100,CALL,SB,MP2,TT,Q9s,AQo,J9s,A9s,A8s,66,KTs,QTs,AJs,88,T9s,KQo,AJo,QJs,KJs,77,99,K9s,JTs,ATs,JJ
Sample,100,CALL,BB,MP2,Q9s,96s,76s,Q6s,A2s,66,76o,T9s,64s,AJo,A3s,QJs,J5s,K5s,77,99,QTo,K6s,K9o,A5s,T8s,A3o,43s,Q6o,Q8s,Q8o,T8o,A7s,J9s,A6s,J6s,QTs,98o,J9o,A5o,97o,ATo,K8s,K9s,T9o,55,A4o,K7s,75s,75o,K3s,87s,A2o,44,Q7s,87o,KTs,JTo,AJs,98s,88,65o,KQo,KJs,64o,Q5s,A4s,A6o,Q7o,J8o,K4s,86s,T7s,A7o,97s,74s,53s,TT,65s,AQo,K6o,A9s,KJo,J8s,A8s,J7o,86o,T6s,K8o,33,J7s,22,A9o,Q9o,K5o,KTo,JTs,A8o,54o,K2s,54s,ATs,QJo,K7o
Sample,15,CALL,BB,MP2,Q9s,76s,A2s,66,76o,T9s,AJo,A3s,QJs,K5s,77,99,K9o,K6s,QTo,T8s,A5s,A3o,Q8s,Q8o,T8o,J9s,A7s,A6s,QTs,98o,J9o,97o,A5o,ATo,K8s,K9s,T9o,55,A4o,K7s,75o,K3s,87s,A2o,44,Q7s,87o,KTs,JTo,AJs,98s,88,65o,KQo,KJs,64o,A4s,A6o,J8o,K4s,T7s,A7o,97s,TT,65s,AQo,A9s,KJo,J8s,A8s,86o,K8o,33,J7s,22,A9o,Q9o,KTo,JTs,54o,A8o,K2s,QJo,ATs
Sample,15,CALL,CO,MP2,TT,Q9s,AQo,A7s,J9s,A6s,44,A9s,A8s,A2s,66,KTs,QTs,88,T9s,98s,A3s,KQo,AJo,QJs,KJs,77,A4s,99,K9s,JTs,55,A5s,ATs
Sample,15,CALL,CO,MP3,Q9s,44,A2s,KTs,66,88,T9s,A3s,KQo,AJo,KJs,QJs,A4s,77,99,A5s,TT,AQo,J9s,A7s,A6s,A9s,A8s,QTs,33,22,K9s,JTs,55,ATs
Sample,15,CALL,BTN,MP3,Q9s,44,A2s,66,KTs,T9s,88,AJo,A3s,KQo,QJs,KJs,A4s,77,99,A5s,TT,AQo,J9s,A7s,A6s,A9s,KJo,A8s,QTs,33,22,ATo,K9s,JTs,55,ATs,QJo
Sample,15,CALL,BTN,MP2,Q9s,44,A2s,66,KTs,T9s,88,AJo,A3s,KQo,QJs,KJs,A4s,77,99,A5s,TT,AQo,J9s,A7s,A6s,A9s,KJo,A8s,QTs,33,22,ATo,K9s,JTs,55,ATs,QJo
Sample,15,CALL,SB,MP2,TT,AQo,99,JTs,KTs,QTs,ATs,AJs,88,JJ,KJs,QJs
Sample,25,CALL,BB,MP2,Q9s,76s,A2s,66,76o,T9s,AJo,A3s,QJs,K5s,77,99,K9o,K6s,QTo,T8s,A5s,A3o,Q8s,Q8o,T8o,J9s,A7s,A6s,QTs,98o,J9o,97o,A5o,ATo,K8s,K9s,T9o,55,A4o,K7s,75o,K3s,87s,A2o,44,Q7s,87o,KTs,JTo,AJs,98s,88,65o,KQo,KJs,64o,A4s,A6o,J8o,K4s,T7s,A7o,97s,TT,65s,AQo,A9s,KJo,J8s,A8s,86o,K8o,33,J7s,22,A9o,Q9o,KTo,JTs,54o,A8o,K2s,QJo,ATs
Sample,60,CALL,BB,MP2,Q9s,96s,76s,Q6s,A2s,66,76o,T9s,64s,AJo,A3s,QJs,J5s,K5s,77,99,K9o,K6s,QTo,A5s,T8s,A3o,43s,Q6o,Q8s,Q8o,T8o,A7s,J9s,A6s,J6s,QTs,98o,J9o,A5o,97o,ATo,K8s,K9s,T9o,55,A4o,K7s,75s,75o,K3s,87s,A2o,44,87o,Q7s,KTs,JTo,AJs,88,98s,65o,KQo,KJs,64o,Q5s,A4s,Q7o,A6o,J8o,86s,K4s,A7o,T7s,97s,74s,53s,65s,TT,AQo,K6o,A9s,KJo,A8s,J8s,J7o,T6s,86o,K8o,33,J7s,22,A9o,Q9o,K5o,KTo,JTs,A8o,54o,K2s,54s,QJo,ATs,K7o
Sample,40,CALL,BB,MP2,Q9s,96s,76s,Q6s,A2s,66,76o,T9s,64s,AJo,A3s,QJs,J5s,K5s,77,99,K9o,K6s,QTo,A5s,T8s,A3o,43s,Q6o,Q8s,Q8o,T8o,A7s,J9s,A6s,J6s,QTs,98o,J9o,A5o,97o,ATo,K8s,K9s,T9o,55,A4o,K7s,75s,75o,K3s,87s,A2o,44,87o,Q7s,KTs,JTo,AJs,88,98s,65o,KQo,KJs,64o,Q5s,A4s,Q7o,A6o,J8o,86s,K4s,A7o,T7s,97s,74s,53s,65s,TT,AQo,K6o,A9s,KJo,A8s,J8s,J7o,T6s,86o,K8o,33,J7s,22,A9o,Q9o,K5o,KTo,JTs,A8o,54o,K2s,54s,QJo,ATs,K7o
Sample,25,CALL,SB,MP2,TT,AQo,99,JTs,KTs,QTs,ATs,AJs,88,JJ,KJs,QJs
Sample,60,CALL,SB,MP2,TT,Q9s,AQo,J9s,A9s,A8s,66,KTs,QTs,AJs,88,T9s,KQo,AJo,QJs,KJs,77,99,K9s,JTs,ATs,JJ
Sample,40,CALL,SB,MP2,TT,Q9s,AQo,J9s,A9s,A8s,66,KTs,QTs,AJs,88,T9s,KQo,AJo,QJs,KJs,77,99,K9s,JTs,ATs,JJ
Sample,25,CALL,BTN,MP2,Q9s,44,A2s,KTs,66,T9s,88,AJo,A3s,KQo,QJs,KJs,77,A4s,99,A5s,TT,AQo,A7s,J9s,A6s,A9s,KJo,A8s,QTs,33,22,ATo,K9s,JTs,55,ATs,QJo
Sample,60,CALL,BTN,MP2,Q9s,76s,44,A2s,66,KTs,JTo,88,T9s,98s,KQo,A3s,AJo,KJs,QJs,A4s,77,99,QTo,86s,97s,T8s,A5s,Q8s,TT,AQo,J9s,A7s,A6s,A9s,KJo,J8s,A8s,QTs,33,22,A9o,K8s,ATo,K9s,KTo,JTs,55,QJo,ATs,87s
Sample,40,CALL,BTN,MP2,Q9s,76s,44,A2s,66,KTs,JTo,88,T9s,98s,KQo,A3s,AJo,KJs,QJs,A4s,77,99,QTo,86s,97s,T8s,A5s,Q8s,TT,AQo,J9s,A7s,A6s,A9s,KJo,J8s,A8s,QTs,33,22,A9o,K8s,ATo,K9s,KTo,JTs,55,QJo,ATs,87s
Sample,25,CALL,CO,MP2,TT,Q9s,AQo,A7s,J9s,A6s,44,A9s,A8s,A2s,66,KTs,QTs,88,T9s,98s,A3s,KQo,AJo,QJs,KJs,77,A4s,99,K9s,JTs,55,A5s,ATs
Sample,40,CALL,CO,MP2,Q9s,76s,44,A2s,KTs,66,88,T9s,98s,A3s,KQo,AJo,KJs,QJs,A4s,77,99,97s,A5s,T8s,TT,AQo,J9s,A7s,A6s,A9s,KJo,A8s,QTs,ATo,K9s,JTs,55,ATs,87s
Sample,60,CALL,CO,MP2,Q9s,76s,44,A2s,KTs,66,88,T9s,98s,A3s,KQo,AJo,KJs,QJs,A4s,77,99,97s,A5s,T8s,TT,AQo,J9s,A7s,A6s,A9s,KJo,A8s,QTs,ATo,K9s,JTs,55,ATs,87s
Sample,25,CALL,CO,MP3,Q9s,44,A2s,KTs,66,T9s,88,AJo,KQo,A3s,QJs,KJs,A4s,77,99,A5s,TT,AQo,J9s,A7s,A6s,A9s,A8s,QTs,33,22,K9s,JTs,55,ATs
Sample,25,CALL,MP3,MP2,TT,AQo,A9s,66,KTs,QTs,98s,T9s,88,AJo,KQo,KJs,QJs,77,99,JTs,ATs
Sample,100,CALL,CO,MP3,Q9s,76s,44,A2s,KTs,66,98s,T9s,88,AJo,KQo,A3s,KJs,QJs,A4s,77,99,97s,A5s,T8s,Q8s,TT,AQo,A7s,J9s,A6s,A9s,KJo,A8s,J8s,QTs,33,22,ATo,K8s,K9s,JTs,55,ATs,87s
Sample,100,CALL,BTN,MP3,Q9s,76s,44,A2s,66,KTs,JTo,88,T9s,98s,KQo,A3s,AJo,KJs,QJs,A4s,77,99,QTo,86s,97s,T8s,A5s,43s,Q8s,65s,TT,AQo,J9s,A7s,A6s,A9s,KJo,J8s,A8s,QTs,33,22,A9o,K8s,ATo,K9s,KTo,JTs,55,54s,QJo,ATs,75s,87s
Sample,60,CALL,BTN,MP3,Q9s,76s,44,A2s,66,KTs,JTo,98s,T9s,88,A3s,KQo,AJo,KJs,QJs,A4s,77,99,QTo,86s,97s,T8s,A5s,43s,Q8s,TT,65s,AQo,A7s,J9s,A6s,A9s,KJo,A8s,J8s,QTs,33,A9o,22,ATo,K8s,K9s,KTo,JTs,55,54s,ATs,QJo,75s,87s
Sample,40,CALL,BTN,MP3,Q9s,76s,44,A2s,66,KTs,JTo,98s,T9s,88,A3s,KQo,AJo,KJs,QJs,A4s,77,99,QTo,86s,97s,T8s,A5s,43s,Q8s,TT,65s,AQo,A7s,J9s,A6s,A9s,KJo,A8s,J8s,QTs,33,A9o,22,ATo,K8s,K9s,KTo,JTs,55,54s,ATs,QJo,75s,87s
Sample,100,CALL,SB,MP3,TT,Q9s,AQo,J9s,A9s,A8s,66,KTs,QTs,AJs,88,T9s,KQo,AJo,QJs,KJs,77,99,K9s,JTs,ATs,JJ
Sample,100,CALL,BB,MP3,Q9s,96s,76s,Q6s,A2s,66,76o,T9s,64s,AJo,A3s,QJs,J5s,K5s,77,99,K9o,K6s,QTo,A5s,T8s,A3o,43s,Q6o,Q8s,Q8o,T8o,A7s,J9s,A6s,J6s,QTs,98o,J9o,A5o,97o,ATo,K8s,K9s,T9o,55,A4o,K7s,75s,75o,K3s,87s,A2o,44,87o,Q7s,KTs,JTo,AJs,88,98s,65o,KQo,KJs,64o,Q5s,A4s,Q7o,A6o,J8o,86s,K4s,A7o,T7s,97s,74s,53s,65s,TT,AQo,K6o,A9s,KJo,A8s,J8s,J7o,T6s,86o,K8o,33,J7s,22,A9o,Q9o,K5o,KTo,JTs,A8o,54o,K2s,54s,QJo,ATs,K7o
Sample,60,CALL,BB,MP3,Q9s,66,76o,64s,T9s,AJo,QJs,J5s,T8s,43s,Q8o,T8o,J9s,97o,A5o,A4o,75s,K7s,87s,A2o,44,87o,Q7s,AJs,KJs,Q5s,J8o,97s,AQo,KJo,J7s,A9o,K5o,KTo,A8o,54o,K2s,54s,ATs,QJo,96s,76s,Q6s,A2s,A3s,K5s,77,99,K9o,K6s,QTo,A5s,A3o,Q6o,Q8s,A7s,A6s,J6s,98o,QTs,J9o,K8s,ATo,K9s,55,T9o,K3s,75o,KTs,JTo,88,98s,65o,KQo,64o,A4s,Q7o,A6o,86s,K4s,A7o,T7s,74s,53s,65s,TT,K6o,A9s,A8s,J8s,J7o,T6s,86o,K8o,33,22,Q9o,JTs,K7o
Sample,40,CALL,BB,MP3,Q9s,66,76o,64s,T9s,AJo,QJs,J5s,T8s,43s,Q8o,T8o,J9s,97o,A5o,A4o,75s,K7s,87s,A2o,44,87o,Q7s,AJs,KJs,Q5s,J8o,97s,AQo,KJo,J7s,A9o,K5o,KTo,A8o,54o,K2s,54s,ATs,QJo,96s,76s,Q6s,A2s,A3s,K5s,77,99,K9o,K6s,QTo,A5s,A3o,Q6o,Q8s,A7s,A6s,J6s,98o,QTs,J9o,K8s,ATo,K9s,55,T9o,K3s,75o,KTs,JTo,88,98s,65o,KQo,64o,A4s,Q7o,A6o,86s,K4s,A7o,T7s,74s,53s,65s,TT,K6o,A9s,A8s,J8s,J7o,T6s,86o,K8o,33,22,Q9o,JTs,K7o
Sample,15,CALL,BB,MP3,Q9s,76s,A2s,66,76o,T9s,AJo,A3s,QJs,K5s,77,99,K9o,K6s,QTo,T8s,A5s,A3o,Q8s,Q8o,T8o,J9s,A7s,A6s,QTs,98o,J9o,97o,A5o,ATo,K8s,K9s,T9o,55,A4o,K7s,75o,K3s,87s,A2o,44,Q7s,87o,KTs,JTo,AJs,98s,88,65o,KQo,KJs,64o,A4s,A6o,J8o,K4s,T7s,A7o,97s,TT,65s,AQo,A9s,KJo,J8s,A8s,86o,K8o,33,J7s,22,A9o,Q9o,KTo,JTs,54o,A8o,K2s,QJo,ATs
Sample,25,CALL,BB,MP3,Q9s,76s,A2s,66,76o,T9s,AJo,A3s,QJs,K5s,77,99,K9o,K6s,QTo,T8s,A5s,A3o,Q8s,Q8o,T8o,J9s,A7s,A6s,QTs,98o,J9o,97o,A5o,ATo,K8s,K9s,T9o,55,A4o,K7s,75o,K3s,87s,A2o,44,Q7s,87o,KTs,JTo,AJs,98s,88,65o,KQo,KJs,64o,A4s,A6o,J8o,K4s,T7s,A7o,97s,TT,65s,AQo,A9s,KJo,J8s,A8s,86o,K8o,33,J7s,22,A9o,Q9o,KTo,JTs,54o,A8o,K2s,QJo,ATs
Sample,40,CALL,CO,MP3,Q9s,76s,44,A2s,KTs,66,88,T9s,98s,A3s,KQo,AJo,KJs,QJs,A4s,77,99,97s,A5s,T8s,Q8s,TT,AQo,J9s,A7s,A6s,A9s,KJo,J8s,A8s,QTs,33,22,K8s,ATo,K9s,JTs,55,ATs,87s
Sample,60,CALL,CO,MP3,Q9s,76s,44,A2s,KTs,66,88,T9s,98s,A3s,KQo,AJo,KJs,QJs,A4s,77,99,97s,A5s,T8s,Q8s,TT,AQo,J9s,A7s,A6s,A9s,KJo,J8s,A8s,QTs,33,22,K8s,ATo,K9s,JTs,55,ATs,87s
Sample,25,CALL,BTN,MP3,Q9s,44,A2s,66,KTs,T9s,88,AJo,A3s,KQo,QJs,KJs,A4s,77,99,A5s,TT,AQo,J9s,A7s,A6s,A9s,KJo,A8s,QTs,33,22,ATo,K9s,JTs,55,ATs,QJo
Sample,25,CALL,SB,MP3,TT,AQo,99,JTs,KTs,QTs,ATs,AJs,88,JJ,KJs,QJs
Sample,60,CALL,SB,MP3,TT,Q9s,AQo,J9s,A9s,A8s,66,KTs,QTs,AJs,88,T9s,KQo,AJo,QJs,KJs,77,99,K9s,JTs,ATs,JJ
Sample,40,CALL,SB,MP3,TT,Q9s,AQo,J9s,A9s,A8s,66,KTs,QTs,AJs,88,T9s,KQo,AJo,QJs,KJs,77,99,K9s,JTs,ATs,JJ
Sample,15,CALL,SB,MP3,TT,AQo,99,JTs,KTs,QTs,ATs,AJs,88,JJ,KJs,QJs
Sample,100,CALL,BTN,CO,Q9s,76s,44,A2s,66,KTs,JTo,88,T9s,98s,KQo,A3s,AJo,KJs,QJs,A4s,77,99,QTo,86s,97s,T8s,A5s,43s,Q8s,65s,TT,AQo,J9s,A7s,A6s,A9s,KJo,J8s,A8s,QTs,33,22,A9o,K8s,ATo,K9s,KTo,JTs,55,54s,QJo,ATs,75s,87s
Sample,60,CALL,BTN,CO,Q9s,76s,44,A2s,66,KTs,JTo,88,T9s,98s,KQo,A3s,AJo,KJs,QJs,A4s,77,99,QTo,86s,97s,T8s,A5s,43s,Q8s,65s,TT,AQo,J9s,A7s,A6s,A9s,KJo,J8s,A8s,QTs,33,22,A9o,K8s,ATo,K9s,KTo,JTs,55,54s,QJo,ATs,75s,87s
Sample,40,CALL,BTN,CO,Q9s,76s,44,A2s,66,KTs,JTo,88,T9s,98s,KQo,A3s,AJo,KJs,QJs,A4s,77,99,QTo,86s,97s,T8s,A5s,43s,Q8s,65s,TT,AQo,J9s,A7s,A6s,A9s,KJo,J8s,A8s,QTs,33,22,A9o,K8s,ATo,K9s,KTo,JTs,55,54s,QJo,ATs,75s,87s
Sample,25,CALL,BTN,CO,Q9s,76s,44,A2s,66,KTs,JTo,88,T9s,98s,KQo,A3s,AJo,KJs,QJs,A4s,77,99,QTo,86s,97s,T8s,A5s,43s,Q8s,65s,TT,AQo,J9s,A7s,A6s,A9s,KJo,J8s,A8s,QTs,33,22,A9o,K8s,ATo,K9s,KTo,JTs,55,54s,QJo,ATs,75s,87s
Sample,15,CALL,BTN,CO,Q9s,76s,44,A2s,66,KTs,JTo,88,T9s,98s,KQo,A3s,AJo,KJs,QJs,A4s,77,99,QTo,86s,97s,T8s,A5s,43s,Q8s,65s,TT,AQo,J9s,A7s,A6s,A9s,KJo,J8s,A8s,QTs,33,22,A9o,K8s,ATo,K9s,KTo,JTs,55,54s,QJo,ATs,75s,87s
Sample,15,CALL,SB,CO,Q9s,TT,AQo,J9s,A9s,A8s,66,KTs,QTs,AJs,T9s,88,KQo,AJo,QJs,KJs,77,99,K9s,JTs,ATs,JJ
Sample,100,CALL,SB,CO,TT,Q9s,AQo,J9s,44,A9s,A8s,J8s,66,KTs,QTs,AJs,88,T9s,98s,AJo,KQo,KJs,QJs,77,99,K8s,K9s,JTs,55,T8s,ATs,JJ,Q8s
Sample,60,CALL,SB,CO,TT,Q9s,AQo,J9s,44,A9s,A8s,J8s,66,KTs,QTs,AJs,88,T9s,98s,AJo,KQo,KJs,QJs,77,99,K8s,K9s,JTs,55,T8s,ATs,JJ,Q8s
Sample,40,CALL,SB,CO,Q9s,44,KTs,66,AJs,98s,T9s,88,KQo,AJo,QJs,KJs,77,99,T8s,Q8s,TT,AQo,J9s,A9s,J8s,A8s,QTs,K8s,K9s,JTs,55,ATs,JJ
Sample,25,CALL,SB,CO,Q9s,TT,AQo,J9s,A9s,A8s,66,KTs,QTs,AJs,T9s,88,KQo,AJo,QJs,KJs,77,99,K9s,JTs,ATs,JJ
Sample,100,CALL,BB,CO,Q9s,K3o,66,76o,64s,T9s,AJo,QJs,J5s,95s,T8s,43s,Q8o,T8o,J9s,T7o,97o,A5o,A4o,T5s,75s,K7s,87s,A2o,44,87o,Q7s,AJs,KJs,Q5s,J8o,97s,AQo,KJo,J7s,A9o,J6o,KTo,K5o,54o,A8o,54s,K2s,QJo,ATs,96s,76s,A2s,Q6s,A3s,K5s,77,99,K9o,K6s,QTo,A5s,A3o,Q6o,Q8s,A7s,A6s,J6s,98o,QTs,J9o,85s,K8s,ATo,K9s,55,T9o,96o,T6o,K3s,75o,Q5o,KTs,JTo,88,98s,65o,KQo,64o,A4s,Q7o,A6o,86s,K4s,A7o,T7s,74s,53s,65s,TT,K6o,A9s,A8s,J8s,J7o,86o,T6s,K8o,33,22,Q9o,JTs,K4o,K7o
Sample,60,CALL,BB,CO,Q9s,K3o,66,76o,64s,T9s,AJo,QJs,J5s,95s,T8s,43s,Q8o,T8o,J9s,T7o,97o,A5o,A4o,T5s,75s,K7s,87s,A2o,44,Q7s,87o,AJs,KJs,Q5s,J8o,97s,AQo,KJo,J7s,A9o,J6o,K5o,KTo,A8o,54o,K2s,54s,ATs,QJo,96s,76s,A2s,Q6s,A3s,K5s,77,99,K9o,K6s,QTo,A5s,A3o,Q6o,Q8s,A7s,A6s,J6s,98o,QTs,J9o,85s,K8s,ATo,K9s,55,T9o,96o,T6o,K3s,75o,Q5o,KTs,JTo,88,98s,65o,KQo,64o,A4s,Q7o,A6o,86s,K4s,A7o,T7s,74s,53s,65s,TT,K6o,A9s,J8s,A8s,J7o,T6s,86o,K8o,33,22,Q9o,JTs,K4o,K7o
Sample,40,CALL,BB,CO,Q9s,K3o,66,76o,64s,T9s,AJo,QJs,J5s,95s,T8s,43s,Q8o,T8o,J9s,T7o,97o,A5o,A4o,T5s,75s,K7s,87s,A2o,44,Q7s,87o,AJs,KJs,Q5s,J8o,97s,AQo,KJo,J7s,A9o,J6o,K5o,KTo,A8o,54o,K2s,54s,ATs,QJo,96s,76s,A2s,Q6s,A3s,K5s,77,99,K9o,K6s,QTo,A5s,A3o,Q6o,Q8s,A7s,A6s,J6s,98o,QTs,J9o,85s,K8s,ATo,K9s,55,T9o,96o,T6o,K3s,75o,Q5o,KTs,JTo,88,98s,65o,KQo,64o,A4s,Q7o,A6o,86s,K4s,A7o,T7s,74s,53s,65s,TT,K6o,A9s,J8s,A8s,J7o,T6s,86o,K8o,33,22,Q9o,JTs,K4o,K7o
Sample,25,CALL,BB,CO,Q9s,K3o,66,76o,64s,T9s,AJo,QJs,J5s,95s,T8s,43s,Q8o,T8o,J9s,T7o,97o,A5o,A4o,T5s,75s,K7s,87s,A2o,44,Q7s,87o,AJs,KJs,Q5s,J8o,97s,AQo,KJo,J7s,A9o,J6o,K5o,KTo,A8o,54o,K2s,54s,ATs,QJo,96s,76s,A2s,Q6s,A3s,K5s,77,99,K9o,K6s,QTo,A5s,A3o,Q6o,Q8s,A7s,A6s,J6s,98o,QTs,J9o,85s,K8s,ATo,K9s,55,T9o,96o,T6o,K3s,75o,Q5o,KTs,JTo,88,98s,65o,KQo,64o,A4s,Q7o,A6o,86s,K4s,A7o,T7s,74s,53s,65s,TT,K6o,A9s,J8s,A8s,J7o,T6s,86o,K8o,33,22,Q9o,JTs,K4o,K7o
Sample,15,CALL,BB,CO,Q9s,K3o,66,76o,64s,T9s,AJo,QJs,J5s,95s,T8s,43s,Q8o,T8o,J9s,T7o,97o,A5o,A4o,T5s,75s,K7s,87s,A2o,44,Q7s,87o,AJs,KJs,Q5s,J8o,97s,AQo,KJo,J7s,A9o,J6o,K5o,KTo,A8o,54o,K2s,54s,ATs,QJo,96s,76s,A2s,Q6s,A3s,K5s,77,99,K9o,K6s,QTo,A5s,A3o,Q6o,Q8s,A7s,A6s,J6s,98o,QTs,J9o,85s,K8s,ATo,K9s,55,T9o,96o,T6o,K3s,75o,Q5o,KTs,JTo,88,98s,65o,KQo,64o,A4s,Q7o,A6o,86s,K4s,A7o,T7s,74s,53s,65s,TT,K6o,A9s,J8s,A8s,J7o,T6s,86o,K8o,33,22,Q9o,JTs,K4o,K7o
Sample,15,CALL,SB,BTN,Q9s,44,A2s,KTs,66,AJs,88,T9s,98s,AJo,KQo,A3s,QJs,KJs,A4s,77,99,A5s,T8s,Q8s,TT,AQo,A7s,J9s,A6s,A9s,KJo,A8s,J8s,QTs,33,K8s,ATo,K9s,JTs,55,ATs,JJ
Sample,15,CALL,BB,BTN,Q9s,K3o,66,76o,64s,T9s,AJo,QJs,J5s,95s,T8s,43s,Q8o,T8o,J9s,T7o,97o,A5o,A4o,T5s,75s,K7s,87s,A2o,44,Q7s,87o,AJs,KJs,Q5s,J8o,97s,AQo,KJo,J7s,A9o,J6o,K5o,KTo,A8o,54o,K2s,54s,ATs,QJo,96s,76s,A2s,Q6s,A3s,K5s,77,99,K9o,K6s,QTo,A5s,A3o,Q6o,Q8s,A7s,A6s,J6s,98o,QTs,J9o,85s,K8s,ATo,K9s,55,T9o,96o,T6o,K3s,75o,Q5o,KTs,JTo,88,98s,65o,KQo,64o,A4s,Q7o,A6o,86s,K4s,A7o,T7s,74s,53s,65s,TT,K6o,A9s,J8s,A8s,J7o,T6s,86o,K8o,33,22,Q9o,JTs,K4o,K7o
Sample,25,CALL,BB,BTN,Q9s,K3o,66,76o,64s,T9s,AJo,QJs,J5s,95s,T8s,43s,Q8o,T8o,J9s,T7o,97o,A5o,A4o,T5s,75s,K7s,87s,A2o,44,Q7s,87o,AJs,KJs,Q5s,J8o,97s,AQo,KJo,J7s,A9o,J6o,K5o,KTo,A8o,54o,K2s,54s,ATs,QJo,96s,76s,A2s,Q6s,A3s,K5s,77,99,K9o,K6s,QTo,A5s,A3o,Q6o,Q8s,A7s,A6s,J6s,98o,QTs,J9o,85s,K8s,ATo,K9s,55,T9o,96o,T6o,K3s,75o,Q5o,KTs,JTo,88,98s,65o,KQo,64o,A4s,Q7o,A6o,86s,K4s,A7o,T7s,74s,53s,65s,TT,K6o,A9s,J8s,A8s,J7o,T6s,86o,K8o,33,22,Q9o,JTs,K4o,K7o
Sample,40,CALL,BB,BTN,Q9s,K3o,66,76o,64s,T9s,AJo,QJs,J5s,95s,T8s,43s,Q8o,T8o,J9s,T7o,97o,A5o,A4o,T5s,75s,K7s,87s,A2o,44,Q7s,87o,AJs,KJs,Q5s,J8o,97s,AQo,KJo,J7s,A9o,J6o,K5o,KTo,A8o,54o,K2s,54s,ATs,QJo,96s,76s,A2s,Q6s,A3s,K5s,77,99,K9o,K6s,QTo,A5s,A3o,Q6o,Q8s,A7s,A6s,J6s,98o,QTs,J9o,85s,K8s,ATo,K9s,55,T9o,96o,T6o,K3s,75o,Q5o,KTs,JTo,88,98s,65o,KQo,64o,A4s,Q7o,A6o,86s,K4s,A7o,T7s,74s,53s,65s,TT,K6o,A9s,J8s,A8s,J7o,T6s,86o,K8o,33,22,Q9o,JTs,K4o,K7o
Sample,60,CALL,BB,BTN,Q9s,K3o,66,76o,64s,T9s,AJo,QJs,J5s,95s,T8s,43s,Q8o,T8o,J9s,T7o,97o,A5o,A4o,T5s,75s,K7s,87s,A2o,44,Q7s,87o,AJs,KJs,Q5s,J8o,97s,AQo,KJo,J7s,A9o,J6o,K5o,KTo,A8o,54o,K2s,54s,ATs,QJo,96s,76s,A2s,Q6s,A3s,K5s,77,99,K9o,K6s,QTo,A5s,A3o,Q6o,Q8s,A7s,A6s,J6s,98o,QTs,J9o,85s,K8s,ATo,K9s,55,T9o,96o,T6o,K3s,75o,Q5o,KTs,JTo,88,98s,65o,KQo,64o,A4s,Q7o,A6o,86s,K4s,A7o,T7s,74s,53s,65s,TT,K6o,A9s,J8s,A8s,J7o,T6s,86o,K8o,33,22,Q9o,JTs,K4o,K7o
Sample,100,CALL,BB,BTN,Q9s,K3o,66,76o,64s,T9s,AJo,QJs,J5s,95s,T8s,43s,Q8o,T8o,J9s,T7o,97o,A5o,A4o,T5s,75s,K7s,87s,A2o,44,Q7s,87o,AJs,KJs,Q5s,J8o,97s,AQo,KJo,J7s,A9o,J6o,K5o,KTo,A8o,54o,K2s,54s,ATs,QJo,96s,76s,A2s,Q6s,A3s,K5s,77,99,K9o,K6s,QTo,A5s,A3o,Q6o,Q8s,A7s,A6s,J6s,98o,QTs,J9o,85s,K8s,ATo,K9s,55,T9o,96o,T6o,K3s,75o,Q5o,KTs,JTo,88,98s,65o,KQo,64o,A4s,Q7o,A6o,86s,K4s,A7o,T7s,74s,53s,65s,TT,K6o,A9s,J8s,A8s,J7o,T6s,86o,K8o,33,22,Q9o,JTs,K4o,K7o
Sample,100,CALL,SB,BTN,Q9s,44,A2s,KTs,66,AJs,98s,T9s,88,A3s,KQo,AJo,QJs,KJs,A4s,77,99,A5s,T8s,Q8s,TT,AQo,A7s,J9s,A6s,A9s,KJo,J8s,A8s,QTs,33,ATo,K8s,K9s,JTs,55,ATs,JJ
Sample,60,CALL,SB,BTN,Q9s,44,A2s,KTs,66,AJs,88,T9s,98s,AJo,KQo,A3s,QJs,KJs,A4s,77,99,A5s,T8s,Q8s,TT,AQo,A7s,J9s,A6s,A9s,KJo,A8s,J8s,QTs,33,K8s,ATo,K9s,JTs,55,ATs,JJ
Sample,40,CALL,SB,BTN,Q9s,44,A2s,KTs,66,AJs,88,T9s,98s,AJo,KQo,A3s,QJs,KJs,A4s,77,99,A5s,T8s,Q8s,TT,AQo,A7s,J9s,A6s,A9s,KJo,A8s,J8s,QTs,33,K8s,ATo,K9s,JTs,55,ATs,JJ
Sample,25,CALL,SB,BTN,Q9s,44,A2s,KTs,66,AJs,88,T9s,98s,AJo,KQo,A3s,QJs,KJs,A4s,77,99,A5s,T8s,Q8s,TT,AQo,A7s,J9s,A6s,A9s,KJo,A8s,J8s,QTs,33,K8s,ATo,K9s,JTs,55,ATs,JJ
Sample,100,CALL,BB,SB,Q9s,32s,K3o,J3s,66,76o,64s,T9s,AJo,QJs,J5s,K2o,95s,T8s,43s,Q8o,T8o,J9s,T7o,97o,A5o,A4o,T5s,75s,K7s,87s,A2o,44,Q7s,87o,84s,AJs,KJs,J2s,Q5s,Q3s,J8o,97s,Q2s,AQo,KJo,J7s,A9o,J6o,K5o,KTo,A8o,54o,K2s,54s,ATs,QJo,T2s,T3s,96s,76s,A2s,Q6s,A3s,K5s,77,99,K9o,K6s,QTo,A5s,A3o,Q6o,Q8s,A7s,A6s,J6s,98o,QTs,J9o,Q4s,85s,K8s,ATo,K9s,55,T9o,96o,T6o,K3s,75o,Q5o,KTs,JTo,88,98s,65o,KQo,64o,A4s,Q7o,A6o,86s,K4s,A7o,T7s,74s,J4s,53s,65s,TT,K6o,A9s,J8s,A8s,J7o,T6s,86o,K8o,33,22,T4s,Q9o,JTs,94s,K4o,K7o,42s
Sample,25,CALL,BB,SB,Q9s,K3o,32s,J3s,66,76o,T9s,64s,AJo,QJs,J5s,K2o,95s,T8s,43s,Q8o,T8o,J9s,T7o,97o,A5o,A4o,T5s,K7s,75s,87s,A2o,44,87o,Q7s,AJs,84s,KJs,J2s,Q5s,J8o,Q3s,97s,Q2s,AQo,KJo,J7s,A9o,J6o,KTo,K5o,54o,A8o,K2s,54s,ATs,QJo,T2s,T3s,96s,76s,A2s,Q6s,A3s,K5s,77,99,K9o,K6s,QTo,A5s,A3o,Q6o,Q8s,A7s,A6s,J6s,98o,QTs,J9o,Q4s,85s,K8s,ATo,K9s,55,T9o,96o,T6o,K3s,75o,Q5o,KTs,JTo,88,98s,65o,KQo,64o,A4s,A6o,Q7o,K4s,86s,T7s,A7o,74s,53s,J4s,TT,65s,K6o,A9s,A8s,J8s,J7o,86o,T6s,K8o,33,22,T4s,Q9o,JTs,K4o,94s,K7o,42s
Sample,40,CALL,BB,SB,Q9s,K3o,32s,J3s,66,76o,T9s,64s,AJo,QJs,J5s,K2o,95s,T8s,43s,Q8o,T8o,J9s,T7o,97o,A5o,A4o,T5s,K7s,75s,87s,A2o,44,87o,Q7s,AJs,84s,KJs,J2s,Q5s,J8o,Q3s,97s,Q2s,AQo,KJo,J7s,A9o,J6o,KTo,K5o,54o,A8o,K2s,54s,ATs,QJo,T2s,T3s,96s,76s,A2s,Q6s,A3s,K5s,77,99,K9o,K6s,QTo,A5s,A3o,Q6o,Q8s,A7s,A6s,J6s,98o,QTs,J9o,Q4s,85s,K8s,ATo,K9s,55,T9o,96o,T6o,K3s,75o,Q5o,KTs,JTo,88,98s,65o,KQo,64o,A4s,A6o,Q7o,K4s,86s,T7s,A7o,74s,53s,J4s,TT,65s,K6o,A9s,A8s,J8s,J7o,86o,T6s,K8o,33,22,T4s,Q9o,JTs,K4o,94s,K7o,42s
Sample,60,CALL,BB,SB,Q9s,K3o,32s,J3s,66,76o,T9s,64s,AJo,QJs,J5s,K2o,95s,T8s,43s,Q8o,T8o,J9s,T7o,97o,A5o,A4o,T5s,K7s,75s,87s,A2o,44,87o,Q7s,AJs,84s,KJs,J2s,Q5s,J8o,Q3s,97s,Q2s,AQo,KJo,J7s,A9o,J6o,KTo,K5o,54o,A8o,K2s,54s,ATs,QJo,T2s,T3s,96s,76s,A2s,Q6s,A3s,K5s,77,99,K9o,K6s,QTo,A5s,A3o,Q6o,Q8s,A7s,A6s,J6s,98o,QTs,J9o,Q4s,85s,K8s,ATo,K9s,55,T9o,96o,T6o,K3s,75o,Q5o,KTs,JTo,88,98s,65o,KQo,64o,A4s,A6o,Q7o,K4s,86s,T7s,A7o,74s,53s,J4s,TT,65s,K6o,A9s,A8s,J8s,J7o,86o,T6s,K8o,33,22,T4s,Q9o,JTs,K4o,94s,K7o,42s
Sample,100,THREE_BET,MP2,UTG,KQs,AQo,AQs,KK,AA,K9s,A8s,QQ,AJs,AKs,JJ,AKo
Sample,100,THREE_BET,MP3,UTG,KQs,AQo,AQs,KK,AA,K9s,A8s,QQ,AJs,AKs,JJ,AKo
Sample,100,THREE_BET,CO,UTG,KQs,AQo,AQs,KK,AA,QQ,AJs,AKs,JJ,AKo
Sample,100,THREE_BET,BTN,UTG,KQs,AQo,AQs,KK,AA,QQ,AJs,AKs,JJ,AKo
Sample,100,THREE_BET,SB,UTG,QQ,AKs,AQs,AA,KK,AKo
Sample,100,THREE_BET,BB,UTG,KQs,AQs,KK,AA,QQ,AKs,AKo,JJ
Sample,100,THREE_BET,MP1,UTG1,KQs,AJs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,100,THREE_BET,MP2,UTG1,KQs,AJs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,100,THREE_BET,MP3,UTG1,KQs,AJs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,100,THREE_BET,CO,UTG1,KQs,AJs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,100,THREE_BET,BTN,UTG1,KQs,AJs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,100,THREE_BET,BB,UTG1,AKo,QQ,AKs,KK,AQs,AA
Sample,100,THREE_BET,SB,UTG1,AKo,QQ,AKs,KK,AQs,AA
Sample,25,THREE_BET,BB,UTG,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,25,THREE_BET,MP1,UTG,AJs,KQs,AKs,AQs,TT,QQ,KK,AA,JJ
Sample,40,THREE_BET,MP1,UTG,AJs,KQs,AKs,AQs,TT,QQ,KK,AA,JJ
Sample,60,THREE_BET,BB,UTG,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,40,THREE_BET,BB,UTG,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,25,THREE_BET,SB,UTG,QTs,JTs,KTs,66,KJs,TT,QQ,KK,AQo,ATs,AJs,QJs,KQs,AKs,AQs,77,AJo,99,88,AKo,A9s,KQo,AA,JJ,ATo
Sample,60,THREE_BET,SB,UTG,AKo,QQ,AKs,KK,AQs,AA
Sample,40,THREE_BET,SB,UTG,AKo,QQ,AKs,KK,AQs,AA
Sample,25,THREE_BET,BTN,UTG,KJs,TT,QQ,AQo,KK,ATs,KQs,AJs,QJs,AKs,77,AJo,AQs,99,88,AKo,KQo,AA,JJ
Sample,60,THREE_BET,BTN,UTG,AJs,KQs,AKs,AQs,AKo,QQ,AQo,KK,AA,JJ
Sample,40,THREE_BET,BTN,UTG,AJs,KQs,AKs,AQs,AKo,QQ,AQo,KK,AA,JJ
Sample,25,THREE_BET,CO,UTG,KQs,AJs,AKs,KJs,AQs,99,TT,88,AKo,QQ,KK,AQo,AA,JJ
Sample,60,THREE_BET,CO,UTG,AJs,KQs,AKs,AQs,AKo,QQ,AQo,KK,AA,JJ
Sample,40,THREE_BET,CO,UTG,AJs,KQs,AKs,AQs,AKo,QQ,AQo,KK,AA,JJ
Sample,25,THREE_BET,MP3,UTG,AJs,KQs,AKs,AQs,TT,QQ,KK,AA,JJ
Sample,60,THREE_BET,MP3,UTG,KQs,AJs,AKs,A8s,AQs,AKo,QQ,KK,K9s,AQo,AA,JJ
Sample,40,THREE_BET,MP3,UTG,KQs,AJs,AKs,A8s,AQs,AKo,QQ,KK,K9s,AQo,AA,JJ
Sample,25,THREE_BET,MP2,UTG,AJs,KQs,AKs,AQs,TT,QQ,KK,AA,JJ
Sample,60,THREE_BET,MP2,UTG,KQs,AJs,AKs,A8s,AQs,AKo,QQ,KK,K9s,AQo,AA,JJ
Sample,40,THREE_BET,MP2,UTG,KQs,AJs,AKs,A8s,AQs,AKo,QQ,KK,K9s,AQo,AA,JJ
Sample,100,THREE_BET,MP2,MP1,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,15,THREE_BET,BB,UTG1,AKs,AQs,AKo,QQ,KK,AA
Sample,15,THREE_BET,MP1,UTG1,AJs,KQs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,15,THREE_BET,MP2,UTG1,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,15,THREE_BET,MP3,UTG1,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,15,THREE_BET,CO,UTG1,AJs,KQs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,15,THREE_BET,BTN,UTG1,AJs,KQs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,15,THREE_BET,SB,UTG1,AKo,QQ,AKs,KK,AQs,AA
Sample,60,THREE_BET,BB,UTG1,AKo,QQ,AKs,KK,AQs,AA
Sample,40,THREE_BET,BB,UTG1,AKo,QQ,AKs,KK,AQs,AA
Sample,25,THREE_BET,BB,UTG1,AKo,QQ,AKs,KK,AQs,AA
Sample,25,THREE_BET,SB,UTG1,AKo,QQ,AKs,KK,AQs,AA
Sample,40,THREE_BET,SB,UTG1,AKo,QQ,AKs,KK,AQs,AA
Sample,60,THREE_BET,SB,UTG1,AKo,QQ,AKs,KK,AQs,AA
Sample,60,THREE_BET,BTN,UTG1,AJs,KQs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,40,THREE_BET,BTN,UTG1,AJs,KQs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,25,THREE_BET,BTN,UTG1,AJs,KQs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,25,THREE_BET,CO,UTG1,AJs,KQs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,60,THREE_BET,CO,UTG1,AJs,KQs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,40,THREE_BET,CO,UTG1,AJs,KQs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,25,THREE_BET,MP3,UTG1,AJs,KQs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,60,THREE_BET,MP3,UTG1,AJs,KQs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,40,THREE_BET,MP3,UTG1,AJs,KQs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,25,THREE_BET,MP2,UTG1,AJs,KQs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,60,THREE_BET,MP2,UTG1,AJs,KQs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,40,THREE_BET,MP2,UTG1,AJs,KQs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,60,THREE_BET,MP1,UTG1,AJs,KQs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,40,THREE_BET,MP1,UTG1,AJs,KQs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,25,THREE_BET,MP1,UTG1,AJs,KQs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,15,THREE_BET,MP2,MP1,AJs,KQs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,15,THREE_BET,MP3,MP1,AJs,KQs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,15,THREE_BET,CO,MP1,AJs,KQs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,15,THREE_BET,BTN,MP1,AJs,KQs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,15,THREE_BET,SB,MP1,AJs,KQs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,15,THREE_BET,BB,MP1,KQs,AJs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,100,THREE_BET,BB,MP1,AJs,KQs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,100,THREE_BET,SB,MP1,AJs,KQs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,100,THREE_BET,BTN,MP1,AJs,KQs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,100,THREE_BET,CO,MP1,AJs,KQs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,100,THREE_BET,MP3,MP1,AJs,KQs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,60,THREE_BET,MP2,MP1,AJs,KQs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,40,THREE_BET,MP2,MP1,AJs,KQs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,25,THREE_BET,MP2,MP1,AJs,KQs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,60,THREE_BET,MP3,MP1,KQs,AJs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,40,THREE_BET,MP3,MP1,KQs,AJs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,25,THREE_BET,MP3,MP1,KQs,AJs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,25,THREE_BET,CO,MP1,KQs,AJs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,40,THREE_BET,CO,MP1,KQs,AJs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,60,THREE_BET,CO,MP1,KQs,AJs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,60,THREE_BET,BTN,MP1,KQs,AJs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,40,THREE_BET,BTN,MP1,KQs,AJs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,25,THREE_BET,BTN,MP1,KQs,AJs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,25,THREE_BET,SB,MP1,KQs,AJs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,40,THREE_BET,SB,MP1,KQs,AJs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,60,THREE_BET,SB,MP1,KQs,AJs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,60,THREE_BET,BB,MP1,KQs,AJs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,40,THREE_BET,BB,MP1,KQs,AJs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,25,THREE_BET,BB,MP1,KQs,AJs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,15,THREE_BET,MP3,MP2,KQs,AJs,AKs,AQs,AKo,QQ,KK,ATs,AA,JJ
Sample,100,THREE_BET,MP3,MP2,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,100,THREE_BET,CO,MP2,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,100,THREE_BET,BTN,MP2,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,100,THREE_BET,SB,MP2,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,100,THREE_BET,BB,MP2,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,60,THREE_BET,BB,MP2,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,40,THREE_BET,BB,MP2,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,25,THREE_BET,BB,MP2,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,15,THREE_BET,BB,MP2,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,15,THREE_BET,SB,MP2,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,25,THREE_BET,SB,MP2,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,40,THREE_BET,SB,MP2,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,60,THREE_BET,SB,MP2,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,60,THREE_BET,BTN,MP2,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,40,THREE_BET,BTN,MP2,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,25,THREE_BET,BTN,MP2,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,15,THREE_BET,BTN,MP2,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,15,THREE_BET,CO,MP2,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,40,THREE_BET,CO,MP2,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,25,THREE_BET,CO,MP2,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,60,THREE_BET,CO,MP2,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,60,THREE_BET,MP3,MP2,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,40,THREE_BET,MP3,MP2,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,25,THREE_BET,MP3,MP2,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,15,THREE_BET,CO,MP3,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,100,THREE_BET,CO,MP3,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,100,THREE_BET,BTN,MP3,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,100,THREE_BET,SB,MP3,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,100,THREE_BET,BB,MP3,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,60,THREE_BET,BB,MP3,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,40,THREE_BET,BB,MP3,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,25,THREE_BET,BB,MP3,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,15,THREE_BET,BB,MP3,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,15,THREE_BET,SB,MP3,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,25,THREE_BET,SB,MP3,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,40,THREE_BET,SB,MP3,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,60,THREE_BET,SB,MP3,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,60,THREE_BET,BTN,MP3,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,40,THREE_BET,BTN,MP3,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,25,THREE_BET,BTN,MP3,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,15,THREE_BET,BTN,MP3,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,25,THREE_BET,CO,MP3,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,40,THREE_BET,CO,MP3,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,60,THREE_BET,CO,MP3,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,100,THREE_BET,BTN,CO,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,15,THREE_BET,BTN,CO,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,15,THREE_BET,SB,CO,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,15,THREE_BET,BB,CO,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,25,THREE_BET,BB,CO,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,25,THREE_BET,SB,CO,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,25,THREE_BET,BTN,CO,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,40,THREE_BET,BTN,CO,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,40,THREE_BET,SB,CO,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,40,THREE_BET,BB,CO,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,60,THREE_BET,BB,CO,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,60,THREE_BET,SB,CO,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,60,THREE_BET,BTN,CO,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,100,THREE_BET,SB,CO,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,100,THREE_BET,BB,CO,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,100,THREE_BET,SB,BTN,AJs,KQs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,15,THREE_BET,SB,BTN,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,15,THREE_BET,BB,BTN,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,25,THREE_BET,BB,BTN,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,25,THREE_BET,SB,BTN,KQs,AJs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,40,THREE_BET,SB,BTN,AJs,KQs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,40,THREE_BET,BB,BTN,AJs,KQs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,60,THREE_BET,BB,BTN,AJs,KQs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,60,THREE_BET,SB,BTN,AJs,KQs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,100,THREE_BET,BB,BTN,AJs,KQs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,100,THREE_BET,BB,SB,AJs,KQs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,15,THREE_BET,BB,SB,AJs,KQs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,25,THREE_BET,BB,SB,AJs,KQs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,40,THREE_BET,BB,SB,AJs,KQs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,60,THREE_BET,BB,SB,AJs,KQs,AKs,AQs,AKo,QQ,KK,AA,JJ
Sample,15,CALL_3_BET,SB,BB,Q7s,K3s,A2s,J9o,J7s,87s,K5s,T9s,Q9s,33,JTo,Q8o,A4o,22,98s,65s,A3o,J8s,A7s,K6o,55,T7s,K8s,K9o,A8o,A9o,K6s,76s,K2s,K8o,A4s,Q9o,K9s,QTo,A5s,T9o,Q5s,K4s,Q6s,A5o,K5o,K7s,44,J9s,A6o,A3s,A8s,A2o,K7o,Q8s,A6s,A7o,T8s,97s
Sample,15,CALL_3_BET,UTG,BB,QTs,JTs,KTs,KJs,TT,AQo,K9s,ATs,QJs,KQs,AJs,AJo,77,AQs,99,88,A9s,KQo
Sample,15,CALL_3_BET,BTN,SB,QTs,JTs,KTs,66,44,AQo,K9s,A7s,87s,A5s,55,J9s,A8s,Q9s,T9s,AJo,KJo,A6s,A9s,98s,KQo,65s,76s,ATo
Sample,15,CALL_3_BET,CO,BTN,66,KJs,KTs,TT,K9s,AQo,A5s,T9s,Q9s,AJo,77,99,A9s,QTs,JTs,44,A7s,ATs,QJs,AJs,KQs,J9s,55,A8s,AQs,A6s,88,KQo
Sample,15,CALL_3_BET,UTG,UTG1,QJs,KQs,AJs,QTs,JTs,KTs,KJs,AQs,99,TT,88,AQo,ATs
Sample,15,CALL_3_BET,UTG1,MP1,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,AQo,ATs
Sample,15,CALL_3_BET,UTG,MP1,QJs,KQs,AJs,QTs,JTs,KTs,KJs,AQs,99,TT,88,AQo,ATs
Sample,15,FOUR_BET,UTG,UTG1,AKo,AKs,QQ,KK,AA,JJ
Sample,15,FOUR_BET,UTG1,MP1,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,15,FOUR_BET,UTG,MP1,AKs,TT,AKo,QQ,KK,AA,JJ
Sample,15,CALL_3_BET,MP1,MP2,QTs,JTs,KTs,KJs,TT,AQo,ATs,QJs,KQs,AJs,AQs,77,AJo,99,88,A9s,KQo
Sample,15,CALL_3_BET,UTG,MP2,AJs,KQs,QJs,QTs,JTs,KTs,KJs,AQs,99,TT,88,A9s,K9s,AQo,ATs
Sample,15,FOUR_BET,MP1,MP2,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,15,FOUR_BET,UTG,MP2,AKs,TT,AKo,QQ,KK,AA,JJ
Sample,15,FOUR_BET,MP2,MP3,KJs,TT,QQ,KK,AQo,KQs,AJs,AKs,AQs,AJo,99,AKo,AA,JJ
Sample,15,FOUR_BET,UTG,MP3,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,15,FOUR_BET,MP3,CO,KJs,TT,QQ,KK,AQo,KQs,AJs,QJs,AKs,AQs,AJo,99,88,AKo,KQo,AA,JJ
Sample,15,FOUR_BET,UTG,CO,KQs,AJs,AKs,AQs,TT,AKo,QQ,KK,AQo,AA,JJ
Sample,15,FOUR_BET,CO,BTN,KJs,TT,QQ,KK,AQo,ATs,QJs,AJs,KQs,AKs,77,AJo,AQs,99,88,AKo,KQo,AA,JJ
Sample,15,FOUR_BET,UTG,BTN,KJs,TT,QQ,KK,AQo,ATs,QJs,KQs,AJs,AKs,AQs,99,88,AKo,AA,JJ
Sample,15,FOUR_BET,BTN,SB,QTs,JTs,KTs,KJs,TT,QQ,KK,AQo,ATs,QJs,AJs,KQs,AKs,77,AJo,AQs,99,88,AKo,KQo,AA,ATo,JJ
Sample,15,FOUR_BET,UTG,SB,KQs,AJs,AKs,AQs,TT,AKo,QQ,KK,AQo,AA,JJ
Sample,25,FOUR_BET,UTG,BB,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,40,FOUR_BET,UTG,BB,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,60,FOUR_BET,UTG,BB,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,100,FOUR_BET,UTG,BB,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,100,FOUR_BET,BTN,SB,QTs,JTs,KTs,KJs,TT,QQ,AQo,KK,ATs,KQs,AJs,QJs,AKs,77,AJo,AQs,99,88,AKo,KQo,AA,ATo,JJ
Sample,100,FOUR_BET,UTG,SB,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,25,FOUR_BET,UTG,SB,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,40,FOUR_BET,UTG,SB,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,60,FOUR_BET,UTG,SB,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,100,FOUR_BET,CO,BTN,KJs,TT,QQ,KK,AQo,ATs,KQs,AJs,QJs,AKs,77,AQs,AJo,99,88,AKo,KQo,AA,JJ
Sample,100,FOUR_BET,UTG,BTN,QJs,KQs,AJs,AKs,KJs,AQs,99,TT,88,AKo,QQ,AQo,KK,ATs,AA,JJ
Sample,25,FOUR_BET,UTG,BTN,QJs,KQs,AJs,AKs,KJs,AQs,99,TT,88,AKo,QQ,AQo,KK,ATs,AA,JJ
Sample,40,FOUR_BET,UTG,BTN,QJs,KQs,AJs,AKs,KJs,AQs,99,TT,88,AKo,QQ,AQo,KK,ATs,AA,JJ
Sample,60,FOUR_BET,UTG,BTN,QJs,KQs,AJs,AKs,KJs,AQs,99,TT,88,AKo,QQ,AQo,KK,ATs,AA,JJ
Sample,100,FOUR_BET,MP3,CO,KJs,TT,QQ,KK,AQo,QJs,AJs,KQs,AKs,AJo,AQs,99,88,AKo,KQo,AA,JJ
Sample,100,FOUR_BET,UTG,CO,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,25,FOUR_BET,UTG,CO,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,40,FOUR_BET,UTG,CO,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,60,FOUR_BET,UTG,CO,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,100,FOUR_BET,MP2,MP3,KQs,AJs,AKs,KJs,AQs,AJo,99,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,100,FOUR_BET,UTG,MP3,KQs,AJs,AKs,AQs,TT,AKo,QQ,KK,AQo,AA,JJ
Sample,25,FOUR_BET,UTG,MP3,KQs,AJs,AKs,AQs,TT,AKo,QQ,KK,AQo,AA,JJ
Sample,40,FOUR_BET,UTG,MP3,KQs,AJs,AKs,AQs,TT,AKo,QQ,KK,AQo,AA,JJ
Sample,60,FOUR_BET,UTG,MP3,KQs,AJs,AKs,AQs,TT,AKo,QQ,KK,AQo,AA,JJ
Sample,100,FOUR_BET,MP1,MP2,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,100,FOUR_BET,UTG,MP2,TT,AKo,QQ,AKs,KK,AA,JJ
Sample,25,FOUR_BET,UTG,MP2,TT,AKo,QQ,AKs,KK,AA,JJ
Sample,40,FOUR_BET,UTG,MP2,TT,AKo,QQ,AKs,KK,AA,JJ
Sample,60,FOUR_BET,UTG,MP2,TT,AKo,QQ,AKs,KK,AA,JJ
Sample,100,FOUR_BET,UTG1,MP1,KQs,AKo,AKs,QQ,AQs,KK,AA,JJ
Sample,100,FOUR_BET,UTG,MP1,TT,AKo,QQ,AKs,KK,AA,JJ
Sample,25,FOUR_BET,UTG,MP1,TT,AKo,QQ,AKs,KK,AA,JJ
Sample,40,FOUR_BET,UTG,MP1,TT,AKo,QQ,AKs,KK,AA,JJ
Sample,60,FOUR_BET,UTG,MP1,TT,AKo,QQ,AKs,KK,AA,JJ
Sample,100,FOUR_BET,UTG,UTG1,AKo,AKs,QQ,KK,AA,JJ
Sample,25,FOUR_BET,UTG,UTG1,AKo,AKs,QQ,KK,AA,JJ
Sample,40,FOUR_BET,UTG,UTG1,AKo,AKs,QQ,KK,AA,JJ
Sample,60,FOUR_BET,UTG,UTG1,AKo,AKs,QQ,KK,AA,JJ
Sample,100,FOUR_BET,UTG1,MP2,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,100,FOUR_BET,UTG1,MP3,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,100,FOUR_BET,UTG1,CO,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,100,FOUR_BET,UTG1,BTN,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,100,FOUR_BET,UTG1,SB,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,100,FOUR_BET,SB,BB,66,KJs,KTs,KTo,TT,AQo,AJo,77,KJo,99,A9s,AA,ATo,JJ,QTs,JTs,QQ,KK,ATs,KQs,AJs,QJs,AKs,AQs,QJo,88,AKo,KQo
Sample,100,FOUR_BET,UTG1,BB,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,60,FOUR_BET,UTG1,BB,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,40,FOUR_BET,UTG1,BB,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,25,FOUR_BET,UTG1,BB,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,15,FOUR_BET,UTG1,BB,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,15,FOUR_BET,UTG1,SB,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,25,FOUR_BET,UTG1,SB,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,40,FOUR_BET,UTG1,SB,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,60,FOUR_BET,UTG1,SB,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,60,FOUR_BET,UTG1,BTN,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,40,FOUR_BET,UTG1,BTN,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,25,FOUR_BET,UTG1,BTN,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,15,FOUR_BET,UTG1,BTN,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,15,FOUR_BET,UTG1,CO,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,25,FOUR_BET,UTG1,CO,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,40,FOUR_BET,UTG1,CO,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,60,FOUR_BET,UTG1,CO,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,60,FOUR_BET,UTG1,MP3,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,40,FOUR_BET,UTG1,MP3,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,25,FOUR_BET,UTG1,MP3,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,15,FOUR_BET,UTG1,MP3,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,15,FOUR_BET,UTG1,MP2,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,25,FOUR_BET,UTG1,MP2,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,40,FOUR_BET,UTG1,MP2,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,60,FOUR_BET,UTG1,MP2,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,60,FOUR_BET,UTG1,MP1,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,40,FOUR_BET,UTG1,MP1,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,25,FOUR_BET,UTG1,MP1,KQs,AKo,QQ,AKs,KK,AQs,AA,JJ
Sample,25,FOUR_BET,MP1,MP2,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,40,FOUR_BET,MP1,MP2,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,60,FOUR_BET,MP1,MP2,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,100,FOUR_BET,MP1,MP3,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,60,FOUR_BET,MP1,MP3,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,40,FOUR_BET,MP1,MP3,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,25,FOUR_BET,MP1,MP3,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,15,FOUR_BET,MP1,MP3,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,15,FOUR_BET,MP1,CO,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,25,FOUR_BET,MP1,CO,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,40,FOUR_BET,MP1,CO,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,60,FOUR_BET,MP1,CO,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,100,FOUR_BET,MP1,CO,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,100,FOUR_BET,MP1,BTN,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,60,FOUR_BET,MP1,BTN,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,40,FOUR_BET,MP1,BTN,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,25,FOUR_BET,MP1,BTN,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,15,FOUR_BET,MP1,BTN,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,15,FOUR_BET,MP1,SB,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,25,FOUR_BET,MP1,SB,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,40,FOUR_BET,MP1,SB,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,60,FOUR_BET,MP1,SB,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,100,FOUR_BET,MP1,SB,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,100,FOUR_BET,MP1,BB,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,60,FOUR_BET,MP1,BB,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,40,FOUR_BET,MP1,BB,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,25,FOUR_BET,MP1,BB,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,15,FOUR_BET,MP1,BB,AJs,KQs,AKs,AQs,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,15,FOUR_BET,MP2,BB,KQs,AJs,AKs,KJs,AQs,AJo,99,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,25,FOUR_BET,MP2,MP3,KQs,AJs,AKs,KJs,AQs,AJo,99,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,40,FOUR_BET,MP2,MP3,KQs,AJs,AKs,KJs,AQs,AJo,99,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,60,FOUR_BET,MP2,MP3,KQs,AJs,AKs,KJs,AQs,AJo,99,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,100,FOUR_BET,MP2,CO,KQs,AJs,AKs,KJs,AQs,AJo,99,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,60,FOUR_BET,MP2,CO,KQs,AJs,AKs,KJs,AQs,AJo,99,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,40,FOUR_BET,MP2,CO,KQs,AJs,AKs,KJs,AQs,AJo,99,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,25,FOUR_BET,MP2,CO,KQs,AJs,AKs,KJs,AQs,AJo,99,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,15,FOUR_BET,MP2,CO,KQs,AJs,AKs,KJs,AQs,AJo,99,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,15,FOUR_BET,MP2,BTN,KQs,AJs,AKs,KJs,AQs,AJo,99,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,25,FOUR_BET,MP2,BTN,KQs,AJs,AKs,KJs,AQs,AJo,99,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,40,FOUR_BET,MP2,BTN,KQs,AJs,AKs,KJs,AQs,AJo,99,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,60,FOUR_BET,MP2,BTN,KQs,AJs,AKs,KJs,AQs,AJo,99,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,100,FOUR_BET,MP2,BTN,KQs,AJs,AKs,KJs,AQs,AJo,99,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,100,FOUR_BET,MP2,SB,KQs,AJs,AKs,KJs,AQs,AJo,99,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,60,FOUR_BET,MP2,SB,KQs,AJs,AKs,KJs,AQs,AJo,99,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,40,FOUR_BET,MP2,SB,KQs,AJs,AKs,KJs,AQs,AJo,99,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,25,FOUR_BET,MP2,SB,KQs,AJs,AKs,KJs,AQs,AJo,99,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,15,FOUR_BET,MP2,SB,KQs,AJs,AKs,KJs,AQs,AJo,99,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,25,FOUR_BET,MP2,BB,KQs,AJs,AKs,KJs,AQs,AJo,99,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,40,FOUR_BET,MP2,BB,KQs,AJs,AKs,KJs,AQs,AJo,99,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,60,FOUR_BET,MP2,BB,KQs,AJs,AKs,KJs,AQs,AJo,99,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,100,FOUR_BET,MP2,BB,KQs,AJs,AKs,KJs,AQs,AJo,99,TT,AKo,QQ,AQo,KK,AA,JJ
Sample,100,FOUR_BET,MP3,BB,KJs,TT,QQ,KK,AQo,KQs,AJs,QJs,AKs,AQs,AJo,99,88,AKo,KQo,AA,JJ
Sample,60,FOUR_BET,MP3,CO,KJs,TT,QQ,KK,AQo,KQs,AJs,QJs,AKs,AQs,AJo,99,88,AKo,KQo,AA,JJ
Sample,40,FOUR_BET,MP3,CO,KJs,TT,QQ,KK,AQo,KQs,AJs,QJs,AKs,AQs,AJo,99,88,AKo,KQo,AA,JJ
Sample,25,FOUR_BET,MP3,CO,KJs,TT,QQ,KK,AQo,KQs,AJs,QJs,AKs,AQs,AJo,99,88,AKo,KQo,AA,JJ
Sample,15,FOUR_BET,MP3,BTN,KJs,TT,QQ,KK,AQo,QJs,AJs,KQs,AKs,AJo,AQs,99,88,AKo,KQo,AA,JJ
Sample,25,FOUR_BET,MP3,BTN,KJs,TT,QQ,KK,AQo,QJs,AJs,KQs,AKs,AJo,AQs,99,88,AKo,KQo,AA,JJ
Sample,40,FOUR_BET,MP3,BTN,KJs,TT,QQ,KK,AQo,QJs,AJs,KQs,AKs,AJo,AQs,99,88,AKo,KQo,AA,JJ
Sample,60,FOUR_BET,BTN,SB,QTs,JTs,KTs,KJs,TT,QQ,AQo,KK,ATs,KQs,AJs,QJs,AKs,77,AJo,AQs,99,88,AKo,KQo,AA,ATo,JJ
Sample,60,FOUR_BET,MP3,SB,KJs,TT,QQ,KK,AQo,KQs,AJs,QJs,AKs,AQs,AJo,99,88,AKo,KQo,AA,JJ
Sample,100,FOUR_BET,MP3,SB,KJs,TT,QQ,KK,AQo,KQs,AJs,QJs,AKs,AQs,AJo,99,88,AKo,KQo,AA,JJ
Sample,40,FOUR_BET,MP3,SB,KJs,TT,QQ,KK,AQo,KQs,AJs,QJs,AKs,AQs,AJo,99,88,AKo,KQo,AA,JJ
Sample,25,FOUR_BET,MP3,SB,KJs,TT,QQ,KK,AQo,KQs,AJs,QJs,AKs,AQs,AJo,99,88,AKo,KQo,AA,JJ
Sample,15,FOUR_BET,MP3,SB,KJs,TT,QQ,KK,AQo,KQs,AJs,QJs,AKs,AQs,AJo,99,88,AKo,KQo,AA,JJ
Sample,15,FOUR_BET,MP3,BB,KJs,TT,QQ,KK,AQo,KQs,AJs,QJs,AKs,AQs,AJo,99,88,AKo,KQo,AA,JJ
Sample,25,FOUR_BET,MP3,BB,KJs,TT,QQ,KK,AQo,KQs,AJs,QJs,AKs,AQs,AJo,99,88,AKo,KQo,AA,JJ
Sample,40,FOUR_BET,MP3,BB,KJs,TT,QQ,KK,AQo,KQs,AJs,QJs,AKs,AQs,AJo,99,88,AKo,KQo,AA,JJ
Sample,60,FOUR_BET,MP3,BB,KJs,TT,QQ,KK,AQo,KQs,AJs,QJs,AKs,AQs,AJo,99,88,AKo,KQo,AA,JJ
Sample,60,FOUR_BET,CO,BTN,KJs,TT,QQ,KK,AQo,ATs,QJs,AJs,KQs,AKs,77,AJo,AQs,99,88,AKo,KQo,AA,JJ
Sample,40,FOUR_BET,CO,BTN,KJs,TT,QQ,KK,AQo,ATs,QJs,AJs,KQs,AKs,77,AJo,AQs,99,88,AKo,KQo,AA,JJ
Sample,25,FOUR_BET,CO,BTN,KJs,TT,QQ,KK,AQo,ATs,QJs,AJs,KQs,AKs,77,AJo,AQs,99,88,AKo,KQo,AA,JJ
Sample,15,FOUR_BET,CO,SB,KJs,TT,QQ,KK,AQo,ATs,QJs,AJs,KQs,AKs,77,AJo,AQs,99,88,AKo,KQo,AA,JJ
Sample,25,FOUR_BET,CO,SB,KJs,TT,QQ,KK,AQo,ATs,QJs,AJs,KQs,AKs,77,AJo,AQs,99,88,AKo,KQo,AA,JJ
Sample,40,FOUR_BET,CO,SB,KJs,TT,QQ,KK,AQo,ATs,QJs,AJs,KQs,AKs,77,AJo,AQs,99,88,AKo,KQo,AA,JJ
Sample,60,FOUR_BET,CO,SB,KJs,TT,QQ,KK,AQo,ATs,QJs,AJs,KQs,AKs,77,AJo,AQs,99,88,AKo,KQo,AA,JJ
Sample,100,FOUR_BET,CO,SB,KJs,TT,QQ,KK,AQo,ATs,QJs,AJs,KQs,AKs,77,AJo,AQs,99,88,AKo,KQo,AA,JJ
Sample,100,FOUR_BET,CO,BB,KJs,TT,QQ,KK,AQo,ATs,QJs,AJs,KQs,AKs,77,AJo,AQs,99,88,AKo,KQo,AA,JJ
Sample,60,FOUR_BET,CO,BB,KJs,TT,QQ,KK,AQo,ATs,QJs,AJs,KQs,AKs,77,AJo,AQs,99,88,AKo,KQo,AA,JJ
Sample,40,FOUR_BET,CO,BB,KJs,TT,QQ,KK,AQo,ATs,QJs,AJs,KQs,AKs,77,AJo,AQs,99,88,AKo,KQo,AA,JJ
Sample,25,FOUR_BET,CO,BB,KJs,TT,QQ,KK,AQo,ATs,QJs,AJs,KQs,AKs,77,AJo,AQs,99,88,AKo,KQo,AA,JJ
Sample,15,FOUR_BET,CO,BB,KJs,TT,QQ,KK,AQo,ATs,QJs,AJs,KQs,AKs,77,AJo,AQs,99,88,AKo,KQo,AA,JJ
Sample,25,FOUR_BET,BTN,SB,QTs,JTs,KTs,KJs,TT,QQ,AQo,KK,ATs,KQs,AJs,QJs,AKs,77,AJo,AQs,99,88,AKo,KQo,AA,ATo,JJ
Sample,40,FOUR_BET,BTN,SB,QTs,JTs,KTs,KJs,TT,QQ,AQo,KK,ATs,KQs,AJs,QJs,AKs,77,AJo,AQs,99,88,AKo,KQo,AA,ATo,JJ
Sample,100,FOUR_BET,BTN,BB,QTs,JTs,KTs,KJs,TT,QQ,AQo,KK,ATs,KQs,AJs,QJs,AKs,77,AJo,AQs,99,88,AKo,KQo,AA,ATo,JJ
Sample,60,FOUR_BET,BTN,BB,QTs,JTs,KTs,KJs,TT,QQ,AQo,KK,ATs,KQs,AJs,QJs,AKs,77,AJo,AQs,99,88,AKo,KQo,AA,ATo,JJ
Sample,40,FOUR_BET,BTN,BB,QTs,JTs,KTs,KJs,TT,QQ,AQo,KK,ATs,KQs,AJs,QJs,AKs,77,AJo,AQs,99,88,AKo,KQo,AA,ATo,JJ
Sample,25,FOUR_BET,BTN,BB,QTs,JTs,KTs,KJs,TT,QQ,AQo,KK,ATs,KQs,AJs,QJs,AKs,77,AJo,AQs,99,88,AKo,KQo,AA,ATo,JJ
Sample,15,FOUR_BET,BTN,BB,QTs,JTs,KTs,KJs,TT,QQ,AQo,KK,ATs,KQs,AJs,QJs,AKs,77,AJo,AQs,99,88,AKo,KQo,AA,ATo,JJ
Sample,25,FOUR_BET,SB,BB,66,KJs,KTs,KTo,TT,AQo,AJo,77,KJo,99,A9s,AA,ATo,JJ,QTs,JTs,QQ,KK,ATs,KQs,AJs,QJs,AKs,AQs,QJo,88,AKo,KQo
Sample,40,FOUR_BET,SB,BB,66,KJs,KTs,KTo,TT,AQo,AJo,77,KJo,99,A9s,AA,ATo,JJ,QTs,JTs,QQ,KK,ATs,KQs,AJs,QJs,AKs,AQs,QJo,88,AKo,KQo
Sample,60,FOUR_BET,SB,BB,66,KJs,KTs,KTo,TT,AQo,AJo,77,KJo,99,A9s,AA,ATo,JJ,QTs,JTs,QQ,KK,ATs,KQs,AJs,QJs,AKs,AQs,QJo,88,AKo,KQo
Sample,100,CALL_3_BET,SB,BB,Q7s,K3s,A2s,J9o,J7s,87s,K5s,Q9s,T9s,JTo,33,Q8o,A4o,22,98s,65s,A3o,J8s,A7s,K6o,55,T7s,K8s,K9o,A8o,A9o,K6s,76s,K2s,K8o,A4s,Q9o,QTo,K9s,A5s,T9o,Q5s,Q6s,K4s,A5o,K5o,K7s,44,A3s,A6o,J9s,A2o,A8s,K7o,Q8s,A6s,A7o,T8s,97s
Sample,100,CALL_3_BET,UTG,BB,QTs,JTs,KTs,KJs,TT,K9s,AQo,ATs,AJs,KQs,QJs,AJo,77,AQs,99,88,A9s,KQo
Sample,100,CALL_3_BET,UTG,UTG1,QJs,KQs,AJs,QTs,JTs,KTs,KJs,AQs,99,TT,88,AQo,ATs
Sample,60,CALL_3_BET,UTG,UTG1,QJs,KQs,AJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,AQo,ATs
Sample,40,CALL_3_BET,UTG,UTG1,QJs,KQs,AJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,AQo,ATs
Sample,25,CALL_3_BET,UTG,UTG1,QJs,KQs,AJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,AQo,ATs
Sample,25,CALL_3_BET,UTG,MP1,QJs,KQs,AJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,AQo,ATs
Sample,40,CALL_3_BET,UTG,MP1,QJs,KQs,AJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,AQo,ATs
Sample,60,CALL_3_BET,UTG,MP1,QJs,KQs,AJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,AQo,ATs
Sample,100,CALL_3_BET,UTG,MP1,QJs,KQs,AJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,AQo,ATs
Sample,100,CALL_3_BET,MP1,MP2,QTs,JTs,KTs,KJs,TT,AQo,ATs,AJs,KQs,QJs,AQs,AJo,77,99,88,A9s,KQo
Sample,100,CALL_3_BET,UTG,MP2,QTs,JTs,KTs,KJs,TT,K9s,AQo,ATs,AJs,KQs,QJs,AQs,99,88,A9s
Sample,60,CALL_3_BET,UTG,MP2,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,A9s,K9s,AQo,ATs
Sample,40,CALL_3_BET,UTG,MP2,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,A9s,K9s,AQo,ATs
Sample,25,CALL_3_BET,UTG,MP2,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,A9s,K9s,AQo,ATs
Sample,15,CALL_3_BET,MP2,MP3,QTs,JTs,KJs,KTs,TT,AQo,ATs,AJs,KQs,QJs,AJo,77,AQs,99,88,A9s,KQo
Sample,15,CALL_3_BET,UTG,MP3,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,A9s,K9s,AQo,ATs
Sample,25,CALL_3_BET,UTG,MP3,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,A9s,K9s,AQo,ATs
Sample,40,CALL_3_BET,UTG,MP3,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,A9s,K9s,AQo,ATs
Sample,60,CALL_3_BET,UTG,MP3,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,A9s,K9s,AQo,ATs
Sample,100,CALL_3_BET,UTG,MP3,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,A9s,K9s,AQo,ATs
Sample,100,CALL_3_BET,MP3,CO,66,KJs,KTs,TT,K9s,AQo,A5s,87s,T9s,Q9s,AJo,77,99,A9s,98s,ATo,QTs,JTs,44,A7s,ATs,AJs,KQs,QJs,J9s,55,A8s,AQs,A6s,88,KQo,76s
Sample,100,CALL_3_BET,UTG,CO,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,A9s,K9s,AQo,ATs
Sample,60,CALL_3_BET,UTG,CO,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,A9s,K9s,AQo,ATs
Sample,40,CALL_3_BET,UTG,CO,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,A9s,K9s,AQo,ATs
Sample,25,CALL_3_BET,UTG,CO,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,A9s,K9s,AQo,ATs
Sample,15,CALL_3_BET,UTG,CO,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,A9s,K9s,AQo,ATs
Sample,15,CALL_3_BET,UTG,BTN,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,A9s,K9s,AQo,ATs
Sample,25,CALL_3_BET,UTG,BTN,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,A9s,K9s,AQo,ATs
Sample,40,CALL_3_BET,UTG,BTN,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,A9s,K9s,AQo,ATs
Sample,60,CALL_3_BET,UTG,BTN,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,A9s,K9s,AQo,ATs
Sample,100,CALL_3_BET,UTG,BTN,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,A9s,K9s,AQo,ATs
Sample,100,CALL_3_BET,BTN,SB,66,KTs,AQo,K9s,87s,A5s,T9s,Q9s,KJo,AJo,A9s,98s,65s,ATo,QTs,JTs,44,A7s,55,J9s,A8s,A6s,KQo,76s
Sample,100,CALL_3_BET,UTG,SB,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,A9s,K9s,AQo,ATs
Sample,60,CALL_3_BET,UTG,SB,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,A9s,K9s,AQo,ATs
Sample,40,CALL_3_BET,UTG,SB,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,A9s,K9s,AQo,ATs
Sample,25,CALL_3_BET,UTG,SB,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,A9s,K9s,AQo,ATs
Sample,15,CALL_3_BET,UTG,SB,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,A9s,K9s,AQo,ATs
Sample,25,CALL_3_BET,UTG,BB,QTs,JTs,KTs,KJs,TT,K9s,AQo,ATs,AJs,KQs,QJs,AJo,77,AQs,99,88,A9s,KQo
Sample,40,CALL_3_BET,UTG,BB,QTs,JTs,KTs,KJs,TT,K9s,AQo,ATs,AJs,KQs,QJs,AJo,77,AQs,99,88,A9s,KQo
Sample,60,CALL_3_BET,UTG,BB,QTs,JTs,KTs,KJs,TT,K9s,AQo,ATs,AJs,KQs,QJs,AJo,77,AQs,99,88,A9s,KQo
Sample,100,CALL_3_BET,UTG1,BB,QTs,JTs,KJs,KTs,66,TT,AQo,K9s,ATs,AJs,KQs,QJs,J9s,A8s,T9s,Q9s,AQs,77,AJo,99,88,A9s,KQo,ATo
Sample,100,CALL_3_BET,UTG1,MP1,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,AQo,ATs
Sample,60,CALL_3_BET,UTG1,MP1,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,AQo,ATs
Sample,40,CALL_3_BET,UTG1,MP1,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,AQo,ATs
Sample,25,CALL_3_BET,UTG1,MP1,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,AQo,ATs
Sample,15,CALL_3_BET,UTG1,MP2,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,AQo,ATs
Sample,25,CALL_3_BET,UTG1,MP2,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,AQo,ATs
Sample,40,CALL_3_BET,UTG1,MP2,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,AQo,ATs
Sample,60,CALL_3_BET,UTG1,MP2,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,AQo,ATs
Sample,100,CALL_3_BET,UTG1,MP2,AJs,KQs,QJs,QTs,JTs,KJs,KTs,AQs,99,TT,88,AQo,ATs
Sample,100,CALL_3_BET,MP2,MP3,QTs,JTs,KJs,KTs,TT,AQo,ATs,AJs,KQs,QJs,AJo,77,AQs,99,88,A9s,KQo
Sample,100,CALL_3_BET,UTG1,MP3,QTs,JTs,KTs,KJs,TT,K9s,AQo,ATs,QJs,KQs,AJs,AJo,77,AQs,99,88,A9s,KQo
Sample,60,CALL_3_BET,UTG1,MP3,QTs,JTs,KTs,KJs,TT,AQo,K9s,ATs,AJs,KQs,QJs,AJo,77,AQs,99,88,A9s,KQo
Sample,40,CALL_3_BET,UTG1,MP3,QTs,JTs,KTs,KJs,TT,AQo,K9s,ATs,AJs,KQs,QJs,AJo,77,AQs,99,88,A9s,KQo
Sample,25,CALL_3_BET,UTG1,MP3,QTs,JTs,KTs,KJs,TT,AQo,K9s,ATs,AJs,KQs,QJs,AJo,77,AQs,99,88,A9s,KQo
Sample,15,CALL_3_BET,UTG1,MP3,QTs,JTs,KTs,KJs,TT,AQo,K9s,ATs,AJs,KQs,QJs,AJo,77,AQs,99,88,A9s,KQo
Sample,15,CALL_3_BET,MP3,CO,66,KJs,KTs,TT,AQo,K9s,A5s,87s,T9s,Q9s,AJo,77,99,A9s,98s,ATo,QTs,JTs,44,ATs,A7s,QJs,KQs,AJs,J9s,55,A8s,AQs,A6s,88,KQo,76s
Sample,15,CALL_3_BET,UTG1,CO,QTs,JTs,KTs,KJs,TT,AQo,K9s,ATs,AJs,KQs,QJs,AJo,77,AQs,99,88,A9s,KQo
Sample,25,CALL_3_BET,UTG1,CO,QTs,JTs,KTs,KJs,TT,AQo,K9s,ATs,AJs,KQs,QJs,AJo,77,AQs,99,88,A9s,KQo
Sample,40,CALL_3_BET,UTG1,CO,QTs,JTs,KTs,KJs,TT,AQo,K9s,ATs,AJs,KQs,QJs,AJo,77,AQs,99,88,A9s,KQo
Sample,60,CALL_3_BET,UTG1,CO,QTs,JTs,KTs,KJs,TT,AQo,K9s,ATs,AJs,KQs,QJs,AJo,77,AQs,99,88,A9s,KQo
Sample,100,CALL_3_BET,UTG1,CO,QTs,JTs,KTs,KJs,TT,AQo,K9s,ATs,AJs,KQs,QJs,AJo,77,AQs,99,88,A9s,KQo
Sample,100,CALL_3_BET,CO,BTN,66,KJs,KTs,TT,AQo,K9s,A5s,T9s,Q9s,AJo,77,99,A9s,QTs,JTs,44,ATs,A7s,KQs,AJs,QJs,55,J9s,A8s,AQs,A6s,88,KQo
Sample,100,CALL_3_BET,UTG1,BTN,QTs,JTs,66,KTs,KJs,TT,AQo,K9s,ATs,AJs,KQs,QJs,J9s,Q9s,T9s,AJo,77,AQs,99,88,A9s,KQo,ATo
Sample,100,CALL_3_BET,MP1,BTN,QTs,JTs,KTs,KJs,TT,AQo,ATs,QJs,KQs,AJs,AQs,77,AJo,99,88,A9s,KQo
Sample,100,CALL_3_BET,MP1,CO,QTs,JTs,66,KTs,KJs,TT,K9s,AQo,ATs,QJs,KQs,AJs,J9s,T9s,Q9s,AQs,77,AJo,99,88,A9s,KQo
Sample,60,CALL_3_BET,UTG1,BTN,QTs,JTs,KJs,KTs,66,TT,K9s,AQo,ATs,QJs,KQs,AJs,J9s,T9s,Q9s,AQs,77,AJo,99,88,A9s,KQo,ATo
Sample,40,CALL_3_BET,UTG1,BTN,QTs,JTs,KJs,KTs,66,TT,K9s,AQo,ATs,QJs,KQs,AJs,J9s,T9s,Q9s,AQs,77,AJo,99,88,A9s,KQo,ATo
Sample,25,CALL_3_BET,UTG1,BTN,QTs,JTs,KJs,KTs,66,TT,K9s,AQo,ATs,QJs,KQs,AJs,J9s,T9s,Q9s,AQs,77,AJo,99,88,A9s,KQo,ATo
Sample,15,CALL_3_BET,UTG1,BTN,QTs,JTs,KJs,KTs,66,TT,K9s,AQo,ATs,QJs,KQs,AJs,J9s,T9s,Q9s,AQs,77,AJo,99,88,A9s,KQo,ATo
Sample,15,CALL_3_BET,UTG1,SB,QTs,JTs,66,KTs,KJs,TT,AQo,K9s,ATs,AJs,KQs,QJs,J9s,A8s,Q9s,T9s,AQs,AJo,77,99,88,A9s,KQo,ATo
Sample,60,CALL_3_BET,UTG1,SB,QTs,JTs,KJs,KTs,66,TT,AQo,K9s,ATs,AJs,KQs,QJs,J9s,A8s,T9s,Q9s,AQs,77,AJo,99,88,A9s,KQo,ATo
Sample,40,CALL_3_BET,UTG1,SB,QTs,JTs,KJs,KTs,66,TT,AQo,K9s,ATs,AJs,KQs,QJs,J9s,A8s,T9s,Q9s,AQs,77,AJo,99,88,A9s,KQo,ATo
Sample,25,CALL_3_BET,UTG1,SB,QTs,JTs,KJs,KTs,66,TT,AQo,K9s,ATs,AJs,KQs,QJs,J9s,A8s,T9s,Q9s,AQs,77,AJo,99,88,A9s,KQo,ATo
Sample,15,CALL_3_BET,MP1,BB,QTs,JTs,KJs,KTs,66,TT,AQo,K9s,ATs,AJs,KQs,QJs,J9s,A8s,T9s,Q9s,AQs,77,AJo,99,88,A9s,KQo,ATo
Sample,15,CALL_3_BET,MP1,SB,QTs,JTs,66,KTs,KJs,TT,AQo,K9s,ATs,AJs,KQs,QJs,J9s,A8s,Q9s,T9s,AQs,AJo,77,99,88,A9s,KQo,ATo
Sample,15,CALL_3_BET,UTG1,BB,QTs,JTs,KJs,KTs,66,TT,AQo,K9s,ATs,AJs,KQs,QJs,J9s,A8s,T9s,Q9s,AQs,77,AJo,99,88,A9s,KQo,ATo
Sample,25,CALL_3_BET,UTG1,BB,QTs,JTs,KJs,KTs,66,TT,AQo,K9s,ATs,AJs,KQs,QJs,J9s,A8s,T9s,Q9s,AQs,77,AJo,99,88,A9s,KQo,ATo
Sample,40,CALL_3_BET,UTG1,BB,QTs,JTs,KJs,KTs,66,TT,AQo,K9s,ATs,AJs,KQs,QJs,J9s,A8s,T9s,Q9s,AQs,77,AJo,99,88,A9s,KQo,ATo
Sample,60,CALL_3_BET,UTG1,BB,QTs,JTs,KJs,KTs,66,TT,AQo,K9s,ATs,AJs,KQs,QJs,J9s,A8s,T9s,Q9s,AQs,77,AJo,99,88,A9s,KQo,ATo
Sample,100,CALL_3_BET,UTG1,SB,QTs,JTs,66,KTs,KJs,TT,AQo,K9s,ATs,AJs,KQs,QJs,J9s,A8s,Q9s,T9s,AQs,AJo,77,99,88,A9s,KQo,ATo
Sample,15,CALL_3_BET,MP1,BTN,QTs,JTs,KTs,KJs,TT,AQo,ATs,QJs,KQs,AJs,AQs,77,AJo,99,88,A9s,KQo
Sample,15,CALL_3_BET,MP1,CO,QTs,JTs,KTs,KJs,TT,AQo,ATs,QJs,KQs,AJs,AQs,77,AJo,99,88,A9s,KQo
Sample,15,CALL_3_BET,MP1,MP3,QTs,JTs,KTs,KJs,TT,AQo,ATs,QJs,KQs,AJs,AQs,77,AJo,99,88,A9s,KQo
Sample,60,CALL_3_BET,MP1,MP2,QTs,JTs,KTs,KJs,TT,AQo,ATs,QJs,KQs,AJs,AQs,77,AJo,99,88,A9s,KQo
Sample,40,CALL_3_BET,MP1,MP2,QTs,JTs,KTs,KJs,TT,AQo,ATs,QJs,KQs,AJs,AQs,77,AJo,99,88,A9s,KQo
Sample,25,CALL_3_BET,MP1,MP2,QTs,JTs,KTs,KJs,TT,AQo,ATs,QJs,KQs,AJs,AQs,77,AJo,99,88,A9s,KQo
Sample,25,CALL_3_BET,MP1,MP3,QTs,JTs,KTs,KJs,TT,AQo,ATs,QJs,KQs,AJs,AQs,77,AJo,99,88,A9s,KQo
Sample,40,CALL_3_BET,MP1,MP3,QTs,JTs,KTs,KJs,TT,AQo,ATs,QJs,KQs,AJs,AQs,77,AJo,99,88,A9s,KQo
Sample,60,CALL_3_BET,MP1,MP3,QTs,JTs,KTs,KJs,TT,AQo,ATs,QJs,KQs,AJs,AQs,77,AJo,99,88,A9s,KQo
Sample,100,CALL_3_BET,MP1,MP3,QTs,JTs,KTs,KJs,TT,AQo,ATs,QJs,KQs,AJs,AQs,77,AJo,99,88,A9s,KQo
Sample,60,CALL_3_BET,MP1,CO,QTs,JTs,KTs,KJs,TT,AQo,ATs,QJs,KQs,AJs,AQs,77,AJo,99,88,A9s,KQo
Sample,40,CALL_3_BET,MP1,CO,QTs,JTs,KTs,KJs,TT,AQo,ATs,QJs,KQs,AJs,AQs,77,AJo,99,88,A9s,KQo
Sample,25,CALL_3_BET,MP1,CO,QTs,JTs,KTs,KJs,TT,AQo,ATs,QJs,KQs,AJs,AQs,77,AJo,99,88,A9s,KQo
Sample,25,CALL_3_BET,MP1,BTN,QTs,JTs,KTs,KJs,TT,AQo,ATs,QJs,KQs,AJs,AQs,77,AJo,99,88,A9s,KQo
Sample,40,CALL_3_BET,MP1,BTN,QTs,JTs,KTs,KJs,TT,AQo,ATs,QJs,KQs,AJs,AQs,77,AJo,99,88,A9s,KQo
Sample,60,CALL_3_BET,MP1,BTN,QTs,JTs,KTs,KJs,TT,AQo,ATs,QJs,KQs,AJs,AQs,77,AJo,99,88,A9s,KQo
Sample,100,CALL_3_BET,MP2,SB,QTs,JTs,KTs,KJs,66,TT,AQo,K9s,ATs,QJs,KQs,AJs,J9s,55,Q9s,T9s,AQs,77,AJo,99,88,A9s,98s,KQo
Sample,100,CALL_3_BET,MP1,SB,KJs,KTs,66,TT,K9s,AQo,T9s,Q9s,77,AJo,99,A9s,ATo,QTs,JTs,ATs,QJs,KQs,AJs,55,J9s,A8s,AQs,88,KQo
Sample,60,CALL_3_BET,MP1,SB,QTs,JTs,66,KTs,KJs,TT,AQo,K9s,ATs,AJs,KQs,QJs,J9s,A8s,Q9s,T9s,AQs,AJo,77,99,88,A9s,KQo,ATo
Sample,40,CALL_3_BET,MP1,SB,QTs,JTs,66,KTs,KJs,TT,AQo,K9s,ATs,AJs,KQs,QJs,J9s,A8s,Q9s,T9s,AQs,AJo,77,99,88,A9s,KQo,ATo
Sample,25,CALL_3_BET,MP1,SB,QTs,JTs,66,KTs,KJs,TT,AQo,K9s,ATs,AJs,KQs,QJs,J9s,A8s,Q9s,T9s,AQs,AJo,77,99,88,A9s,KQo,ATo
Sample,25,CALL_3_BET,MP1,BB,QTs,JTs,KJs,KTs,66,TT,AQo,K9s,ATs,AJs,KQs,QJs,J9s,A8s,T9s,Q9s,AQs,77,AJo,99,88,A9s,KQo,ATo
Sample,40,CALL_3_BET,MP1,BB,QTs,JTs,KJs,KTs,66,TT,AQo,K9s,ATs,AJs,KQs,QJs,J9s,A8s,T9s,Q9s,AQs,77,AJo,99,88,A9s,KQo,ATo
Sample,60,CALL_3_BET,MP1,BB,QTs,JTs,KJs,KTs,66,TT,AQo,K9s,ATs,AJs,KQs,QJs,J9s,A8s,T9s,Q9s,AQs,77,AJo,99,88,A9s,KQo,ATo
Sample,100,CALL_3_BET,MP1,BB,QTs,JTs,KJs,KTs,66,TT,AQo,K9s,ATs,AJs,KQs,QJs,J9s,A8s,T9s,Q9s,AQs,77,AJo,99,88,A9s,KQo,ATo
Sample,15,CALL_3_BET,MP2,BB,QTs,JTs,KTs,KJs,66,TT,AQo,K9s,ATs,QJs,KQs,AJs,J9s,55,Q9s,T9s,AQs,77,AJo,99,88,A9s,98s,KQo
Sample,25,CALL_3_BET,MP2,MP3,QTs,JTs,KJs,KTs,TT,AQo,ATs,AJs,KQs,QJs,AJo,77,AQs,99,88,A9s,KQo
Sample,40,CALL_3_BET,MP2,MP3,QTs,JTs,KJs,KTs,TT,AQo,ATs,AJs,KQs,QJs,AJo,77,AQs,99,88,A9s,KQo
Sample,60,CALL_3_BET,MP2,MP3,QTs,JTs,KJs,KTs,TT,AQo,ATs,AJs,KQs,QJs,AJo,77,AQs,99,88,A9s,KQo
Sample,100,CALL_3_BET,MP2,CO,QTs,JTs,66,KJs,KTs,TT,K9s,AQo,ATs,AJs,KQs,QJs,J9s,55,T9s,Q9s,AJo,77,AQs,99,88,A9s,KQo
Sample,60,CALL_3_BET,MP2,CO,QTs,JTs,66,KJs,KTs,TT,AQo,K9s,ATs,QJs,KQs,AJs,J9s,55,T9s,Q9s,AJo,77,AQs,99,88,A9s,KQo
Sample,40,CALL_3_BET,MP2,CO,QTs,JTs,66,KJs,KTs,TT,AQo,K9s,ATs,QJs,KQs,AJs,J9s,55,T9s,Q9s,AJo,77,AQs,99,88,A9s,KQo
Sample,25,CALL_3_BET,MP2,CO,QTs,JTs,66,KJs,KTs,TT,AQo,K9s,ATs,QJs,KQs,AJs,J9s,55,T9s,Q9s,AJo,77,AQs,99,88,A9s,KQo
Sample,15,CALL_3_BET,MP2,CO,QTs,JTs,66,KJs,KTs,TT,AQo,K9s,ATs,QJs,KQs,AJs,J9s,55,T9s,Q9s,AJo,77,AQs,99,88,A9s,KQo
Sample,15,CALL_3_BET,MP2,BTN,QTs,JTs,66,KJs,KTs,TT,AQo,K9s,ATs,QJs,KQs,AJs,J9s,55,T9s,Q9s,AJo,77,AQs,99,88,A9s,98s,KQo
Sample,25,CALL_3_BET,MP2,BTN,QTs,JTs,KTs,KJs,66,TT,AQo,K9s,ATs,QJs,KQs,AJs,J9s,55,Q9s,T9s,AQs,77,AJo,99,88,A9s,98s,KQo
Sample,40,CALL_3_BET,MP2,BTN,QTs,JTs,KTs,KJs,66,TT,AQo,K9s,ATs,QJs,KQs,AJs,J9s,55,Q9s,T9s,AQs,77,AJo,99,88,A9s,98s,KQo
Sample,60,CALL_3_BET,MP2,BTN,QTs,JTs,KTs,KJs,66,TT,AQo,K9s,ATs,QJs,KQs,AJs,J9s,55,Q9s,T9s,AQs,77,AJo,99,88,A9s,98s,KQo
Sample,100,CALL_3_BET,MP2,BTN,QTs,JTs,KTs,KJs,66,TT,AQo,K9s,ATs,QJs,KQs,AJs,J9s,55,Q9s,T9s,AQs,77,AJo,99,88,A9s,98s,KQo
Sample,60,CALL_3_BET,MP2,SB,QTs,JTs,KTs,KJs,66,TT,AQo,K9s,ATs,QJs,KQs,AJs,J9s,55,Q9s,T9s,AQs,77,AJo,99,88,A9s,98s,KQo
Sample,40,CALL_3_BET,MP2,SB,QTs,JTs,KTs,KJs,66,TT,AQo,K9s,ATs,QJs,KQs,AJs,J9s,55,Q9s,T9s,AQs,77,AJo,99,88,A9s,98s,KQo
Sample,25,CALL_3_BET,MP2,SB,QTs,JTs,KTs,KJs,66,TT,AQo,K9s,ATs,QJs,KQs,AJs,J9s,55,Q9s,T9s,AQs,77,AJo,99,88,A9s,98s,KQo
Sample,15,CALL_3_BET,MP2,SB,QTs,JTs,KTs,KJs,66,TT,AQo,K9s,ATs,QJs,KQs,AJs,J9s,55,Q9s,T9s,AQs,77,AJo,99,88,A9s,98s,KQo
Sample,25,CALL_3_BET,MP2,BB,QTs,JTs,KTs,KJs,66,TT,AQo,K9s,ATs,QJs,KQs,AJs,J9s,55,Q9s,T9s,AQs,77,AJo,99,88,A9s,98s,KQo
Sample,40,CALL_3_BET,MP2,BB,QTs,JTs,KTs,KJs,66,TT,AQo,K9s,ATs,QJs,KQs,AJs,J9s,55,Q9s,T9s,AQs,77,AJo,99,88,A9s,98s,KQo
Sample,60,CALL_3_BET,MP2,BB,QTs,JTs,KTs,KJs,66,TT,AQo,K9s,ATs,QJs,KQs,AJs,J9s,55,Q9s,T9s,AQs,77,AJo,99,88,A9s,98s,KQo
Sample,100,CALL_3_BET,MP2,BB,QTs,JTs,KTs,KJs,66,TT,AQo,K9s,ATs,QJs,KQs,AJs,J9s,55,Q9s,T9s,AQs,77,AJo,99,88,A9s,98s,KQo
Sample,60,CALL_3_BET,MP3,CO,66,KJs,KTs,TT,AQo,K9s,A5s,87s,T9s,Q9s,AJo,77,99,A9s,98s,ATo,QTs,JTs,44,ATs,A7s,QJs,KQs,AJs,J9s,55,A8s,AQs,A6s,88,KQo,76s
Sample,40,CALL_3_BET,MP3,CO,66,KJs,KTs,TT,AQo,K9s,A5s,87s,T9s,Q9s,AJo,77,99,A9s,98s,ATo,QTs,JTs,44,ATs,A7s,QJs,KQs,AJs,J9s,55,A8s,AQs,A6s,88,KQo,76s
Sample,25,CALL_3_BET,MP3,CO,66,KJs,KTs,TT,AQo,K9s,A5s,87s,T9s,Q9s,AJo,77,99,A9s,98s,ATo,QTs,JTs,44,ATs,A7s,QJs,KQs,AJs,J9s,55,A8s,AQs,A6s,88,KQo,76s
Sample,15,CALL_3_BET,MP3,BTN,66,KJs,KTs,TT,AQo,K9s,A5s,87s,T9s,Q9s,AJo,77,99,A9s,98s,ATo,QTs,JTs,44,ATs,A7s,QJs,KQs,AJs,J9s,55,A8s,AQs,A6s,88,KQo,76s
Sample,25,CALL_3_BET,MP3,BTN,66,KJs,KTs,TT,AQo,K9s,A5s,87s,T9s,Q9s,AJo,77,99,A9s,98s,ATo,QTs,JTs,44,ATs,A7s,QJs,KQs,AJs,J9s,55,A8s,AQs,A6s,88,KQo,76s
Sample,40,CALL_3_BET,MP3,BTN,66,KJs,KTs,TT,AQo,K9s,A5s,87s,T9s,Q9s,AJo,77,99,A9s,98s,ATo,QTs,JTs,44,ATs,A7s,QJs,KQs,AJs,J9s,55,A8s,AQs,A6s,88,KQo,76s
Sample,60,CALL_3_BET,MP3,BTN,66,KJs,KTs,TT,AQo,K9s,A5s,87s,T9s,Q9s,AJo,77,99,A9s,98s,ATo,QTs,JTs,44,ATs,A7s,QJs,KQs,AJs,J9s,55,A8s,AQs,A6s,88,KQo,76s
Sample,100,CALL_3_BET,MP3,BTN,66,KJs,KTs,TT,AQo,K9s,A5s,87s,T9s,Q9s,AJo,77,99,A9s,98s,ATo,QTs,JTs,44,ATs,A7s,QJs,KQs,AJs,J9s,55,A8s,AQs,A6s,88,KQo,76s
Sample,100,CALL_3_BET,MP3,SB,66,KJs,KTs,TT,AQo,K9s,A5s,87s,T9s,Q9s,KJo,AJo,77,99,A9s,98s,ATo,QTs,JTs,44,ATs,A7s,QJs,KQs,AJs,J9s,55,A8s,AQs,A6s,88,KQo,76s
Sample,60,CALL_3_BET,MP3,SB,66,KJs,KTs,TT,K9s,AQo,A5s,87s,T9s,Q9s,AJo,77,KJo,99,A9s,98s,ATo,QTs,JTs,44,A7s,ATs,QJs,AJs,KQs,J9s,55,A8s,AQs,A6s,88,KQo,76s
Sample,40,CALL_3_BET,MP3,SB,66,KJs,KTs,TT,K9s,AQo,A5s,87s,T9s,Q9s,AJo,77,KJo,99,A9s,98s,ATo,QTs,JTs,44,A7s,ATs,QJs,AJs,KQs,J9s,55,A8s,AQs,A6s,88,KQo,76s
Sample,25,CALL_3_BET,MP3,SB,66,KJs,KTs,TT,K9s,AQo,A5s,87s,T9s,Q9s,AJo,77,KJo,99,A9s,98s,ATo,QTs,JTs,44,A7s,ATs,QJs,AJs,KQs,J9s,55,A8s,AQs,A6s,88,KQo,76s
Sample,15,CALL_3_BET,MP3,SB,66,KJs,KTs,TT,K9s,AQo,A5s,87s,T9s,Q9s,AJo,77,KJo,99,A9s,98s,ATo,QTs,JTs,44,A7s,ATs,QJs,AJs,KQs,J9s,55,A8s,AQs,A6s,88,KQo,76s
Sample,15,CALL_3_BET,MP3,BB,66,KJs,KTs,TT,AQo,K9s,A5s,87s,T9s,Q9s,KJo,AJo,77,99,A9s,98s,ATo,QTs,JTs,44,ATs,A7s,QJs,KQs,AJs,J9s,55,A8s,AQs,A6s,88,KQo,76s
Sample,60,CALL_3_BET,MP3,BB,66,KJs,KTs,TT,K9s,AQo,A5s,87s,T9s,Q9s,AJo,77,KJo,99,A9s,98s,ATo,QTs,JTs,44,A7s,ATs,QJs,AJs,KQs,J9s,55,A8s,AQs,A6s,88,KQo,76s
Sample,40,CALL_3_BET,MP3,BB,66,KJs,KTs,TT,K9s,AQo,A5s,87s,T9s,Q9s,AJo,77,KJo,99,A9s,98s,ATo,QTs,JTs,44,A7s,ATs,QJs,AJs,KQs,J9s,55,A8s,AQs,A6s,88,KQo,76s
Sample,25,CALL_3_BET,MP3,BB,66,KJs,KTs,TT,K9s,AQo,A5s,87s,T9s,Q9s,AJo,77,KJo,99,A9s,98s,ATo,QTs,JTs,44,A7s,ATs,QJs,AJs,KQs,J9s,55,A8s,AQs,A6s,88,KQo,76s
Sample,25,CALL_3_BET,CO,BTN,66,KJs,KTs,TT,AQo,K9s,A5s,T9s,Q9s,AJo,77,99,A9s,QTs,JTs,44,ATs,A7s,KQs,AJs,QJs,55,J9s,A8s,AQs,A6s,88,KQo
Sample,40,CALL_3_BET,CO,BTN,66,KJs,KTs,TT,AQo,K9s,A5s,T9s,Q9s,AJo,77,99,A9s,QTs,JTs,44,ATs,A7s,KQs,AJs,QJs,55,J9s,A8s,AQs,A6s,88,KQo
Sample,60,CALL_3_BET,CO,BTN,66,KJs,KTs,TT,AQo,K9s,A5s,T9s,Q9s,AJo,77,99,A9s,QTs,JTs,44,ATs,A7s,KQs,AJs,QJs,55,J9s,A8s,AQs,A6s,88,KQo
Sample,100,CALL_3_BET,CO,SB,66,KJs,KTs,TT,AQo,K9s,87s,A5s,T9s,Q9s,77,AJo,99,A9s,98s,ATo,QTs,JTs,44,ATs,A7s,KQs,AJs,QJs,55,J9s,A8s,AQs,A6s,88,KQo
Sample,60,CALL_3_BET,CO,SB,66,KJs,KTs,TT,K9s,AQo,87s,A5s,T9s,Q9s,77,AJo,99,A9s,98s,ATo,QTs,JTs,44,A7s,ATs,QJs,AJs,KQs,55,J9s,A8s,AQs,A6s,88,KQo
Sample,40,CALL_3_BET,CO,SB,66,KJs,KTs,TT,K9s,AQo,87s,A5s,T9s,Q9s,77,AJo,99,A9s,98s,ATo,QTs,JTs,44,A7s,ATs,QJs,AJs,KQs,55,J9s,A8s,AQs,A6s,88,KQo
Sample,25,CALL_3_BET,CO,SB,66,KJs,KTs,TT,K9s,AQo,87s,A5s,T9s,Q9s,77,AJo,99,A9s,98s,ATo,QTs,JTs,44,A7s,ATs,QJs,AJs,KQs,55,J9s,A8s,AQs,A6s,88,KQo
Sample,15,CALL_3_BET,CO,SB,66,KJs,KTs,TT,K9s,AQo,87s,A5s,T9s,Q9s,77,AJo,99,A9s,98s,ATo,QTs,JTs,44,A7s,ATs,QJs,AJs,KQs,55,J9s,A8s,AQs,A6s,88,KQo
Sample,15,CALL_3_BET,CO,BB,66,KJs,KTs,TT,K9s,AQo,87s,A5s,T9s,Q9s,77,AJo,99,A9s,98s,ATo,QTs,JTs,44,A7s,ATs,QJs,AJs,KQs,55,J9s,A8s,AQs,A6s,88,KQo
Sample,25,CALL_3_BET,CO,BB,66,KJs,KTs,TT,K9s,AQo,87s,A5s,T9s,Q9s,77,AJo,99,A9s,98s,ATo,QTs,JTs,44,A7s,ATs,QJs,AJs,KQs,55,J9s,A8s,AQs,A6s,88,KQo
Sample,40,CALL_3_BET,CO,BB,66,KJs,KTs,TT,K9s,AQo,87s,A5s,T9s,Q9s,77,AJo,99,A9s,98s,ATo,QTs,JTs,44,A7s,ATs,QJs,AJs,KQs,55,J9s,A8s,AQs,A6s,88,KQo
Sample,60,CALL_3_BET,CO,BB,66,KJs,KTs,TT,K9s,AQo,87s,A5s,T9s,Q9s,77,AJo,99,A9s,98s,ATo,QTs,JTs,44,A7s,ATs,QJs,AJs,KQs,55,J9s,A8s,AQs,A6s,88,KQo
Sample,100,CALL_3_BET,CO,BB,66,KJs,KTs,TT,K9s,AQo,87s,A5s,T9s,Q9s,77,AJo,99,A9s,98s,ATo,QTs,JTs,44,A7s,ATs,QJs,AJs,KQs,55,J9s,A8s,AQs,A6s,88,KQo
Sample,60,CALL_3_BET,BTN,SB,QTs,JTs,KTs,66,44,AQo,K9s,A7s,87s,A5s,55,J9s,A8s,Q9s,T9s,AJo,KJo,A6s,A9s,98s,KQo,65s,76s,ATo
Sample,40,CALL_3_BET,BTN,SB,QTs,JTs,KTs,66,44,AQo,K9s,A7s,87s,A5s,55,J9s,A8s,Q9s,T9s,AJo,KJo,A6s,A9s,98s,KQo,65s,76s,ATo
Sample,25,CALL_3_BET,BTN,SB,QTs,JTs,KTs,66,44,AQo,K9s,A7s,87s,A5s,55,J9s,A8s,Q9s,T9s,AJo,KJo,A6s,A9s,98s,KQo,65s,76s,ATo
Sample,15,CALL_3_BET,BTN,BB,QTs,JTs,KTs,66,44,AQo,K9s,A7s,87s,A5s,55,J9s,A8s,Q9s,T9s,AJo,KJo,A6s,A9s,98s,KQo,ATo
Sample,60,CALL_3_BET,BTN,BB,QTs,JTs,KTs,66,44,K9s,AQo,A7s,A5s,87s,J9s,55,A8s,Q9s,T9s,AJo,KJo,A6s,A9s,98s,KQo,ATo
Sample,100,CALL_3_BET,BTN,BB,QTs,JTs,KTs,66,44,K9s,AQo,A7s,A5s,87s,J9s,55,A8s,Q9s,T9s,AJo,KJo,A6s,A9s,98s,KQo,ATo
Sample,40,CALL_3_BET,BTN,BB,QTs,JTs,KTs,66,44,K9s,AQo,A7s,A5s,87s,J9s,55,A8s,Q9s,T9s,AJo,KJo,A6s,A9s,98s,KQo,ATo
Sample,25,CALL_3_BET,BTN,BB,QTs,JTs,KTs,66,44,K9s,AQo,A7s,A5s,87s,J9s,55,A8s,Q9s,T9s,AJo,KJo,A6s,A9s,98s,KQo,ATo
Sample,25,CALL_3_BET,SB,BB,Q7s,K3s,A2s,J9o,J7s,87s,K5s,Q9s,T9s,JTo,33,Q8o,A4o,22,98s,65s,A3o,J8s,A7s,K6o,55,T7s,K8s,K9o,A8o,A9o,K6s,76s,K2s,K8o,A4s,Q9o,QTo,K9s,A5s,T9o,Q5s,Q6s,K4s,A5o,K5o,K7s,44,A3s,A6o,J9s,A2o,A8s,K7o,Q8s,A6s,A7o,T8s,97s
Sample,40,CALL_3_BET,SB,BB,Q7s,K3s,A2s,J9o,J7s,87s,K5s,Q9s,T9s,JTo,33,Q8o,A4o,22,98s,65s,A3o,J8s,A7s,K6o,55,T7s,K8s,K9o,A8o,A9o,K6s,76s,K2s,K8o,A4s,Q9o,QTo,K9s,A5s,T9o,Q5s,Q6s,K4s,A5o,K5o,K7s,44,A3s,A6o,J9s,A2o,A8s,K7o,Q8s,A6s,A7o,T8s,97s
Sample,60,CALL_3_BET,SB,BB,Q7s,K3s,A2s,J9o,J7s,87s,K5s,Q9s,T9s,JTo,33,Q8o,A4o,22,98s,65s,A3o,J8s,A7s,K6o,55,T7s,K8s,K9o,A8o,A9o,K6s,76s,K2s,K8o,A4s,Q9o,QTo,K9s,A5s,T9o,Q5s,Q6s,K4s,A5o,K5o,K7s,44,A3s,A6o,J9s,A2o,A8s,K7o,Q8s,A6s,A7o,T8s,97s
Sample,100,LIMP,SB,,Q7o,J7o,T7o,97o,87o,Q6o,J6o,T6o,96o,86o,76o,Q5o,J5o,T5o,95o,85o,75o,65o,K4o,Q4o,J4o,64o,54o,K3o,Q3o,J3o,53o,43o,K2o,Q2o,J2o,42o,32o
Sample,60,LIMP,SB,,Q7o,J7o,T7o,97o,87o,Q6o,J6o,T6o,96o,86o,76o,Q5o,J5o,T5o,95o,85o,75o,65o,K4o,Q4o,J4o,64o,54o,K3o,Q3o,J3o,53o,43o,K2o,Q2o,J2o,42o,32o
Sample,40,LIMP,SB,,Q7o,J7o,T7o,97o,87o,Q6o,J6o,T6o,96o,86o,76o,Q5o,J5o,T5o,95o,85o,75o,65o,K4o,Q4o,J4o,64o,54o,K3o,Q3o,J3o,53o,43o,K2o,Q2o,J2o,42o,32o
Sample,100,THREE_BET_FOLD,UTG1,UTG,A9s,A5s,KJs,KTs,AQo,QJs
Sample,100,THREE_BET_FOLD,MP1,UTG,A9s,A5s,KJs,KTs,AQo,QJs
Sample,100,THREE_BET_FOLD,MP2,UTG,A9s,A5s,KJs,KTs,AQo,QJs
Sample,100,THREE_BET_FOLD,MP3,UTG,A9s,A5s,KJs,KTs,AQo,QJs
Sample,100,THREE_BET_FOLD,MP3,UTG1,A9s,A5s,KJs,KTs,AQo,QJs
Sample,100,THREE_BET_FOLD,MP1,UTG1,A9s,A5s,KJs,KTs,AQo,QJs
Sample,100,THREE_BET_FOLD,MP2,UTG1,A9s,A5s,KJs,KTs,AQo,QJs
Sample,100,THREE_BET_FOLD,MP3,MP1,A9s,A5s,KJs,KTs,AQo,QJs
Sample,100,THREE_BET_FOLD,MP2,MP1,A9s,A5s,KJs,KTs,AQo,QJs
Sample,100,THREE_BET_FOLD,MP3,MP2,A9s,A5s,KJs,KTs,AQo,QJs
Sample,100,THREE_BET_FOLD,CO,UTG,ATs,A8s,A5s,A4s,KJs,KTs,K9s,AQo,KQo,QTs
Sample,100,THREE_BET_FOLD,CO,UTG1,ATs,A8s,A5s,A4s,KJs,KTs,K9s,AQo,KQo,QTs
Sample,100,THREE_BET_FOLD,BTN,UTG,ATs,A9s,A5s,A4s,KJs,KTs,K9s,KQo,QJs,AJo,ATo,87s,76s
Sample,100,THREE_BET_FOLD,CO,MP1,ATs,A8s,A5s,A4s,KJs,KTs,K9s,AQo,KQo,QTs
Sample,100,THREE_BET_FOLD,CO,MP2,ATs,A8s,A5s,A4s,KJs,KTs,K9s,AQo,KQo,QTs
Sample,100,THREE_BET_FOLD,CO,MP3,ATs,A8s,A5s,A4s,KJs,KTs,K9s,AQo,KQo,QTs
Sample,100,THREE_BET_FOLD,BTN,UTG1,ATs,A9s,A5s,A4s,KJs,KTs,K9s,KQo,QJs,AJo,ATo,87s,76s
Sample,100,THREE_BET_FOLD,BTN,MP1,ATs,A9s,A5s,A4s,KJs,KTs,K9s,KQo,QJs,AJo,ATo,87s,76s
Sample,100,THREE_BET_FOLD,BTN,MP2,ATs,A9s,A5s,A4s,KJs,KTs,K9s,KQo,QJs,AJo,ATo,87s,76s
Sample,100,THREE_BET_FOLD,BTN,MP3,ATs,A9s,A5s,A4s,KJs,KTs,K9s,KQo,QJs,AJo,ATo,87s,76s
Sample,100,THREE_BET_FOLD,BTN,CO,A9s,A8s,A7s,A6s,A5s,AJo,ATo,A9o,87s,76s
Sample,100,THREE_BET_FOLD,SB,CO,A9s,A6s,A5s,A4s,K9s,K8s,KQo,QTs,Q9s,AJo,KJo,QJo,JTs,J9s,ATo,T9s,87s,76s
Sample,100,THREE_BET_FOLD,SB,UTG,AJs,ATs,A5s,A4s,KQs,KQo
Sample,100,THREE_BET_FOLD,SB,UTG1,AJs,ATs,A5s,A4s,KQs,KQo
Sample,100,THREE_BET_FOLD,SB,MP1,AJs,ATs,A5s,A4s,KQs,KQo
Sample,100,THREE_BET_FOLD,SB,MP2,AJs,ATs,A5s,A4s,KQs,KQo
Sample,100,THREE_BET_FOLD,SB,MP3,AJs,ATs,A5s,A4s,KQs,KQo
Sample,100,THREE_BET_FOLD,SB,BTN,A9s,A6s,A5s,A4s,K9s,K8s,KQo,QTs,Q9s,AJo,KJo,QJo,JTs,J9s,ATo,T9s,87s,76s
Sample,100,THREE_BET_FOLD,BB,BTN,A5s,K5s,Q5s,KJo,J9s,J8s,J7s,ATo,T8s,T7s,T6s,97s,96s,86s,76s,75s,65s,64s,54s,43s
Sample,100,THREE_BET_FOLD,BB,UTG,A8s,A5s,A4s,KQs,KJs,QTs,T9s,87s,76s
Sample,100,THREE_BET_FOLD,BB,SB,A4s,A3s,K4s,K3s,T7s,T6s,97s,96s,95s,86s,85s,75s,74s,64s,54s,43s
Sample,100,THREE_BET_FOLD,BB,UTG1,A8s,A5s,A4s,KQs,KJs,QTs,T9s,87s,76s
Sample,100,THREE_BET_FOLD,BB,MP1,A8s,A5s,A4s,KQs,KJs,QTs,T9s,87s,76s
Sample,100,THREE_BET_FOLD,BB,MP2,A8s,A5s,A4s,KQs,KJs,QTs,T9s,87s,76s
Sample,100,THREE_BET_FOLD,BB,MP3,A8s,A5s,A4s,KQs,KJs,QTs,T9s,87s,76s
Sample,100,THREE_BET_FOLD,BB,CO,A5s,K5s,Q5s,KJo,J9s,J8s,J7s,ATo,T8s,T7s,T6s,97s,96s,86s,76s,75s,65s,64s,54s,43s
Sample,60,THREE_BET_FOLD,UTG1,UTG,A9s,A5s,KJs,KTs,AQo,QJs
Sample,60,THREE_BET_FOLD,MP1,UTG,A9s,A5s,KJs,KTs,AQo,QJs
Sample,60,THREE_BET_FOLD,MP2,UTG,A9s,A5s,KJs,KTs,AQo,QJs
Sample,60,THREE_BET_FOLD,MP3,UTG,A9s,A5s,KJs,KTs,AQo,QJs
Sample,60,THREE_BET_FOLD,MP3,UTG1,A9s,A5s,KJs,KTs,AQo,QJs
Sample,60,THREE_BET_FOLD,MP1,UTG1,A9s,A5s,KJs,KTs,AQo,QJs
Sample,60,THREE_BET_FOLD,MP2,UTG1,A9s,A5s,KJs,KTs,AQo,QJs
Sample,60,THREE_BET_FOLD,MP3,MP1,A9s,A5s,KJs,KTs,AQo,QJs
Sample,60,THREE_BET_FOLD,MP2,MP1,A9s,A5s,KJs,KTs,AQo,QJs
Sample,60,THREE_BET_FOLD,MP3,MP2,A9s,A5s,KJs,KTs,AQo,QJs
Sample,60,THREE_BET_FOLD,CO,UTG,ATs,A8s,A5s,A4s,KJs,KTs,K9s,AQo,KQo,QTs
Sample,60,THREE_BET_FOLD,CO,UTG1,ATs,A8s,A5s,A4s,KJs,KTs,K9s,AQo,KQo,QTs
Sample,60,THREE_BET_FOLD,BTN,UTG,ATs,A9s,A5s,A4s,KJs,KTs,K9s,KQo,QJs,AJo,ATo,87s,76s
Sample,60,THREE_BET_FOLD,CO,MP1,ATs,A8s,A5s,A4s,KJs,KTs,K9s,AQo,KQo,QTs
Sample,60,THREE_BET_FOLD,CO,MP2,ATs,A8s,A5s,A4s,KJs,KTs,K9s,AQo,KQo,QTs
Sample,60,THREE_BET_FOLD,CO,MP3,ATs,A8s,A5s,A4s,KJs,KTs,K9s,AQo,KQo,QTs
Sample,60,THREE_BET_FOLD,BTN,UTG1,ATs,A9s,A5s,A4s,KJs,KTs,K9s,KQo,QJs,AJo,ATo,87s,76s
Sample,60,THREE_BET_FOLD,BTN,MP1,ATs,A9s,A5s,A4s,KJs,KTs,K9s,KQo,QJs,AJo,ATo,87s,76s
Sample,60,THREE_BET_FOLD,BTN,MP2,ATs,A9s,A5s,A4s,KJs,KTs,K9s,KQo,QJs,AJo,ATo,87s,76s
Sample,60,THREE_BET_FOLD,BTN,MP3,ATs,A9s,A5s,A4s,KJs,KTs,K9s,KQo,QJs,AJo,ATo,87s,76s
Sample,60,THREE_BET_FOLD,BTN,CO,A9s,A8s,A7s,A6s,A5s,AJo,ATo,A9o,87s,76s
Sample,60,THREE_BET_FOLD,SB,CO,A9s,A6s,A5s,A4s,K9s,K8s,KQo,QTs,Q9s,AJo,KJo,QJo,JTs,J9s,ATo,T9s,87s,76s
Sample,60,THREE_BET_FOLD,SB,UTG,AJs,ATs,A5s,A4s,KQs,KQo
Sample,60,THREE_BET_FOLD,SB,UTG1,AJs,ATs,A5s,A4s,KQs,KQo
Sample,60,THREE_BET_FOLD,SB,MP1,AJs,ATs,A5s,A4s,KQs,KQo
Sample,60,THREE_BET_FOLD,SB,MP2,AJs,ATs,A5s,A4s,KQs,KQo
Sample,60,THREE_BET_FOLD,SB,MP3,AJs,ATs,A5s,A4s,KQs,KQo
Sample,60,THREE_BET_FOLD,SB,BTN,A9s,A6s,A5s,A4s,K9s,K8s,KQo,QTs,Q9s,AJo,KJo,QJo,JTs,J9s,ATo,T9s,87s,76s
Sample,60,THREE_BET_FOLD,BB,BTN,A5s,K5s,Q5s,KJo,J9s,J8s,J7s,ATo,T8s,T7s,T6s,97s,96s,86s,76s,75s,65s,64s,54s,43s
Sample,60,THREE_BET_FOLD,BB,UTG,A8s,A5s,A4s,KQs,KJs,QTs,T9s,87s,76s
Sample,60,THREE_BET_FOLD,BB,SB,A4s,A3s,K4s,K3s,T7s,T6s,97s,96s,95s,86s,85s,75s,74s,64s,54s,43s
Sample,60,THREE_BET_FOLD,BB,UTG1,A8s,A5s,A4s,KQs,KJs,QTs,T9s,87s,76s
Sample,60,THREE_BET_FOLD,BB,MP1,A8s,A5s,A4s,KQs,KJs,QTs,T9s,87s,76s
Sample,60,THREE_BET_FOLD,BB,MP2,A8s,A5s,A4s,KQs,KJs,QTs,T9s,87s,76s
Sample,60,THREE_BET_FOLD,BB,MP3,A8s,A5s,A4s,KQs,KJs,QTs,T9s,87s,76s
Sample,60,THREE_BET_FOLD,BB,CO,A5s,K5s,Q5s,KJo,J9s,J8s,J7s,ATo,T8s,T7s,T6s,97s,96s,86s,76s,75s,65s,64s,54s,43s
Sample,40,THREE_BET_FOLD,UTG1,UTG,A9s,A5s,KJs,KTs,AQo,QJs
Sample,40,THREE_BET_FOLD,MP1,UTG,A9s,A5s,KJs,KTs,AQo,QJs
Sample,40,THREE_BET_FOLD,MP2,UTG,A9s,A5s,KJs,KTs,AQo,QJs
Sample,40,THREE_BET_FOLD,MP3,UTG,A9s,A5s,KJs,KTs,AQo,QJs
Sample,40,THREE_BET_FOLD,MP3,UTG1,A9s,A5s,KJs,KTs,AQo,QJs
Sample,40,THREE_BET_FOLD,MP1,UTG1,A9s,A5s,KJs,KTs,AQo,QJs
Sample,40,THREE_BET_FOLD,MP2,UTG1,A9s,A5s,KJs,KTs,AQo,QJs
Sample,40,THREE_BET_FOLD,MP3,MP1,A9s,A5s,KJs,KTs,AQo,QJs
Sample,40,THREE_BET_FOLD,MP2,MP1,A9s,A5s,KJs,KTs,AQo,QJs
Sample,40,THREE_BET_FOLD,MP3,MP2,A9s,A5s,KJs,KTs,AQo,QJs
Sample,40,THREE_BET_FOLD,CO,UTG,ATs,A8s,A5s,A4s,KJs,KTs,K9s,AQo,KQo,QTs
Sample,40,THREE_BET_FOLD,CO,UTG1,ATs,A8s,A5s,A4s,KJs,KTs,K9s,AQo,KQo,QTs
Sample,40,THREE_BET_FOLD,BTN,UTG,ATs,A9s,A5s,A4s,KJs,KTs,K9s,KQo,QJs,AJo,ATo,87s,76s
Sample,40,THREE_BET_FOLD,CO,MP1,ATs,A8s,A5s,A4s,KJs,KTs,K9s,AQo,KQo,QTs
Sample,40,THREE_BET_FOLD,CO,MP2,ATs,A8s,A5s,A4s,KJs,KTs,K9s,AQo,KQo,QTs
Sample,40,THREE_BET_FOLD,CO,MP3,ATs,A8s,A5s,A4s,KJs,KTs,K9s,AQo,KQo,QTs
Sample,40,THREE_BET_FOLD,BTN,UTG1,ATs,A9s,A5s,A4s,KJs,KTs,K9s,KQo,QJs,AJo,ATo,87s,76s
Sample,40,THREE_BET_FOLD,BTN,MP1,ATs,A9s,A5s,A4s,KJs,KTs,K9s,KQo,QJs,AJo,ATo,87s,76s
Sample,40,THREE_BET_FOLD,BTN,MP2,ATs,A9s,A5s,A4s,KJs,KTs,K9s,KQo,QJs,AJo,ATo,87s,76s
Sample,40,THREE_BET_FOLD,BTN,MP3,ATs,A9s,A5s,A4s,KJs,KTs,K9s,KQo,QJs,AJo,ATo,87s,76s
Sample,40,THREE_BET_FOLD,BTN,CO,A9s,A8s,A7s,A6s,A5s,AJo,ATo,A9o,87s,76s
Sample,40,THREE_BET_FOLD,SB,CO,A9s,A6s,A5s,A4s,K9s,K8s,KQo,QTs,Q9s,AJo,KJo,QJo,JTs,J9s,ATo,T9s,87s,76s
Sample,40,THREE_BET_FOLD,SB,UTG,AJs,ATs,A5s,A4s,KQs,KQo
Sample,40,THREE_BET_FOLD,SB,UTG1,AJs,ATs,A5s,A4s,KQs,KQo
Sample,40,THREE_BET_FOLD,SB,MP1,AJs,ATs,A5s,A4s,KQs,KQo
Sample,40,THREE_BET_FOLD,SB,MP2,AJs,ATs,A5s,A4s,KQs,KQo
Sample,40,THREE_BET_FOLD,SB,MP3,AJs,ATs,A5s,A4s,KQs,KQo
Sample,40,THREE_BET_FOLD,SB,BTN,A9s,A6s,A5s,A4s,K9s,K8s,KQo,QTs,Q9s,AJo,KJo,QJo,JTs,J9s,ATo,T9s,87s,76s
Sample,40,THREE_BET_FOLD,BB,BTN,A5s,K5s,Q5s,KJo,J9s,J8s,J7s,ATo,T8s,T7s,T6s,97s,96s,86s,76s,75s,65s,64s,54s,43s
Sample,40,THREE_BET_FOLD,BB,UTG,A8s,A5s,A4s,KQs,KJs,QTs,T9s,87s,76s
Sample,40,THREE_BET_FOLD,BB,SB,A4s,A3s,K4s,K3s,T7s,T6s,97s,96s,95s,86s,85s,75s,74s,64s,54s,43s
Sample,40,THREE_BET_FOLD,BB,UTG1,A8s,A5s,A4s,KQs,KJs,QTs,T9s,87s,76s
Sample,40,THREE_BET_FOLD,BB,MP1,A8s,A5s,A4s,KQs,KJs,QTs,T9s,87s,76s
Sample,40,THREE_BET_FOLD,BB,MP2,A8s,A5s,A4s,KQs,KJs,QTs,T9s,87s,76s
Sample,40,THREE_BET_FOLD,BB,MP3,A8s,A5s,A4s,KQs,KJs,QTs,T9s,87s,76s
Sample,40,THREE_BET_FOLD,BB,CO,A5s,K5s,Q5s,KJo,J9s,J8s,J7s,ATo,T8s,T7s,T6s,97s,96s,86s,76s,75s,65s,64s,54s,43s
Sample,100,FOUR_BET_FOLD,SB,BB,T4s,T3s,95s,94s,93s,K8o,Q8o,J8o,T8o,85s,84s,83s,K7o,K6o,K5o
Sample,100,FOUR_BET_FOLD,UTG,BB,A5s,A4s,KTs
Sample,100,FOUR_BET_FOLD,UTG,UTG1,A5s,A4s,KTs
Sample,100,FOUR_BET_FOLD,UTG,MP1,A5s,A4s,KTs
Sample,100,FOUR_BET_FOLD,UTG,MP2,A5s,A4s,KTs
Sample,100,FOUR_BET_FOLD,UTG,MP3,A5s,A4s,KTs
Sample,100,FOUR_BET_FOLD,UTG,CO,A5s,A4s,KTs
Sample,100,FOUR_BET_FOLD,UTG,BTN,A5s,A4s,KTs
Sample,100,FOUR_BET_FOLD,UTG,SB,A5s,A4s,KTs
Sample,100,FOUR_BET_FOLD,UTG1,BB,A5s,A4s,KTs,AQo
Sample,100,FOUR_BET_FOLD,UTG1,MP1,A5s,A4s,KTs,AQo
Sample,100,FOUR_BET_FOLD,UTG1,MP2,A5s,A4s,KTs,AQo
Sample,100,FOUR_BET_FOLD,UTG1,MP3,A5s,A4s,KTs,AQo
Sample,100,FOUR_BET_FOLD,UTG1,CO,A5s,A4s,KTs,AQo
Sample,100,FOUR_BET_FOLD,UTG1,BTN,A5s,A4s,KTs,AQo
Sample,100,FOUR_BET_FOLD,UTG1,SB,A5s,A4s,KTs,AQo
Sample,100,FOUR_BET_FOLD,MP1,BB,A9s,A8s,A5s,A4s,KTs
Sample,100,FOUR_BET_FOLD,MP1,MP2,A9s,A8s,A5s,A4s,KTs
Sample,100,FOUR_BET_FOLD,MP1,MP3,A9s,A8s,A5s,A4s,KTs
Sample,100,FOUR_BET_FOLD,MP1,CO,A9s,A8s,A5s,A4s,KTs
Sample,100,FOUR_BET_FOLD,MP1,BTN,A9s,A8s,A5s,A4s,KTs
Sample,100,FOUR_BET_FOLD,MP1,SB,A9s,A8s,A5s,A4s,KTs
Sample,100,FOUR_BET_FOLD,MP2,BB,A8s,A7s,A6s,A5s,A4s,A3s,A2s,K9s
Sample,100,FOUR_BET_FOLD,MP2,MP3,A8s,A7s,A6s,A5s,A4s,A3s,A2s,K9s
Sample,100,FOUR_BET_FOLD,MP2,CO,A8s,A7s,A6s,A5s,A4s,A3s,A2s,K9s
Sample,100,FOUR_BET_FOLD,MP2,BTN,A8s,A7s,A6s,A5s,A4s,A3s,A2s,K9s
Sample,100,FOUR_BET_FOLD,MP2,SB,A8s,A7s,A6s,A5s,A4s,A3s,A2s,K9s
Sample,100,FOUR_BET_FOLD,MP3,BB,A7s,A6s,A5s,A4s,A3s,A2s,K9s,K8s,KQo,AJo
Sample,100,FOUR_BET_FOLD,MP3,CO,A7s,A6s,A5s,A4s,A3s,A2s,K9s,K8s,KQo,AJo
Sample,100,FOUR_BET_FOLD,MP3,BTN,A7s,A6s,A5s,A4s,A3s,A2s,K9s,K8s,KQo,AJo
Sample,100,FOUR_BET_FOLD,MP3,SB,A7s,A6s,A5s,A4s,A3s,A2s,K9s,K8s,KQo,AJo
Sample,100,FOUR_BET_FOLD,CO,BB,A2s,K9s,K8s,KQo,AJo,ATo
Sample,100,FOUR_BET_FOLD,CO,BTN,A2s,K9s,K8s,KQo,AJo,ATo
Sample,100,FOUR_BET_FOLD,CO,SB,A2s,K9s,K8s,KQo,AJo,ATo
Sample,100,FOUR_BET_FOLD,BTN,BB,A8s,K8s,ATo,A9o,A8o
Sample,100,FOUR_BET_FOLD,BTN,SB,A8s,K8s,ATo,A9o,A8o
Sample,60,FOUR_BET_FOLD,SB,BB,T4s,T3s,95s,94s,93s,K8o,Q8o,J8o,T8o,85s,84s,83s,K7o,K6o,K5o
Sample,60,FOUR_BET_FOLD,UTG,BB,A5s,A4s,KTs
Sample,60,FOUR_BET_FOLD,UTG,UTG1,A5s,A4s,KTs
Sample,60,FOUR_BET_FOLD,UTG,MP1,A5s,A4s,KTs
Sample,60,FOUR_BET_FOLD,UTG,MP2,A5s,A4s,KTs
Sample,60,FOUR_BET_FOLD,UTG,MP3,A5s,A4s,KTs
Sample,60,FOUR_BET_FOLD,UTG,CO,A5s,A4s,KTs
Sample,60,FOUR_BET_FOLD,UTG,BTN,A5s,A4s,KTs
Sample,60,FOUR_BET_FOLD,UTG,SB,A5s,A4s,KTs
Sample,60,FOUR_BET_FOLD,UTG1,BB,A5s,A4s,KTs,AQo
Sample,60,FOUR_BET_FOLD,UTG1,MP1,A5s,A4s,KTs,AQo
Sample,60,FOUR_BET_FOLD,UTG1,MP2,A5s,A4s,KTs,AQo
Sample,60,FOUR_BET_FOLD,UTG1,MP3,A5s,A4s,KTs,AQo
Sample,60,FOUR_BET_FOLD,UTG1,CO,A5s,A4s,KTs,AQo
Sample,60,FOUR_BET_FOLD,UTG1,BTN,A5s,A4s,KTs,AQo
Sample,60,FOUR_BET_FOLD,UTG1,SB,A5s,A4s,KTs,AQo
Sample,60,FOUR_BET_FOLD,MP1,BB,A9s,A8s,A5s,A4s,KTs
Sample,60,FOUR_BET_FOLD,MP1,MP2,A9s,A8s,A5s,A4s,KTs
Sample,60,FOUR_BET_FOLD,MP1,MP3,A9s,A8s,A5s,A4s,KTs
Sample,60,FOUR_BET_FOLD,MP1,CO,A9s,A8s,A5s,A4s,KTs
Sample,60,FOUR_BET_FOLD,MP1,BTN,A9s,A8s,A5s,A4s,KTs
Sample,60,FOUR_BET_FOLD,MP1,SB,A9s,A8s,A5s,A4s,KTs
Sample,60,FOUR_BET_FOLD,MP2,BB,A8s,A7s,A6s,A5s,A4s,A3s,A2s,K9s
Sample,60,FOUR_BET_FOLD,MP2,MP3,A8s,A7s,A6s,A5s,A4s,A3s,A2s,K9s
Sample,60,FOUR_BET_FOLD,MP2,CO,A8s,A7s,A6s,A5s,A4s,A3s,A2s,K9s
Sample,60,FOUR_BET_FOLD,MP2,BTN,A8s,A7s,A6s,A5s,A4s,A3s,A2s,K9s
Sample,60,FOUR_BET_FOLD,MP2,SB,A8s,A7s,A6s,A5s,A4s,A3s,A2s,K9s
Sample,60,FOUR_BET_FOLD,MP3,BB,A7s,A6s,A5s,A4s,A3s,A2s,K9s,K8s,KQo,AJo
Sample,60,FOUR_BET_FOLD,MP3,CO,A7s,A6s,A5s,A4s,A3s,A2s,K9s,K8s,KQo,AJo
Sample,60,FOUR_BET_FOLD,MP3,BTN,A7s,A6s,A5s,A4s,A3s,A2s,K9s,K8s,KQo,AJo
Sample,60,FOUR_BET_FOLD,MP3,SB,A7s,A6s,A5s,A4s,A3s,A2s,K9s,K8s,KQo,AJo
Sample,60,FOUR_BET_FOLD,CO,BB,A2s,K9s,K8s,KQo,AJo,ATo
Sample,60,FOUR_BET_FOLD,CO,BTN,A2s,K9s,K8s,KQo,AJo,ATo
Sample,60,FOUR_BET_FOLD,CO,SB,A2s,K9s,K8s,KQo,AJo,ATo
Sample,60,FOUR_BET_FOLD,BTN,BB,A8s,K8s,ATo,A9o,A8o
Sample,60,FOUR_BET_FOLD,BTN,SB,A8s,K8s,ATo,A9o,A8o
Sample,40,FOUR_BET_FOLD,SB,BB,T4s,T3s,95s,94s,93s,K8o,Q8o,J8o,T8o,85s,84s,83s,K7o,K6o,K5o
Sample,40,FOUR_BET_FOLD,UTG,BB,A5s,A4s,KTs
Sample,40,FOUR_BET_FOLD,UTG,UTG1,A5s,A4s,KTs
Sample,40,FOUR_BET_FOLD,UTG,MP1,A5s,A4s,KTs
Sample,40,FOUR_BET_FOLD,UTG,MP2,A5s,A4s,KTs
Sample,40,FOUR_BET_FOLD,UTG,MP3,A5s,A4s,KTs
Sample,40,FOUR_BET_FOLD,UTG,CO,A5s,A4s,KTs
Sample,40,FOUR_BET_FOLD,UTG,BTN,A5s,A4s,KTs
Sample,40,FOUR_BET_FOLD,UTG,SB,A5s,A4s,KTs
Sample,40,FOUR_BET_FOLD,UTG1,BB,A5s,A4s,KTs,AQo
Sample,40,FOUR_BET_FOLD,UTG1,MP1,A5s,A4s,KTs,AQo
Sample,40,FOUR_BET_FOLD,UTG1,MP2,A5s,A4s,KTs,AQo
Sample,40,FOUR_BET_FOLD,UTG1,MP3,A5s,A4s,KTs,AQo
Sample,40,FOUR_BET_FOLD,UTG1,CO,A5s,A4s,KTs,AQo
Sample,40,FOUR_BET_FOLD,UTG1,BTN,A5s,A4s,KTs,AQo
Sample,40,FOUR_BET_FOLD,UTG1,SB,A5s,A4s,KTs,AQo
Sample,40,FOUR_BET_FOLD,MP1,BB,A9s,A8s,A5s,A4s,KTs
Sample,40,FOUR_BET_FOLD,MP1,MP2,A9s,A8s,A5s,A4s,KTs
Sample,40,FOUR_BET_FOLD,MP1,MP3,A9s,A8s,A5s,A4s,KTs
Sample,40,FOUR_BET_FOLD,MP1,CO,A9s,A8s,A5s,A4s,KTs
Sample,40,FOUR_BET_FOLD,MP1,BTN,A9s,A8s,A5s,A4s,KTs
Sample,40,FOUR_BET_FOLD,MP1,SB,A9s,A8s,A5s,A4s,KTs
Sample,40,FOUR_BET_FOLD,MP2,BB,A8s,A7s,A6s,A5s,A4s,A3s,A2s,K9s
Sample,40,FOUR_BET_FOLD,MP2,MP3,A8s,A7s,A6s,A5s,A4s,A3s,A2s,K9s
Sample,40,FOUR_BET_FOLD,MP2,CO,A8s,A7s,A6s,A5s,A4s,A3s,A2s,K9s
Sample,40,FOUR_BET_FOLD,MP2,BTN,A8s,A7s,A6s,A5s,A4s,A3s,A2s,K9s
Sample,40,FOUR_BET_FOLD,MP2,SB,A8s,A7s,A6s,A5s,A4s,A3s,A2s,K9s
Sample,40,FOUR_BET_FOLD,MP3,BB,A7s,A6s,A5s,A4s,A3s,A2s,K9s,K8s,KQo,AJo
Sample,40,FOUR_BET_FOLD,MP3,CO,A7s,A6s,A5s,A4s,A3s,A2s,K9s,K8s,KQo,AJo
Sample,40,FOUR_BET_FOLD,MP3,BTN,A7s,A6s,A5s,A4s,A3s,A2s,K9s,K8s,KQo,AJo
Sample,40,FOUR_BET_FOLD,MP3,SB,A7s,A6s,A5s,A4s,A3s,A2s,K9s,K8s,KQo,AJo
Sample,40,FOUR_BET_FOLD,CO,BB,A2s,K9s,K8s,KQo,AJo,ATo
Sample,40,FOUR_BET_FOLD,CO,BTN,A2s,K9s,K8s,KQo,AJo,ATo
Sample,40,FOUR_BET_FOLD,CO,SB,A2s,K9s,K8s,KQo,AJo,ATo
Sample,40,FOUR_BET_FOLD,BTN,BB,A8s,K8s,ATo,A9o,A8o
Sample,40,FOUR_BET_FOLD,BTN,SB,A8s,K8s,ATo,A9o,A8o
`