import React, { useState, useCallback, useMemo } from 'react'
import MaoSeletor from './components/MaoSeletor'
import { tablesShove, tablesCall } from './utils'
import './style.css';
import { useCookies } from 'react-cookie'
import { useProfile } from '../../useAuth';
import RandomHot from '../../components/afiliados'
const limiteUsePublic = 10;//sem user
const limiteUseLoged = 20;
export default function Nash() {


    const strCookieDate = "useNash." + new Date().toLocaleDateString();
    const [mesas, setMesas] = useState(["mesa1"])
    const [cookie, setCookie, removeCookie] = useCookies([strCookieDate]);

    const profile = useProfile();



    let limiteUse = limiteUsePublic;
    let dataLimiteShow = null;
    if (profile) {
        // if (profile.emailVerified) {
        limiteUse = limiteUseLoged;
        // }
        if (profile.limitePremiumDate) {

            let dataLimite = new Date(profile.limitePremiumDate);
            dataLimite = new Date(dataLimite.getTime() + dataLimite.getTimezoneOffset() * 60000);//utc

            if (new Date() < dataLimite) {
                limiteUse = -1
                dataLimiteShow = dataLimite.toLocaleDateString();
            } else {

            }

        }
    }



    if (!cookie[strCookieDate]) {
        for (const key in cookie) {
            removeCookie(key)
        }
    }

    let v = cookie[strCookieDate]
    const useNash = useMemo(() => v || 0, [v])

    const addMesa = () => {
        setMesas(mesas.concat(['NovaMesa' + mesas.length]))
    }
    const removeMesa = (index) => {
        let newMesas = mesas.slice();
        newMesas.splice(-1, 1)
        setMesas(newMesas)
    }


    const newUse = useCallback(
        () => {
            let ant = 0;
            if (useNash) {
                ant = parseInt(useNash)
            }

            setCookie(strCookieDate, ant + 1)
        }, [setCookie, strCookieDate, useNash],
    )
    // console.log('useNash', useNash)
    let bloqueia = useNash >= limiteUse && !dataLimiteShow
    let restantes = limiteUse - useNash
    bloqueia = false;
    return (
        <div className="contDivulg">
            <div>
                <RandomHot />
                <RandomHot />
            </div>
            <div className="rootNash">


                {/* <SeletorTipoMesas addMesa={addMesa} removeMesa={removeMesa} restantes={restantes} dataLimiteShow={dataLimiteShow} /> */}
                {/* <div style={{
                height: 100, width: 960, maxHeight:100
                
            }}>
                <AdSense.Google
                    client='ca-pub-4168191540735264'
                    slot='6026605389'
                    style={{ display: 'block' }}
                    format='auto'
                    responsive='true'
                    layoutKey='-gw-1+2a-9x+5c'
                />
            </div> */}
                <div className="mesaView">
                    {mesas.map((m, i) => <MaoSeletor key={i} dbShove={tablesShove} dbCall={tablesCall} newUse={newUse} bloqueia={bloqueia} />)}

                </div>
                {/* <div style={{ height: 100, width: 960 }}>
                <AdSense.Google
                    client='ca-pub-4168191540735264'
                    slot='8835817731'
                    style={{ display: 'block' }}
                    format='auto'
                    responsive='true'
                    layoutKey='-gw-1+2a-9x+5c'
                />
            </div> */}
                {/* <Materia /> */}
            </div>
            {/* <div className='contDivulg2Hide'>
                <RandomHot />
                <RandomHot />
            </div> */}
        </div>
    )
}
