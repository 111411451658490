import React from 'react'

import "./style.css"
import useAuth, { useProfile, useLastSubscription } from '../../useAuth'
import { Typography, Button } from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import StripeSubscriptionButton from '../premium/StripeSubscriptionButton';
export default function Profile() {

    const history = useHistory();
    const { logOut } = useAuth();
    const p = useProfile();
    let sub = useLastSubscription();
    let exibeComprarPremiun = !sub || sub.status !== "active"

    return (
        <div className="profileDiv">
            {p && <>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                    <div>
                        <Typography variant={'h5'}>{p.displayName}</Typography>
                        <Typography variant={'h6'}>{p.email}</Typography>
                    </div>
                    <Button style={{ marginBottom: 20 }}
                        variant='contained' color='primary' onClick={() => {
                            logOut().then(r => {
                                history.push('/')
                            });
                        }}>LogOut</Button>
                </div>

                {sub && <div className="atualSubs">
                    <div>
                        Subscription ID:
                    </div>
                    <div>
                        Start:
                    </div>
                    <div>
                        End:
                    </div>
                    <div>
                        Status:
                    </div>
                    <div>{sub.id}</div>
                    <div>{new Date(sub.current_period_start * 1000).toLocaleDateString()}</div>
                    <div> {new Date(sub.current_period_end * 1000).toLocaleDateString()}</div>
                    <div> {sub.status}</div>
                </div>}
                {exibeComprarPremiun && <StripeSubscriptionButton urlReturn={'http://localhost:3000/profile'} />}
            </>}

        </div>
    )
}
