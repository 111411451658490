import React, { useState,  } from 'react'
import { TextField, Button, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'


var blok=`SharkScope
Login
Preços
PERGUNTAS FREQUENTES
Opções
4 pesquisas grátis restantes. Cadastre-se agora para obter pesquisas e usar recursos

Pesquisa BásicaPesquisa Avançada
 
 
888(NJ)
 
Todos os Jogos
Moeda:R$
	
Jogador	
Filtrar	
História de Lucro	
Contagem	
Lucro Méd	
Stake Méd	
ROI Méd	
Lucro	
Capacidade	
Formulário	
	grahamdesNovas conquistas desbloqueadasPokerStars		Optar participar	1,293	Optar participar	R$4.77	Optar participar	Optar participar	55	
x
Adicionar Outras EstatísticasVisualizar
Grupos de jogadores
grahamdes (PokerStars)
GráficosTorneiosDesmembramentoEstatísticasConquistasEncontrarPublicar
Gráfico Selecionado: 
História de Lucro
Nº de Jogos
Total acumulado (BRL)
Lucro total
Não optou participar
Lucro Excluindo Rake
Lucro
Vitórias Significativas
Estatísticas do Jogador
Encontrar um Torneio
Tournament Selector
SharkScope Desktop & HUD
Aplicativo Móvel SharkScope
Tabelas de Líderes
Tutoriais SharkScope
Guia de SNG
Optar / Optar não participar
Dados de Mercado


RECURSOS
Termos & Condições
SharkScope API
Revendedores
Política de Privacidade
NOTÍCIAS SHARKSCOPE
9 / 27 : SharkScope Poker Tips #276: Iso shoves(9 man hyper) Q. With what range should we...
9 / 24 : SharkScope Poker Tips #275: Aggressive Opponents Q. Is this a reshove given the ...
9 / 21 : SharkScope Poker Tips #274: Reshoving Small Pairs Q. When are low pairs playable...

`
var livre=`SharkScope
Login
Preços
PERGUNTAS FREQUENTES
Opções
3 pesquisas grátis restantes. Cadastre-se agora para obter pesquisas e usar recursos

Pesquisa BásicaPesquisa Avançada
 
 
888(NJ)
 
Todos os Jogos
Moeda:R$
	
Jogador	
Filtrar	
História de Lucro	
Contagem	
Lucro Méd	
Stake Méd	
ROI Méd	
Lucro	
Capacidade	
Formulário	
	cluzziPokerStars		
13,451	R$0.69	R$17.97	3.4%	R$9,336	66	
x
Adicionar Outras EstatísticasVisualizar
Grupos de jogadores
cluzzi (PokerStars)
GráficosTorneiosDesmembramentoEstatísticasTabelas de LíderesConquistasEncontrarPublicar
Gráfico Selecionado: 
História de Lucro
Nº de Jogos
Total acumulado (BRL)
$
$
$
$
$
$
$
$
$
$
Lucro total
Clique e arraste dentro da área para fazer zoom
Lucro Excluindo Rake
Lucro
Vitórias Significativas
0
2k
4k
6k
8k
10k
12k
-4.5K
0
4.5K
9K
13.5K
18K
22.5K
27K
31.5K
36K
Estatísticas do Jogador
Encontrar um Torneio
Tournament Selector
SharkScope Desktop & HUD
Aplicativo Móvel SharkScope
Tabelas de Líderes
Tutoriais SharkScope
Guia de SNG
Optar / Optar não participar
Dados de Mercado


RECURSOS
Termos & Condições
SharkScope API
Revendedores
Política de Privacidade
NOTÍCIAS SHARKSCOPE
9 / 27 : SharkScope Poker Tips #276: Iso shoves(9 man hyper) Q. With what range should we...
9 / 24 : SharkScope Poker Tips #275: Aggressive Opponents Q. Is this a reshove given the ...
9 / 21 : SharkScope Poker Tips #274: Reshoving Small Pairs Q. When are low pairs playable...

`
var bLog=`SharkScope
Logout
Preços
PERGUNTAS FREQUENTES
Opções
Patrick Cavalcanti (Facebook): 9 pesquisas grátis restantes.

Pesquisa BásicaPesquisa Avançada
 
 
888(NJ)
 
Todos os Jogos
Moeda:R$
	
Jogador	
Filtrar	
História de Lucro	
Contagem	
Lucro Méd	
Stake Méd	
ROI Méd	
Lucro	
Capacidade	
Formulário	
	grahamdesPokerStars		Optar participar	1,318	Optar participar	R$4.79	Optar participar	Optar participar	55	
x
Adicionar Outras EstatísticasVisualizar
Grupos de jogadores
grahamdes (PokerStars)
GráficosTorneiosDesmembramentoEstatísticasConquistasEncontrarPublicar
Gráfico Selecionado: 
História de Lucro
Nº de Jogos
Total acumulado (BRL)
Lucro total
Não optou participar
Lucro Excluindo Rake
Lucro
Vitórias Significativas
Estatísticas do Jogador
Encontrar um Torneio
Tournament Selector
SharkScope Desktop & HUD
Aplicativo Móvel SharkScope
Tabelas de Líderes
Tutoriais SharkScope
Guia de SNG
Optar / Optar não participar
Dados de Mercado


RECURSOS
Termos & Condições
SharkScope API
Revendedores
Política de Privacidade
NOTÍCIAS SHARKSCOPE
10 / 4 : SharkScope Poker Tips #277: ATC Shoves(9 man hyper turbo) Q. Fold or shove? A....
10 / 1 : USDT Is A Game Changer For Online Poker CoinPoker - the biggest & oldest pure c...
9 / 27 : SharkScope Poker Tips #276: Iso shoves(9 man hyper) Q. With what range should we...

`
var lLog=`SharkScope
Logout
Preços
PERGUNTAS FREQUENTES
Opções
Patrick Cavalcanti (Facebook): 8 pesquisas grátis restantes.

Pesquisa BásicaPesquisa Avançada
 
 
888(NJ)
 
Todos os Jogos
Moeda:R$
	
Jogador	
Filtrar	
História de Lucro	
Contagem	
Lucro Méd	
Stake Méd	
ROI Méd	
Lucro	
Capacidade	
Formulário	
	PatrickCbjrPokerStars		
1,669	-R$0.22	R$4.35	-9.1%	-R$367	57	
x
Adicionar Outras EstatísticasVisualizar
Grupos de jogadores
PatrickCbjr (PokerStars)
GráficosTorneiosDesmembramentoEstatísticasTabelas de LíderesConquistasEncontrarPublicar
Gráfico Selecionado: 
História de Lucro
Nº de Jogos
Total acumulado (BRL)
$
$
$
$
$
$
$
$
$
$
Lucro total
Clique e arraste dentro da área para fazer zoom
Lucro Excluindo Rake
Lucro
Vitórias Significativas
0
250
500
750
1000
1250
1500
-1.2K
-1,050
-900
-750
-600
-450
-300
-150
0
150
Estatísticas do Jogador
Encontrar um Torneio
Tournament Selector
SharkScope Desktop & HUD
Aplicativo Móvel SharkScope
Tabelas de Líderes
Tutoriais SharkScope
Guia de SNG
Optar / Optar não participar
Dados de Mercado


RECURSOS
Termos & Condições
SharkScope API
Revendedores
Política de Privacidade
NOTÍCIAS SHARKSCOPE
10 / 4 : SharkScope Poker Tips #277: ATC Shoves(9 man hyper turbo) Q. Fold or shove? A....
10 / 1 : USDT Is A Game Changer For Online Poker CoinPoker - the biggest & oldest pure c...
9 / 27 : SharkScope Poker Tips #276: Iso shoves(9 man hyper) Q. With what range should we...

`
function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
};
export default function SharkNote() {
    
    const [note, setNote] = useState("")
    const [userPs, setUserPs] = useState("")
    const url="https://pt.sharkscope.com/#Player-Statistics//networks/PokerStars/players/"
    //grahamdes
    //patrickcbjr
    const parseText = (txt)=>{
        if(!txt){
            return "";
        }
if(!userPs.toUpperCase()){
    return "ERROR\nText from the clipboard does not match the player's name"
} 
        let split = txt.split(/\r?\n/)
        console.log('split',split)
        let linhaPlayer=-1
        split.forEach((l,ind) => {
            if(linhaPlayer===-1){
            if(l.toUpperCase().includes(userPs.toUpperCase())){
                linhaPlayer=ind
            }}
        });
        if(linhaPlayer===-1){
            return "ERROR\nInvalid Text."
        }
        console.log('linhaPlayer',linhaPlayer)
        
        let linhaDados = split[linhaPlayer];
        let headers = []
        for(let i=linhaPlayer-10;i<=linhaPlayer-2;i++){
            headers.push(split[i].trim())
        }
        let valores = []
        if(!linhaDados.toUpperCase().includes(userPs.toUpperCase())){
            return "ERROR\nText from the clipboard does not match the player's name"
        }
        if(split[linhaPlayer+1]==='x'){
            //bloqueado
            let arrDados = linhaDados.split(/\t/)    
            valores[0]=arrDados[1].replace("PokerStars", '').replace("Novas conquistas desbloqueadas", '').trim()
            valores[1]=arrDados[2]
            valores[2]=arrDados[3]
            valores[3]=arrDados[4]
            valores[4]=arrDados[5]
            valores[5]=arrDados[6]
            valores[6]=arrDados[7]
            valores[7]=arrDados[8]
            valores[8]=arrDados[9]

        }else{
            //aberto
            let arrDados = split[linhaPlayer+1].split(/\t/)    
            
            valores[0]=linhaDados.replace("PokerStars", '').replace("Novas conquistas desbloqueadas", '').trim()
            valores[1]=""
            valores[2]=""
            valores[3]=arrDados[0]
            valores[4]=arrDados[1]
            valores[5]=arrDados[2]
            valores[6]=arrDados[3]
            valores[7]=arrDados[4]
            valores[8]=arrDados[5]
        }

        // console.log('headers',headers)
        // console.log('valores',valores)
        headers.splice(1,1)
        headers.splice(1,1)

        valores.splice(1,1)
        valores.splice(1,1)
        headers=array_move(headers,3,2)
        valores=array_move(valores,3,2)

        headers=array_move(headers,6,3)
        valores=array_move(valores,6,3)

        let r="Date: " + new Date().toLocaleDateString() + "\n";
        headers.forEach((v,i)=>{
            r+=v+": "+valores[i]+"\n"
        })
        return r;  
    }
    const generate1 = async (evt) => {

        let txt = bLog
        setNote(parseText(txt))
       
    }
    const generate2 = async (evt) => {

        let txt = lLog
                setNote(parseText(txt))
               
            }

    const generate = async (evt) => {



        const sharkPage = await navigator.clipboard.readText()
        setNote(parseText(sharkPage))
        // try {
        //     // console.log('sharkPage', sharkPage)
        //     let split = sharkPage.split(/\r?\n/)

        //     console.log("split",split);
        //     let start = false;
        //     let startIndex = -1;
        //     let colunas = []
        //     split.forEach((linha, i) => {
        //         if (linha.toUpperCase().includes(userPs.toUpperCase())) {
        //             if (!start) {
        //                 start = true;
        //                 startIndex = i
        //             } else {
        //                 start = false;
        //             }
        //         }
        //         if (start) {
        //             let ls = linha.split(/\t/)
        //             console.log("ls",ls);
        //             ls.forEach((e,i) => {
        //                 if(i>0){
        //                 // if (e) {
        //                     colunas.push(e)
        //                 // }
        //                 }
        //              });
        //         }
        //     })
        //     //grahamdes
        //     //cluzzi
        //     if (startIndex !== -1) {
        //         let headers = []
        //         for (let index = startIndex; index > 0; index--) {
        //             let head = split[index]

        //             if (head.trim() ) {
        //                 headers = [head].concat(headers)
        //             } else {
        //                 break;

        //             }
        //         }
        //         console.log('headers', headers)
        //         console.log('colunas', colunas)

        //         let str = "";
        //         str += "Date: " + new Date().toLocaleDateString() + "\n";
        //         for (let index = 0; index < 8; index++) {
        //             str += headers[index].trim() + (index === 0 || index === 5 ? "" : "") + ": " + colunas[index].replace("PokerStars", '').replace("Novas conquistas desbloqueadas", '').trim() + "\n"
        //         }
        //         str += headers[7].trim() + ": " + colunas[6].replace("PokerStars", '').replace("Novas conquistas desbloqueadas", '').trim() + "\n"

        //         setNote(str)
        //     } else {
        //         setNote("ERROR\nText from the clipboard does not match the player's name")
        //     }
        // } catch (error) {
        //     setNote("ERROR\nText from the clipboard does not match the player's name")
        // }
    }

    const handleFocus = event => {
        event.preventDefault();
        const { target } = event;

        target.focus();
        target.setSelectionRange(0, target.value.length - 1);
    }
    return (
        <div style={{ width: 1000, backgroundColor: "#fff", margin: "0 auto", padding: 15 }}>

            <div style={{ display: "flex" }}>

                <TextField
                    fullWidth
                    label="Player Name"
                    value={userPs}
                    onChange={(evt, v) => setUserPs(evt.target.value)}

                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Button variant='contained' color='primary' style={{ width: 350, marginLeft: 10 }}>
                    <Link to="route" target="_blank" onClick={(event) => { event.preventDefault(); window.open(url + userPs); }} >Search in Sharkscope</Link>
                </Button>

            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 15 }}>

                <Button variant='contained' color='primary' onClick={generate}>Generate Note</Button>
                {/* <Button variant='contained' color='primary' onClick={generate1}>G1</Button>
                <Button variant='contained' color='primary' onClick={generate2}>G2</Button> */}
            </div>

            <TextField
                style={{ paddingBottom: 25 }}
                fullWidth
                label="Note"
                multiline
                rows={10}
                value={note}
                onFocus={handleFocus}
                InputLabelProps={{
                    shrink: true,
                }}
            />


            <div>
                <Typography variant='h6' color='textPrimary'>How use:</Typography>
                <Typography color='textPrimary'>
                    Enter the name of the player and click on "Search in Sharkscope", then when the page of the sharkscope load the data press "CTRL+A" (to select all)
                    and then "CTRL+C" (to copy).
                
                </Typography>
                <Typography color='textPrimary'>
                    Go back to Poker-Nash and click on "Generate Note".
                    Copy the note. ;)
                </Typography>



            </div>
        </div>



    )
}
