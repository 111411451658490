

export const possiveisCartas = ["pAA", "sAK", "sAQ", "sAJ", "sAT", "sA9", "sA8", "sA7", "sA6", "sA5", "sA4", "sA3", "sA2",
    "oAK", "pKK", "sKQ", "sKJ", "sKT", "sK9", "sK8", "sK7", "sK6", "sK5", "sK4", "sK3", "sK2",
    "oAQ", "oKQ", "pQQ", "sQJ", "sQT", "sQ9", "sQ8", "sQ7", "sQ6", "sQ5", "sQ4", "sQ3", "sQ2",
    "oAJ", "oKJ", "oQJ", "pJJ", "sJT", "sJ9", "sJ8", "sJ7", "sJ6", "sJ5", "sJ4", "sJ3", "sJ2",
    "oAT", "oKT", "oQT", "oJT", "pTT", "sT9", "sT8", "sT7", "sT6", "sT5", "sT4", "sT3", "sT2",
    "oA9", "oK9", "oQ9", "oJ9", "oT9", "p99", "s98", "s97", "s96", "s95", "s94", "s93", "s92",
    "oA8", "oK8", "oQ8", "oJ8", "oT8", "o98", "p88", "s87", "s86", "s85", "s84", "s83", "s82",
    "oA7", "oK7", "oQ7", "oJ7", "oT7", "o97", "o87", "p77", "s76", "s75", "s74", "s73", "s72",
    "oA6", "oK6", "oQ6", "oJ6", "oT6", "o96", "o86", "o76", "p66", "s65", "s64", "s63", "s62",
    "oA5", "oK5", "oQ5", "oJ5", "oT5", "o95", "o85", "o75", "o65", "p55", "s54", "s53", "s52",
    "oA4", "oK4", "oQ4", "oJ4", "oT4", "o94", "o84", "o74", "o64", "o54", "p44", "s43", "s42",
    "oA3", "oK3", "oQ3", "oJ3", "oT3", "o93", "o83", "o73", "o63", "o53", "o43", "p33", "s32",
    "oA2", "oK2", "oQ2", "oJ2", "oT2", "o92", "o82", "o72", "o62", "o52", "o42", "o32", "p22"]
