import React from 'react'
import PersonIcon from '@material-ui/icons/Person';
import { Link } from 'react-router-dom';
import { useProfile } from '../../useAuth';
export default function ProfileUp() {

    let profile = useProfile()
    let textBt = "Login"
    let urlBt = "/login"

    if (profile) {
        textBt = (profile.displayName)
        urlBt = "/profile"
    }


    return (

        <Link className='loginDiv' to={urlBt}>
            <PersonIcon />
            {textBt}
        </Link>

    )
} 
