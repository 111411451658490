import React from 'react'
import { Link } from 'react-router-dom'
import ProfileUp from '../ProfileUp'
import './style.css'
import { ReactComponent as Logo } from './poker-cards.svg';
import useAuth, { usePremium } from '../../useAuth';


export default function Nav() {

    let premium = usePremium();
    let { user } = useAuth();
    let email = '';
    if (user())
        email = user().email;

    let showR = false;
    if (email === "patrickvcavalcanti@gmail.com") {
        showR = true;
    }
    if (email === "cluzzi@gmail.com") {
        showR = true;
    }
    return (
        <div className="navbarTop">
            <div className="logoDiv">Poker<Logo className="cardIcon" style={{ margin: 10, height: 35 }} />Nash</div>
            <div className="linksDiv">
                {/* <Link className="menuItem" to="/">Home</Link> */}
                <Link className="menuItem" to="/nash">Nash</Link>
                <Link className="menuItem" to="/sharknote">SharkNote</Link>
                {}
                {showR && <Link className="menuItem" to="/range">Ranges</Link>}
                {/* {premium === null && <Link className="menuItem" to="/premium">Premium</Link>} */}

            </div>
            <ProfileUp />

        </div>
    )
}
