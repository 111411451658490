import React from 'react'

export default function FimUsesFree() {
    return (
        <div style={{backgroundColor:"orange",height:55}}>
            usos free acabou
            
        </div>
    )
}
