import { Card } from '@material-ui/core'
import React from 'react'
import Image from './gto.jpg'
import './style.css'
export default function Afiliado() {
    let link = "https://go.hotmart.com/I41550224V"
    let texto = "Curso de GTO"

    return (
        <div className='contanerHot' >
            <Card className='contanerHot' style={{ height: 325 }}>
                <a href={link} target="_blank"> 
                    <img alt="Link" src={Image} width={290
                    } />
                </a>
                <br />
                <a className='hotText' href={link} target="_blank">
                    {texto}
                </a>
            </Card>
        </div>
    )
}
