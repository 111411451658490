
import { InputLabel, Card, TextField, Switch } from '@material-ui/core';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React, { useEffect, useState } from 'react'
import TabelaSel from './components/TabelaSel';
import { txtCsv } from './db'
const bbPossiveis = [
    { value: '15', label: '15', },
    { value: '25', label: '25', },
    { value: '40', label: '40', },
    { value: '60', label: '60', },
    { value: '100', label: '100', }


];
const posicoesMesa = ["BB", "SB", "BTN", "CO", "MP3", "MP2", "MP1", "UTG1", "UTG"]
export default function Range() {

    const [posicoesMesaVilao, setPosicoesMesaVilao] = useState(posicoesMesa.slice(3, posicoesMesa.length))
    const [bb, setBB] = useState('15')
    const [position, setPosition] = useState("BTN")
    const [vPosition, setVposition] = useState("CO")

    const [nome, setnome] = useState('')
    const [range, setRange] = useState([])
    const [ranges, setRanges] = useState([])
    const [rangesFil, setRangesFil] = useState([])
    // console.log("txtCsv    ", txtCsv);
    const getRange = (str) => {
        let c = str.split(',');


        return c.slice(5)
    }
    let strLinhas = txtCsv.split('\n');
    let aux = strLinhas[1];
    // console.log("strLinhas", strLinhas);
    // console.log("strLinhas",strLinhas[0]);
    let range2 = getRange(strLinhas[1])


    useEffect(() => {
        let c = aux.split(',');
        setnome(c.slice(1, 5).join('-'))
    }, [aux])

    useEffect(() => {
        setRangesFil(ranges.filter(r => {
            return r.position === position && r.bb === bb && (!r.positionVilao || r.positionVilao === vPosition)
        }))

    }, [position, bb, ranges, vPosition])

    useEffect(() => {
        let r = [];
        let bbb = [];
        let strLinhas = txtCsv.split('\n');
        strLinhas.forEach((l, i) => {
            if (i > 0) {
                let linha = l.split(',');

                let rrr = {
                    nome: linha.slice(1, 5).join('-'),
                    range: getRange(l),
                    bb: linha[1],
                    action: linha[2],
                    position: linha[3],
                    positionVilao: linha[4],
                }
                if (!bbb.includes(linha[1])) {
                    bbb.push(linha[1])
                }
                r.push(rrr)
            }
        })
        // console.log("bbb", bbb);
        setRanges(r)
        setRangesFil(r)

    }, [txtCsv])

    const selCarta = (c) => {
        setRange(r => {
            let newArray = r.slice();
            console.log("(c)", c);
            let index = newArray.indexOf(c)
            console.log("(index)", index);
            if (index == -1) {
                newArray.push(c);
            } else {
                newArray.splice(index, 1);

            }
            return newArray;
        })
    }

    const Ran = ({ r }) => {
        return (<div style={{ width: 410, height: 450 }}>
            <InputLabel >{r.action}</InputLabel>
            <TabelaSel range={r.range} />
        </div>
        )

    }
    return (
        <div style={{ width: 1000, backgroundColor: "#fff", margin: "0 auto", padding: 15 }}>
            <div style={{
                display: 'flex',
                justifyContent: "space-between",
                paddingBottom: 15
            }}>
                < div className="inputComp">
                    <InputLabel>Hero Position:</InputLabel>
                    <ToggleButtonGroup
                        style={{ height: 32 }}
                        value={position}
                        exclusive
                        onChange={(evt, v) => {
                            if (v) {
                                setPosition(v)
                                let index = posicoesMesa.indexOf(v);
                                index++;
                                if (index < posicoesMesa.length) {
                                    setVposition(posicoesMesa[index])
                                } else {
                                    setVposition(null)
                                }

                                setPosicoesMesaVilao(posicoesMesa.slice(index, posicoesMesa.length))
                            }
                        }}
                    >
                        {posicoesMesa.map((b) => <ToggleButton style={{ padding: 5 }} key={b} value={b} >
                            {b}
                        </ToggleButton>)}
                    </ToggleButtonGroup>
                </div>
                <div className="inputComp">
                    <InputLabel>BB:</InputLabel>
                    <ToggleButtonGroup
                        style={{ height: 32 }}
                        value={bb}
                        exclusive
                        onChange={(evt, v) => { if (v) setBB(v) }}
                    >
                        {bbPossiveis.map(b => <ToggleButton style={{ padding: 5 }} key={b.value} value={b.value} >
                            {b.label}
                        </ToggleButton>)}

                    </ToggleButtonGroup>
                </div>

                <div className="inputComp">
                    <InputLabel>Villian Position:</InputLabel>
                    <ToggleButtonGroup
                        style={{ height: 32 }}
                        value={vPosition}
                        exclusive
                        onChange={(evt, v) => { if (v) setVposition(v) }}
                    >
                        {posicoesMesaVilao.map(b => <ToggleButton style={{ padding: 5 }} key={b} value={b} >
                            {b}
                        </ToggleButton>)}
                    </ToggleButtonGroup>
                </div>
            </div>
            <div style={{ display: 'flex', flexWrap: "wrap", justifyContent: 'space-evenly' }}>

                {(rangesFil || []).slice(0, 50).map((r, i) => {
                    return <Ran key={i} r={r} />
                })}
            </div>
            {nome}
            {/* <TabelaSel range={range2} changeRange={selCarta} /> */}
        </div>
    )
}
