import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firebase-firestore'
import 'firebase/analytics'

export const config = {
	apiKey: "AIzaSyBKwBPW-nCmarCuq8Fsv-4T22Cq7z3oJgE",
	authDomain: "pokernashcalc.firebaseapp.com",
	databaseURL: "https://pokernashcalc.firebaseio.com",
	projectId: "pokernashcalc",
	storageBucket: "pokernashcalc.appspot.com",
	messagingSenderId: "498910663704",
	appId: "1:498910663704:web:5417fe53d8454d2b7a9f6d",
	measurementId: "G-4MBR8LCDBK"
};


class FirebaseUtil {
	constructor() {
		app.initializeApp(config)
		this.perfilUser = null;
		this.auth = app.auth()
		this.db = app.firestore()
		app.analytics();
		this.db.enablePersistence().catch(function (err) {
			console.log("Erro ao ativar persistencia offline: ", err);

			if (err.code === 'failed-precondition') {
				// Multiple tabs open, persistence can only be enabled
				// in one tab at a a time.
				// ...
			} else if (err.code === 'unimplemented') {
				// The current browser does not support all of the
				// features required to enable persistence
				// ...
			}
		});

	}

	logEvent(str) { 
		console.log('logEvent',str)
		app.analytics().logEvent(str);
	}
	firestore() {
		return app.firestore
	}
	storageFunc() {
		return app.storage
	}


	login(email, password) {
		return this.auth.signInWithEmailAndPassword(email, password)
	}

	logout() {		
		return this.auth.signOut()
	}

	async register(name, email, password, country, ipRegistre) {
		await this.auth.createUserWithEmailAndPassword(email, password)
		return this.auth.currentUser.updateProfile({
			displayName: name,
			country: country,
			ipRegistre
		})
	}

	isInitialized() {

		return new Promise(resolve => {
			this.auth.onAuthStateChanged((r) => {
				resolve(r);
			})

		})
	}

	getCurrentUsername() {
		return this.auth.currentUser && this.auth.currentUser.displayName
	}
	getCurrentUser() {
		return this.auth.currentUser
	}
	setPerfilUser(perfilUser) {
		this.perfilUser = perfilUser;
	}

	getPerfilUser() {
		if (this.perfilUser) {
			return this.perfilUser;
		}
		// return this.auth.currentUser	
	}


	initGetDataBase(config, uidApp) {
		if (config && !app.apps.map(item => item.name).includes(uidApp)) {
			app.initializeApp(config, uidApp);
		}

		let iii = app.apps.map(item => item.name).indexOf(uidApp)
		let fireApp = app.apps[iii];
		if (fireApp) {
			return fireApp.firestore();
		} else {
			return null;
		}


	}
}

export default new FirebaseUtil()