import React from 'react'
import { loadStripe } from '@stripe/stripe-js';
import { useProfile } from '../../useAuth';
import { Button } from '@material-ui/core';
let key = 'pk_test_51GqMeTAovhxT4gAXAcn9kblDORKJOSIK3ribEyK86qdG0xYn28tcsW8oqGNzI3yuvclQlqJ5RSyeOQ1saqafcU7000tv8GJSB8'

const stripePromise = loadStripe(key);

export default function StripeSubscriptionButton({ urlReturn }) {
    let profile = useProfile();

    let clientReferenceId = profile ? profile.uid : null
    let emailChave = profile ? profile.email : null

    const handleClick = async (event) => {
        if (clientReferenceId) {
            const stripe = await stripePromise;
            stripe.redirectToCheckout({
                lineItems: [
                    // Replace with the ID of your price
                    { price: 'price_1GqMidAovhxT4gAXmwMrJVeU', quantity: 1 }
                ],
                mode: 'subscription',
                successUrl: urlReturn + '?checkoutStatus=success',
                cancelUrl: urlReturn + '?checkoutStatus=cancel',
                customerEmail: emailChave,
                clientReferenceId: clientReferenceId,
            }).then(function (result) {
                // If `redirectToCheckout` fails due to a browser or network
                // error, display the localized error message to your customer
                // using `result.error.message`.
            });
        }
    };


    return (
        <Button style={{ marginBottom: 20 }}
            variant='contained' color='primary' onClick={handleClick}>Subscribe</Button>
    )
}
