import React, { useState } from 'react'
import './style.css'
import firebaseUtil from '../../firebaseUtil';
import useAuth from '../../useAuth';

import { ReactComponent as Logo } from './poker-cards.svg';
import { TextField, Button, CircularProgress } from "@material-ui/core";
import { useHistory, Link } from 'react-router-dom';
const wall = require("./wall.jpg")
export default function Login() {



    const [error, setError] = useState("");
    const [username, setUsername] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [password, setPassword] = useState("");
    const history = useHistory();
    const { isAuthenticated, redirectPosAuth, registerLogin, logOut } = useAuth();
    async function loginUsuario(email, password) {
        setSubmitting(true)
        try {
            firebaseUtil.login(email, password)
                .then((vvv) => {
                    registerLogin()
                    redirectPosAuth('nash')
                }).catch(error => {
                    console.log("error.message", error.message);
                    // showWarning(t(error.code))
                    setError(error.message)
                    setSubmitting(false)
                })

        } catch (error) {
            console.log("error.message", error.message);
            // showWarning(t(error.code))
            setError(error.message)
            setSubmitting(false)
        }
    }
    let isAuth = isAuthenticated()
    // console.log('isAuth', isAuth)

    return (
        <div style={{ backgroundImage: "url(" + wall + ")", flex: 1 }} className='pageLogin'>
            <div className='boxLogin' style={{}}>

                <div className="logoLogin">Poker<Logo style={{ margin: 10, height: 35 }} />Nash</div>

                {!isAuth ?
                    < >

                        <div className='errorText'>{error}</div>
                        <TextField
                            style={{ marginBottom: 20 }}
                            name="email"
                            placeholder={'Email'}
                            type="email"
                            value={username}
                            onChange={event => {
                                setUsername(event.target.value);
                            }}
                        />

                        <TextField
                            style={{ marginBottom: 20 }}
                            placeholder={'Password'}
                            type="password"
                            value={password}
                            onChange={event => {
                                setPassword(event.target.value);
                            }}
                        />


                        <Button style={{ marginBottom: 20 }}
                            variant="contained" color="primary" onClick={() => {
                                loginUsuario(username, password);
                            }} disabled={submitting}>
                            {!submitting && "Login"}
                            {submitting && <CircularProgress />}
                        </Button>
                        <Link style={{ textAlign: 'center' }} to="/signup">Don't have an account? Sign up</Link>

                    </> :
                    <>

                        <Button style={{ marginBottom: 20 }}
                            variant='contained' color='primary' onClick={() => {
                                logOut().then(r => {
                                    history.push('/')
                                });
                            }}>LogOut</Button>
                        {/* 
                        <Button style={{ marginBottom: 20 }}
                            variant='contained' color='primary' onClick={() => {
                                console.log('user()', user())
                            }}>Log user</Button> */}
                    </>
                }

            </div>

        </div>
    )
}
